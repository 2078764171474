import { Fragment } from "react";

const UrlConverter: React.FC = ({ children }) => {

    const converted: Array<any> = [];
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

    const convertUrls = () => {
        const splited = String(children).split(' ');
        splited.forEach((item, index) => {
            if (item.match(urlRegex)) {
                const checkedItem = item.includes('http') ? item : 'http://' + item;
                const urlItem = <a key={`url-item_${item}_${index}`} href={checkedItem} target='_blank' rel='noreferrer'>{item}</a>;
                converted.push(urlItem);
            } else {
                converted.push(item);
            }
            
            if (index !== splited.length - 1) {
                converted.push(' ');
            }
        })
    }
    convertUrls();

    return (
        <Fragment>
            {converted}
        </Fragment>   
    )
}

export default UrlConverter;