import { ScrollDetail } from "@ionic/core";
import { IonAlert, IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonLabel, IonToolbar, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent, IonPage } from "@ionic/react";
import { arrowDownOutline, chevronBackOutline } from "ionicons/icons";
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { shallowEqual } from "react-redux";
import { useParams, useHistory } from "react-router";
import { getNextPageRoomMessages, sendMessage, updateUserLastRead } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { selectRoomById, selectRoomMessagesById } from "../../app/slices/roomSlice";
import { addCurrentChannel, removeCurrentChannel, selectUserId, selectCurrentChannel, selectUserModerator } from "../../app/slices/userSlice";
import { store } from "../../app/store";
import { Room } from "../../app/types/rooms";
import MessageBubbleMemo from "../../components/Chat/MessageBubbleMemo";
import MessageInput from "../../components/Chat/MessageInput";
import NotificationBell from "../../components/NotificationBell";
import { Message, MessagePayload } from '../../models/message';
import { translate } from "../../app/translate";

interface Props{ roomId?: Number }

const ChatRoomIonic: React.FC<Props> = (props: Props) => {
    const params: any = useParams<{ id: string }>();
    const history = useHistory();
    const [id, setId] = useState(parseInt(params.id));
    const room: Room = useAppSelector(selectRoomById(id), shallowEqual);
    const rawMessages: Array<Message> = useAppSelector(selectRoomMessagesById(id));
    const [messages, setMessages] = useState<Array<Message>>(rawMessages);
    const userId = useAppSelector(selectUserId);
    const CurrentChannel = useAppSelector(selectCurrentChannel);
    const contentRef = useRef<HTMLIonContentElement>(null);
    const [showBackToBottom, setShowBackToBottom] = useState(false);
    const isModerator = useAppSelector(selectUserModerator);
    const [alertController, setAlertController] = useState({
        isOpen: false,
        header: '',
        message: '',
    });
    const [disableLoadmore, setDisableLoadmore] = useState(true);

    useEffect(() => {
        setId(parseInt(params.id));
        setDisableLoadmore(true);
    }, [params]);

    useEffect(() => {
        (rawMessages && rawMessages.length > 0) && setMessages(rawMessages);
    }, [rawMessages]);

    // update last read
    useEffect(() => {
        if (userId > 0 && id && CurrentChannel.includes(id)) {
            updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
        }
        return () => {
            if (userId > 0 && id && CurrentChannel.includes(id)) { 
                updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
            }
        }
    }, [userId, id, CurrentChannel]);

    // if not desktop, remove channel listener
    useEffect(() => {
        store.dispatch(addCurrentChannel(id))
        return function cleanup() { store.dispatch(removeCurrentChannel(id)) }
    }, [id]);

    // send message hook
    const addNewMessage = async (newMessage: MessagePayload) => {
        const sendResult = await sendMessage(newMessage.message, id);
        if (sendResult === 'ILLEGAL') {
            setAlertController({
                isOpen: true,
                header: translate('Message Cannot be Sent'),
                message: translate('This message contains illegal characters / words and cannot be sent!'),
            });
        } else if (sendResult === 'EXCEEDED') {
            setAlertController({
                isOpen: true,
                header: translate('Message Cannot be Sent'),
                message: translate('This message exceeded the 500 characters limit. Consider sending it in few separate messages.'),
            });
        } else {
            contentRef.current?.scrollToBottom(500);
        }
    }

    const loadMore = useCallback(
        async (e: CustomEvent<void>) => {
            if (messages && messages.length > 0) {
                const olderMessages: Array<Message> = await getNextPageRoomMessages(String(id), String(messages[0].id));
                if (olderMessages.length <= 0) {
                    (e.target as HTMLIonInfiniteScrollElement).complete();
                    return
                };
                let temp: Array<Message> = [...messages];
                temp.unshift(...olderMessages);
                setMessages(temp);
                (e.target as HTMLIonInfiniteScrollElement).complete();
            }
        },
        [messages, id],
    )

    // handle when chat window scrolls
    const handleScroll = useCallback(
        async (e: CustomEvent<ScrollDetail>) => {
            const element = await contentRef.current?.getScrollElement();
            const scrollTop:number = element?.scrollTop || 0, scrollHeight:number = element?.scrollHeight || 0, clientHeight:number = element?.clientHeight || 0;
            if (element && clientHeight !== 0) {
                if (scrollHeight - scrollTop <= clientHeight * 1.5) {
                    setDisableLoadmore(true)
                    setShowBackToBottom(false)
                } else {
                    setDisableLoadmore(false)
                    setShowBackToBottom(true)
                }
            };
        }
        , [])

    const backToBottom = useCallback(
        async () => {
            if (messages.length > 50) {
                const temp = [...messages];
                const sliced = temp.slice(Math.max(temp.length - 50, 0));
                setMessages(sliced);
            }
            setTimeout(() => {
                contentRef.current?.scrollToBottom(500);
            }, 300);
        },
        [messages],
    )

    // onLoad go to bottom
    useEffect(() => {
        backToBottom();
    }, []);

    useEffect(() => {
        const checkAndScroll = async () => {
            const element = await contentRef.current?.getScrollElement();
            const scrollTop:number = element?.scrollTop || 0, scrollHeight:number = element?.scrollHeight || 0, clientHeight:number = element?.clientHeight || 0;
            if (element && clientHeight !== 0) {
                if (scrollHeight - scrollTop <= clientHeight * 1.5) {
                    setDisableLoadmore(true)
                    setShowBackToBottom(false)
                    setTimeout(() => {
                        contentRef.current?.scrollToBottom(500);
                    }, 300);
                } else {
                    setDisableLoadmore(false)
                    setShowBackToBottom(true)
                }
            };
        }
        checkAndScroll();
        window.addEventListener('focus', () => checkAndScroll());
        return () => {
            window.removeEventListener('focus', () => checkAndScroll());
        }
    }, [messages, backToBottom]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonButton slot='start' fill="clear" className='ion-no-padding'
                        onClick={() => history.push({
                            pathname: '/home/chatrooms',
                            state: {from: `/chatroom`, direction: 'back'}
                        })}
                    >
                        <IonIcon style={{color: `var(--ion-color-primary-contrast)`}} icon={chevronBackOutline} />
                        <IonLabel style={{color: `var(--ion-color-primary-contrast)`}}>{String(room.name)}</IonLabel>
                    </IonButton>
                    <NotificationBell/>
                </IonToolbar>
            </IonHeader>

            <IonContent
                // fullscreen
                scrollEvents={true}
                ref={contentRef}
                onIonScroll={(e: CustomEvent<ScrollDetail>) => handleScroll(e)}
            >
                {(messages && messages.length > 0) &&
                    <Fragment>
                        <IonInfiniteScroll
                            onIonInfinite={(e: CustomEvent<void>) => loadMore(e)}
                            position='top'
                            disabled={disableLoadmore}
                        >
                            <IonInfiniteScrollContent loadingText={translate('Loading')}>
                                
                            </IonInfiniteScrollContent>
                        </IonInfiniteScroll>

                        {
                            !isModerator ? 
                                messages.filter(item => item.status === 'sent').map((item, index) => (
                                    <MessageBubbleMemo
                                    key={`${id}_${item.id}`}
                                    messageData={item}
                                    lastMessage={messages.filter(item => item.status === 'sent')[index - 1] || null}
                                    nextMessage={messages.filter(item => item.status === 'sent')[index +1] || null}
                                    />
                                ))
                                :
                                messages.map((item, index) => (
                                    <MessageBubbleMemo
                                    key={`${id}_${item.id}`}
                                    messageData={item}
                                    lastMessage={messages[index - 1] || null}
                                    nextMessage={messages[index +1] || null}
                                    />
                                ))
                        }
                        
                        <IonFab
                            vertical="bottom" horizontal="center" slot="fixed"
                            style={{opacity: showBackToBottom ? '1' : '0'}}
                        >
                            <IonFabButton
                                onClick={() => backToBottom()}
                                disabled={showBackToBottom ? false : true}
                                
                            >
                                <IonIcon icon={arrowDownOutline} />
                            </IonFabButton>
                        </IonFab>
                    </Fragment>
                }
            </IonContent>

            <IonFooter>
                <MessageInput
                    recipient={props.roomId ? props.roomId : params.id}
                    addNewMessage={(newMessage: MessagePayload) => addNewMessage(newMessage)}
                    lastMessage={messages.length}
                    type="chatroom"
                />
            </IonFooter>

            <IonAlert
                isOpen={alertController.isOpen}
                onDidDismiss={() => setAlertController({
                    isOpen: false,
                    header: '',
                    message: '',
                })}
                header={alertController.header}
                message={alertController.message}
                buttons={['OK']}
            />
        </IonPage>
    )
}

export default ChatRoomIonic;