import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserState } from "./types";
import CheckLocal from "../../components/Classes/CheckLocal";
import { AppType, CheckEnv } from "../../components/Classes/CheckEnv";
import CheckWidget from "../../components/Classes/CheckWidget";


const appType:AppType = CheckEnv();
const isWidget = CheckWidget();
// get search query from url to get init autoAuth params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const initEventKey = urlParams.get('eventKey');
const initIsAutoAuth = urlParams.get('isAutoAuth') && parseInt(String(urlParams.get('isAutoAuth'))) === 1 ? true : false;
var localAccess = CheckLocal();

// init firebase token handling
const localInitFToken = localStorage.getItem('firebase_token');
const lastTokenTimestamp = localStorage.getItem('token_timestamp');
const localStorageValid = lastTokenTimestamp && Number(lastTokenTimestamp) ?
  Date.now() - Number(lastTokenTimestamp) < 3600000
  : false;
const initFToken = (initIsAutoAuth || !localAccess) ? '' : localInitFToken && localStorageValid ? localInitFToken : '';

const initialState: UserState = {
  token: initIsAutoAuth || !localAccess ? '' : localStorage.getItem('token') || '',
  firebase_token: initFToken,
  event_key: isWidget ? String(initEventKey) : appType === 'event' ? String(process.env.REACT_APP_EVENT_TOKEN) : localStorage.getItem('event_key') || '',
  status: 'idle',
  token_timestamp: Number(lastTokenTimestamp) || null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setStatus: (state: UserState, action: PayloadAction<'idle' | 'loading' | 'failed'>) => {
        state.status = action.payload;
      },
      setEvent_key: (state: UserState, action: PayloadAction<string>) => {
        state.event_key = action.payload;
      },
      setCredentials: (state: UserState, { payload: { access_token, firebase_token } }: PayloadAction<{ access_token: string, firebase_token: string }>) => {
        state.token = access_token;
        state.firebase_token = firebase_token;
      },
      logout: (state: UserState) => {
        state.token = '';
        state.firebase_token = '';
        (appType !== 'event') && (state.event_key = '');
        if (initIsAutoAuth || !localAccess) {
          return
        };
        window.self.localStorage.removeItem('token');
        window.self.localStorage.removeItem('firebase_token');
        (appType !== 'event') && window.self.localStorage.removeItem('event_key');
        window.self.localStorage.removeItem('magicEvents');
        window.self.localStorage.removeItem('magicTokens');
      },
    },
});

export const selectStatus = (state: RootState) => state.auth.status;
export const isAuthenticated = (state: RootState) => state.auth.token.length > 0 && state.auth.firebase_token.length > 0;
export const selectFirebaseToken = (state: RootState) => state.auth.firebase_token;
export const selectEvent_key = (state: RootState) => state.auth.event_key;
export const selectUserToken = (state: RootState) => state.auth.token;

export const { setStatus, setEvent_key, setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;
