import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User, UserTimestamp } from "../../pages/Auth/types";
import videoCallStatus from "../enums/videoCallStatus";
import { RootState } from "../store";
import { UserSettings, UserState, UserAnswer } from "../types/user";

const initialState: UserState = {
  userId: 0,
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    jobTitle: '',
    organisation: '',
    email: '',
    profilePicture: '',
    online: false,
    isInit: false,
    is_sponsor_user: false,
    callStatus: { status: videoCallStatus.available },
  },
  timestamps: {},
  matchmaking: false,
  settings: {
    missedChatEmail: false,
    missedChatFrequency: 0,
    pushNotifications: false,
    status: {
      manual: false,
      status: 'online'
    },
    blocked: {},
    blockedBy: {},
    isInit: false,
    moderator: false
  },
    currentChannel: [],
    answers: [],
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state: UserState, action: PayloadAction<number>) => {
      state.userId = action.payload
    },
    setUser: (state: UserState, action: PayloadAction<User>) => {
        state.user = action.payload
    },
    setUserTimestamps: (state: UserState, action: PayloadAction<any>) => {
      state.timestamps = action.payload
    },
    setUserMatchmaking: (state: UserState, action: PayloadAction<boolean>) => {
      state.matchmaking = action.payload
    },
    setUserSettings: (state: UserState, action: PayloadAction<UserSettings>) => {
        state.settings = action.payload
    },
    setUserAnswers: (state: UserState, action: PayloadAction<Array<UserAnswer>>) => {
        state.answers = action.payload
    },
    updateUserStatus: (state: UserState, action: PayloadAction<any>) => {
        state.settings.status = action.payload
    },
    addCurrentChannel: (state: UserState, action: PayloadAction<string | number>) => {
      state.currentChannel.push(action.payload)
    },
    removeCurrentChannel: (state: UserState, action: PayloadAction<string | number>) => {
        state.currentChannel = state.currentChannel.filter((channel) => channel !== action.payload)
    }
  }
});

export const selectUser = (state: RootState): User => state.user.user;
export const selectUserModerator = (state: RootState): boolean => state.user.settings.moderator || false;
export const selectUserId = (state: RootState): number => state.user.user.id;
export const userIdSelector = (state: RootState): number => state.user.userId;
export const selectUserTimestamps = (state: RootState): UserTimestamp => state.user.timestamps;
export const selectUserMatchmaking = (state: RootState): boolean => state.user.matchmaking;
export const selectUserSettings = (state: RootState): UserSettings => state.user.settings;
export const selectUserAnswers = (state: RootState): Array<UserAnswer> => state.user.answers;
export const selectCurrentChannel = (state: RootState): Array<any> => state.user.currentChannel;
export const selectVideoCallStatus = (state: RootState) => state.user.user.callStatus;

export const {
  setUser,
  setUserId,
  setUserTimestamps,
  setUserSettings,
  setUserAnswers,
  setUserMatchmaking,
  updateUserStatus,
  addCurrentChannel,
  removeCurrentChannel
} = userSlice.actions;

export default userSlice.reducer;
