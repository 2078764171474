import {
    IonButton,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar,
    useIonAlert
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { firebaseLogin } from '../../app/firebase';
import { useAppDispatch } from '../../app/hooks';
import { useRegisterMutation } from '../../app/services/authApi';
import { setCredentials } from './authSlice';
import { UserRegisterPayload } from './types';
import { translate } from '../../app/translate';

const Register: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [ formState, setFormState ] = useState<UserRegisterPayload>({
        first_name: '',
        last_name: '',
        organisation: '',
        job_title: '',
        email: '',
        password: '',
        password_confirmation: '',
    });

    const [ formErrorState, setFormErrorState ] = useState({
        first_name: '',
        last_name: '',
        organisation: '',
        job_title: '',
        email: '',
        password: '',
        password_confirmation: '',
    })

    const [ register, { isLoading } ] = useRegisterMutation();

    const [ present ] = useIonAlert();

    const handleChange = (e: CustomEvent<object>) => {
        const { name, value } = e.target as HTMLIonInputElement;

        setFormState((prev) => ({ ...prev, [name] : value }))
        handleValidationError(name, '');
    }

    const handleValidationError = (name: string, value: string) => {
        setFormErrorState((prev) => ({ ...prev, [name] : value }));
    }

    const handleSubmit = async () => {
        let valid = true;

        // Basic validation on form
        if (formState.first_name.length === 0) {
            handleValidationError('first_name', translate('The first name field is required'));
            valid = false;
        }

        if (formState.last_name.length === 0) {
            handleValidationError('last_name', translate('The last name field is required'));
            valid = false;
        }

        if (formState.organisation.length === 0) {
            handleValidationError('organisation', translate('The organisation field is required'));
            valid = false;
        }

        if (formState.job_title.length === 0) {
            handleValidationError('job_title', translate('The job title field is required'));
            valid = false;
        }

        if (formState.email.length === 0) {
            handleValidationError('email', translate('The email field is required'));
            valid = false;
        }

        if (formState.password.length === 0) {
            handleValidationError('password', translate('The password field is required'));
            valid = false;
        }

        if (formState.password_confirmation.length === 0) {
            handleValidationError('password_confirmation', translate('The confirm password field is required'));
            valid = false;
        }

        if (formState.password != formState.password_confirmation) {
            handleValidationError('password_confirmation', translate('Must match password'));
        }

        let header: string = translate('Invalid Credentials');
        let message: string = translate('Please fix all errors');

        if (valid) {
            const results: any = await register(formState);
            if (results.data) {
                dispatch(setCredentials(results.data));
                await firebaseLogin();
                //history.push(`${process.env.PUBLIC_URL}/home`);
                return;
            } else {
                switch (results.error.status) {
                    case 422:
                        Object.keys(results.error.data.errors).forEach((key) => {
                            handleValidationError(key, results.error.data.errors[key]);
                        })
                        break;
                    default:
                        header = translate('Error');
                        message = translate('An error occurred, please try again later');
                }
            }
        }

        present({
            header: header,
            message: message,
            buttons: [
                'OK'
            ],
        });
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar color='primary'>
                        <IonTitle>{translate('Register')}</IonTitle>
                        <IonButton slot='secondary' fill="clear" color='light' onClick={() => history.goBack()}>{translate('Back')}</IonButton>
                    </IonToolbar>
                </IonHeader>

                <IonList lines='inset'>
                    <IonListHeader lines="none">
                        <IonLabel><p>{translate('All Fields Required.')}</p></IonLabel>
                    </IonListHeader>

                    <IonItem>
                        <IonLabel position="floating">{translate('First Name')}</IonLabel>
                        <IonInput
                            type="text"
                            value={formState.first_name}
                            name="first_name"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.first_name.length > 0 ? "danger":"primary"}
                            >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.first_name.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ formErrorState.first_name }</IonText>
                    }

                    <IonItem>
                        <IonLabel position="floating">{translate('Last Name')}</IonLabel>
                        <IonInput
                            type="text"
                            value={formState.last_name}
                            name="last_name"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.last_name.length > 0 ? "danger":"primary"}
                        >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.last_name.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ formErrorState.last_name }</IonText>
                    }

                    <IonItem>
                        <IonLabel position="floating">{translate('Job Title')}</IonLabel>
                        <IonInput
                            type="text"
                            value={formState.job_title}
                            name="job_title"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.job_title.length > 0 ? "danger":"primary"}
                        >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.job_title.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ formErrorState.job_title }</IonText>
                    }

                    <IonItem>
                        <IonLabel position="floating">{translate('Organisation')}</IonLabel>
                        <IonInput
                            type="text"
                            value={formState.organisation}
                            name="organisation"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.organisation.length > 0 ? "danger":"primary"}
                        >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.organisation.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ formErrorState.organisation }</IonText>
                    }

                    <IonItem>
                        <IonLabel position="floating">{translate('Email')}</IonLabel>
                        <IonInput
                            type="email"
                            value={formState.email}
                            name="email"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.email.length > 0 ? "danger":"primary"}
                        >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.email.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ formErrorState.email }</IonText>
                    }

                    <IonItem>
                        <IonLabel position="floating">{translate('Password')}</IonLabel>
                        <IonInput
                            type="password"
                            value={formState.password}
                            name="password"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.password.length > 0 ? "danger":"primary"}
                        >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.password.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ formErrorState.password }</IonText>
                    }

                    <IonItem>
                        <IonLabel position="floating">{translate('Confirm Password')}</IonLabel>
                        <IonInput
                            type="password"
                            value={formState.password_confirmation}
                            name="password_confirmation"
                            onIonChange={handleChange}
                            autocomplete="off"
                            clearInput
                            color={formErrorState.password_confirmation.length > 0 ? "danger":"primary"}
                        >
                        </IonInput>
                    </IonItem>
                    {
                        formErrorState.password_confirmation.length > 0 &&
                        <IonText color='danger' className="ion-margin-start">{ translate(formErrorState.password_confirmation) }</IonText>
                    }

                </IonList>
                <br/>
                <IonButton
                    expand="block"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                {isLoading ?
                    <IonSpinner />
                    : translate('Submit')
                }
                </IonButton>
            </IonContent>
        </IonPage>
    );
};

export default Register;