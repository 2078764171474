import { Action, configureStore, ConfigureStoreOptions, ThunkAction } from '@reduxjs/toolkit';
import authSlice from '../pages/Auth/authSlice';
import { unauthenticatedMiddleware } from './middleware/Unauthenticated';
import { authApi } from './services/authApi';
import { userApi } from './services/userApi';
import eventSlice from './slices/eventSlice';
import groupSlice from './slices/groupSlice';
import initDataSlice from './slices/initDataSlice';
import matchSlice from './slices/matchSlice';
import questionSlice from './slices/questionSlice';
import roomSlice from './slices/roomSlice';
import userSlice from './slices/userSlice';
import magicEventsSlice from './slices/magicEventsSlice';
import CheckLocal from '../components/Classes/CheckLocal';
import CheckWidget from '../components/Classes/CheckWidget';
import sponsorSlice from './slices/sponsorSlice';

const isWidget = CheckWidget();
// get search query from url to get init autoAuth params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const initIsAutoAuth = !isWidget ? false : parseInt(String(urlParams.get('isAutoAuth'))) === 1 ? true : false;

var localAccess = CheckLocal();

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) => configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth: authSlice,
    initData: initDataSlice,
    [userApi.reducerPath]: userApi.reducer,
    user: userSlice,
    questions: questionSlice,
    matches: matchSlice,
    rooms: roomSlice,
    groups: groupSlice,
    events: eventSlice,
    magicEvents: magicEventsSlice,
    sponsors: sponsorSlice
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware().concat(authApi.middleware, userApi.middleware, unauthenticatedMiddleware),
    ...options
});

export const store = createStore();

store.subscribe(() => {
  //listener to update localStorage tokens

  if (initIsAutoAuth || !localAccess) { return };

  // get tokens changes
  const token: string = store.getState().auth.token;
  const firebaseToken: string = store.getState().auth.firebase_token;
  const event_key: string = store.getState().auth.event_key;

  if (token.length > 0 && localStorage.getItem('token') !== token) {
    // Set token in localStorage
    localStorage.setItem('token', token);
  }

  if (firebaseToken.length > 0 && localStorage.getItem('firebase_token') !== firebaseToken) {
    // Set firebase_token in localStorage
    localStorage.setItem('firebase_token', firebaseToken);
  }
  if (event_key.length > 0 && localStorage.getItem('event_key') !== event_key) {
    // Set event key in localStorage
    localStorage.setItem('event_key', event_key);
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
  
