import { IonButton, IonContent, IonPage } from "@ionic/react";
import phoneSvg from './img/android-reminder-artwork-01.svg';
import contentSvg from './img/android-reminder-artwork-02.svg';
import './AndroidVideoCallReminder.css';
import { translate } from "../app/translate";


interface Props {
    startCallCallback: () => void
    cancelCallback: () => void
}

const AndroidVideoCallReminder: React.FC<Props> = (props) => {
    return (
        <div className='ion-justify-content-center ion-align-items-center' style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box',
            padding: '16px',
            textAlign: 'center'
        }} >
            <div
                className="phone-image-container"
            >
                <img
                    className="phone-image"
                    src={phoneSvg}
                    alt='phoneSvg'
                />
                <img
                    className="content-image"
                    src={contentSvg}
                    alt='phoneSvg'
                />
            </div>
            <h2>{translate('Use Google Chrome as your Default Browser')}</h2>
            <br/>
            <IonButton
                onClick={props.startCallCallback}
            >{translate('OK, Start The Call')}</IonButton>
            <IonButton
                color="medium"
                fill="outline"
                onClick={props.cancelCallback}
            >{translate('Cancel The Call')}</IonButton>
        </div>
    )
}

export default AndroidVideoCallReminder;