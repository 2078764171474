import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCol,
	IonContent,
	IonGrid,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonPage,
	IonRow,
} from "@ionic/react";
import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { selectEventName } from "../../app/slices/initDataSlice";
import { selectMagicEvents } from "../../app/slices/magicEventsSlice";
import { AppType, CheckEnv } from "../../components/Classes/CheckEnv";
import { translate } from "../../app/translate";

const tempLandingPage = true;

const OrgLogin: React.FC = () => {
    const history = useHistory();
	const appType: AppType = CheckEnv();
	const eventName = useAppSelector(selectEventName);
	const magicEvents = useAppSelector(selectMagicEvents);
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [sendLinkRes, setSendLinkRes] = useState<any>(null);

	const handleSubmit = async () => {
		setIsLoading(true);
		setTimeout(() => {
			Math.random() > 0.5 ? setSendLinkRes("sent") : setSendLinkRes("none");
			setIsLoading(false);
		}, Math.floor(Math.random() * 4000) + 1000);
	};

	const getName = useCallback(() => {
		return appType === "org" ? eventName : "ChatGo";
	}, [appType, eventName]);

	return (
		tempLandingPage ?
		<IonPage>
			<IonContent fullscreen>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "space-between",
						height: "100vh",
						backgroundColor: `var(--ion-color-primary)`
					}}
				>
					<div></div>
					<IonGrid
						fixed
						style={{
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<IonRow>
							<IonCol size="12">
								<IonLabel style={{color: `var(--ion-color-primary-contrast)`}}>
									<h1>
										<b>{getName()}</b>
									</h1>
									<h3>
										<b>{translate('Welcome to our Chat Portal')}</b>
									</h3>
								</IonLabel>
							</IonCol>
						</IonRow>
						<IonCard color="medium">
						<IonCardContent>
							<p>{translate('Please click the magic link(s) provided to you via email, and login to 1 or more chatGo events you had registered with.')}</p>
						</IonCardContent>
					</IonCard>
					</IonGrid>
					<div></div>
				</div>
			</IonContent>
			<IonLoading isOpen={isLoading} />
		</IonPage>
		:
		<IonPage>
			<IonContent fullscreen>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "space-between",
						height: "100vh",
					}}
				>
					<div></div>
					<IonGrid
						fixed
						style={{
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<IonRow>
							<IonCol size="12">
								<IonLabel>
									<h1>
										<b>{getName()}</b>
									</h1>
									<h3>
										<b>{translate('Welcome to our Chat Portal')}</b>
									</h3>
								</IonLabel>
							</IonCol>
						</IonRow>
						{!sendLinkRes || sendLinkRes === "none" ? (
							<>
								<IonRow>
									<IonCol size="12">
										<IonLabel>
											<p style={{ padding: "1rem" }}>
												{translate('Enter your email address to access 1 or more chat events that you signed up with us.')}
											</p>
										</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol size="12">
										<IonList lines="full">
											<IonItem>
												<IonLabel position="floating">translate('Email')</IonLabel>
												<IonInput
													type="email"
													name="email"
													onIonChange={(e) => setEmail(e.detail.value!)}
												/>
											</IonItem>
										</IonList>
										<br />
										<br />
										<IonButton
											key={"email-submit-btn"}
											expand="block"
											disabled={!email.includes(`@`) || !email.includes(`.`)}
											onClick={() => handleSubmit()}
										>
											{translate('Login')}
										</IonButton>
									</IonCol>
								</IonRow>
							</>
						) : (
							<>
								<IonRow>
									<IonCol>
										<IonLabel>
											<br />
											<br />
											<br />
											<h2>
												<b>{translate('Check your Inbox')}</b>
											</h2>
											<p>
												{translate(`We have found 1 or more chats that you have signed up with :name. Please click the link in the email we sent to login securely.`, { 'name': getName() ?? ''})}
											</p>
										</IonLabel>
									</IonCol>
								</IonRow>
								<IonRow>
									<IonCol>
										<br />
										<IonButton>{translate('Go to my Inbox')}</IonButton>
										<br />
										<IonButton
											key={"login-back-btn"}
											fill="clear"
											color="medium"
											onClick={() => {
												setSendLinkRes(null);
												setEmail("");
											}}
										>
											{translate('Back')}
										</IonButton>
									</IonCol>
								</IonRow>
							</>
						)}
					</IonGrid>
					<div></div>
				</div>
			</IonContent>
			<IonLoading isOpen={isLoading} />
		</IonPage>
	);
};

export default OrgLogin;
