import Color from "color";
import { store } from "../../app/store";

export const getBrandings = async (eventKey: string) => {
	var brandingApiOptions = {
		method: "GET",
		headers: {
			accept: "application/json",
			authorization: `Bearer ${eventKey}`,
		},
	};
	const brandRes = await fetch(
		`${process.env.REACT_APP_CHATGO_API}/branding`,
		brandingApiOptions
	);
	const brandingData = await brandRes.json();
    const branding = brandingData.meta.custom.widget;

	addThemeColorsCssVars({
		colour_1: branding.colour_1,
		colour_2: branding.colour_2,
	});
};

export const addThemeColorsCssVars = (colors: {
	colour_1: string;
	colour_2: string;
}) => {
	// REMARKS: the package 'Color' has issue with babel if you use >= 4.0.1 version, hence we sticks with v3.2.1
	const color1 = Color(colors.colour_1);
	const color2 = Color(colors.colour_2);

	const themeColorsCss = `
      :root{
        /** primary **/
        --ion-color-primary: ${color1.hex()};
        --ion-color-primary-rgb: ${color1.rgb()};
        --ion-color-primary-contrast: ${color1.isDark() ? '#FFFFFF' : '#222222'};
		--ion-color-primary-contrast-rgb: ${color1.isDark() ? '255,255,255' : '22,22,22'};
        --ion-color-primary-shade: ${color1.darken(0.3).hex()};
        --ion-color-primary-tint: ${color1.lighten(0.3).hex()};
  
        /** secondary **/
        --ion-color-secondary: ${color2.hex()};
        --ion-color-secondary-rgb: ${color2.rgb()};
        --ion-color-secondary-contrast: ${color2.isDark() ? '#FFFFFF' : '#222'};
		--ion-color-secondary-contrast-rgb: ${color2.isDark() ? '255,255,255' : '22,22,22'};
        --ion-color-secondary-shade: ${color2.darken(0.3).hex()};
        --ion-color-secondary-tint: ${color2.lighten(0.3).hex()};
      }
    `;

	const oldTag = document.getElementById("event-theme-vars");
	oldTag && oldTag.remove();

	var themeColorsTag = document.createElement("style");
	themeColorsTag.appendChild(document.createTextNode(themeColorsCss));
	themeColorsTag.id = "event-theme-vars";
	document.body.append(themeColorsTag);
};
