import {
    IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem,
    IonLabel, IonList, IonRow, IonSpinner, useIonAlert, IonPage
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useLoginMutation } from '../../app/services/authApi';
import { selectEventName, selectIsAutoAuth } from '../../app/slices/initDataSlice';
import { setCredentials } from './authSlice';
import { UserLoginPayload } from './types';
import { firebaseLogin } from '../../app/firebase';
import { translate } from '../../app/translate';

const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const isAutoAuth = useAppSelector(selectIsAutoAuth);
    const isIFrame = window.top !== window.self;

    const event_name: string | null | undefined = useAppSelector(selectEventName);

    const [ formState, setFormState ] = useState<UserLoginPayload>({
        email: "",
        password: "",
    });

    const [ present ] = useIonAlert();

    const [ login, { isLoading } ] = useLoginMutation();

    const handleChange = (e: CustomEvent<object>) => {
        const { name, value } = e.target as HTMLIonInputElement;

        setFormState((prev) => ({ ...prev, [name] : value }))
    };

    // Validates + Submits login request
    const handleLogin = async () => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let header: string = '';
        let message: string = '';

        // Validate email + password are acceptable
        if (re.test(formState.email) && formState.password.length > 0) {
            try {
                const result: any = await login(formState);
                if (result.data) {
                    dispatch(setCredentials(result.data));
                    await firebaseLogin();
                    //history.push(`${process.env.PUBLIC_URL}/home`);

                    return;
                } else {
                    switch (result.error.status) {
                        case 401:
                            header = translate('Invalid Credentials');
                            message = translate('Please enter a valid email/password');
                            break;
                        case 429:
                            header = translate('Too Many Attempts');
                            message = translate(result.error.data.message);
                            break;
                        default:
                            header = translate('Error');
                            message = translate('An error occurred, please try again later');
                    }
                }
            } catch (error) {
                header = translate('Error');
                message = translate('An error occurred, please try again later');
            }
        } else {
            header = translate('Invalid Credentials');
            message = translate('email/password must be valid format');
        }

        present({
            header: header,
            message: message,
            buttons: [
                'OK'
            ],
        });
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid fixed={true}>
                    
                    {!isIFrame &&
                        <IonRow>
                            <IonCol
                                size='12'
                                className='ion-margin ion-padding'
                            ></IonCol>
                        </IonRow>
                    }
                    
                    <IonRow>
                        <IonCol size='12' className='ion-margin-top'>
                            <h1 className="ion-text-center"><b>{translate('Welcome to the')}<br />{event_name ? event_name : 'ChatGo'} {translate('App')}</b></h1>
                            <br />
                            {/* <h3>{process.env.REACT_APP_CHATGO_API}</h3> */}
                        </IonCol>
                    </IonRow>
                    {isAutoAuth ?
                        <IonRow>
                            <IonCol className='ion-text-center ion-padding ion-margin' >
                                <br/>
                                <IonLabel color='medium'>
                                    <IonSpinner color='primary'></IonSpinner>
                                    <h2>{translate('Loading Chat')}</h2>
                                </IonLabel>
                                
                            </IonCol>
                        </IonRow>
                        :
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonList lines="full">
                                        <IonItem>
                                            <IonLabel position="floating"> {translate('Email')}</IonLabel>
                                            <IonInput
                                                type="email"
                                                value={formState.email}
                                                onIonChange={handleChange}
                                                name="email"
                                                >
                                            </IonInput>
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel position="floating"> {translate('Password')}</IonLabel>
                                            <IonInput
                                                type="password"
                                                value={formState.password}
                                                onIonChange={handleChange}
                                                name="password"
                                                >
                                            </IonInput>
                                        </IonItem>

                                    </IonList>
                                    <br/>
                                    <IonButton 
                                        expand="block" 
                                        disabled={isLoading}
                                        onClick={handleLogin}
                                    >
                                        {isLoading ?
                                            <IonSpinner />
                                            : translate('Login')
                                        }
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow className='ion-margin-top'>
                                <IonCol size='12'>
                                    <IonButton
                                        onClick={() => {
                                            history.push(`${process.env.PUBLIC_URL}/register`);
                                        }}
                                        size="default"
                                        fill="clear"
                                        expand="block"
                                    >
                                        {translate('Click here to register')}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    }
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}

export default Login
