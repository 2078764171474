import { useState, useEffect } from 'react';
import {
    IonButton,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import { chevronBackOutline, personCircleOutline, close } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { PostMessageToParent } from '../components/Classes/PostMessage';
import { useAppSelector } from '../app/hooks';
import { selectMode } from '../app/slices/initDataSlice';
import CheckWidget from './Classes/CheckWidget';
import { translate } from '../app/translate';

interface Props{
    title: string;
    back?: boolean;
    hideUser?: boolean;
}

const TabHeader: React.FC<Props> = ({title, back, hideUser, children}) => {
    const history = useHistory();
    const isWidget = CheckWidget();
    const [isFullScreen, setIsFullWidth] = useState(window.self.innerWidth < 480);
    const mode: string | null | undefined = useAppSelector(selectMode);

    useEffect(() => {
        const handleResize = () => {
            setIsFullWidth(window.self.innerWidth < 480);
        }
        window.addEventListener('resize', () => handleResize());
        return () => {
            window.removeEventListener('resize', () => handleResize());
        }
    }, [])

    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle style={{color: `var(--ion-color-primary-contrast)`}} >{title}</IonTitle>
                {
                    !hideUser &&
                    <IonButton slot='primary' fill="clear" className='ion-no-padding'>
                        <IonMenuButton menu='user-menu'>
                            <IonIcon
                                icon={personCircleOutline}
                                // color="white"
                                style={{color: `var(--ion-color-primary-contrast)`}}
                            />
                        </IonMenuButton>
                    </IonButton>
                }
                {
                    back ?
                        <IonButton
                            slot='secondary'
                            fill="clear"
                            // color="light"
                            className='ion-no-padding ion-margin-end'
                            onClick={() => history.length > 0 ? history.goBack() : history.push('/')}
                            style={{color: `var(--ion-color-primary-contrast)`}}
                        >
                            <IonIcon icon={chevronBackOutline}/> {translate('Back')}
                        </IonButton>
                        :
                        isWidget && isFullScreen && mode === 'widget' ?
                            <IonButton
                                slot='secondary'
                                fill="clear"
                                color="light"
                                onClick={() => {
                                    PostMessageToParent({
                                        type: 'close'
                                    })
                                }}
                            >
                                <IonIcon icon={close}/>
                            </IonButton>
                            :
                            ''
                }
            </IonToolbar>
            {children}
        </IonHeader>
    )
}

export default TabHeader;