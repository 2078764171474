import { ActionSheetButton } from '@ionic/core';
import {
    IonBadge, IonButton, IonChip, IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem,
    IonLabel, IonList, IonLoading, IonPage, useIonActionSheet,
    useIonAlert
} from "@ionic/react";
import { peopleOutline, settingsOutline, thumbsUpSharp } from 'ionicons/icons';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getDMGroup2, userNotBlocked, userNotBlockedBy, videoCallRecipient } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { useUserBlockMutation, useUserUnBlockMutation } from "../../app/services/userApi";
import { selectEventToggles } from '../../app/slices/eventSlice';
import { selectMatches, setMatches } from '../../app/slices/matchSlice';
import { selectSponsorTitle } from '../../app/slices/sponsorSlice';
import { selectUserSettings } from '../../app/slices/userSlice';
import { EventToggles } from '../../app/types/eventToggles';
import { Match } from '../../app/types/match';
import UserInfoChecked from '../../components/Classes/UserInfoChecked';
import EmptyContent from '../../components/EmptyContent';
import TabHeader from "../../components/TabHeader";
import UserAvatar from "../../components/UserAvatar";
import { translate } from '../../app/translate';

const Matches: React.FC = props => {
    const [present, dismiss] = useIonActionSheet();
    const history = useHistory();
    const initMatches = useAppSelector<Array<Match>>(selectMatches);
    const userSettings = useAppSelector(selectUserSettings);
    const [matches, setmatches] = useState<Array<Match>>([]);
    const [block, { isBlockLoading }] = useUserBlockMutation();
    const [unblock, { isUnBlockLoading }] = useUserUnBlockMutation();
    const [alert] = useIonAlert();
    const [showDMAlert, setShowDMAlert] = useState(false);
    const eventToggles: EventToggles | never[] = useAppSelector(selectEventToggles);
    const [pageCount, setPageCount] = useState<number>(1);
    const itemPerPage: number = 30;
    const [loadingVideoCall, setLoadingVideoCall] = useState(false);
    const sponsorTitle = useAppSelector(selectSponsorTitle);

    useEffect(() => {
        const checked = initMatches.filter(match => userNotBlocked(String(match.user.id)) === true).filter(match => userNotBlockedBy(String(match.user.id)) === true);
        setmatches(checked);
    }, [initMatches, userSettings]);

    const EmptyContentComponent =
        <EmptyContent
            header={``}
            message={translate(`Oh no! We've got no matches for you right now.`)}
            icon={peopleOutline}
        >
            <br />
            <IonButton
                color='secondary'
                onClick={() => history.push('/matchmakingsettings')}
            >
                <IonIcon icon={settingsOutline} className='ion-margin-end' />
                {translate('Check your matchmaking settings')}
            </IonButton>
        </EmptyContent>

    const createAlertMessageBtn = useCallback((match: Match) => {
        let buttons: Array<ActionSheetButton> = [];
        if (userNotBlocked(String(match.user.id))) {
            buttons = [
                {
                    text: translate('View Profile'),
                    handler: () => history.push({
                        pathname: `${process.env.PUBLIC_URL}/userprofile/${match.user.id}`,
                        state: match.user,
                    })
                },
            ];
            eventToggles.direct_messaging && buttons.unshift({
                text: translate(`Message :firstName`, {firstName: match.user.firstName}),
                handler: async () => {
                    setShowDMAlert(true)
                    // getDMGroup(match.user.id).then(id => {
                    //     setShowDMAlert(false)
                    //     setTimeout(() => {
                    //         history.push(`${process.env.PUBLIC_URL}/group/${id}`)
                    //     }, 300);
                    // })
                    const targetGroupId = await getDMGroup2(match.user);
                    setShowDMAlert(false);
                    history.push(`/group/${targetGroupId}`);
                }
            });
            buttons.push({
                text: translate(`Block :firstName`, {firstName: match.user.firstName}),
                handler: async () => {
                    const response: { data: { success: boolean } } = await block({
                        target: match.user.id,
                    })

                    var message = translate(`Failed to block the :firstName`, {firstName: match.user.firstName});
                    var header = translate("Error");
                    if (response.data.success) {
                        message = translate(`Successfully blocked :firstName`, {firstName: match.user.firstName});
                        header = translate("Success");
                    }

                    alert({
                        header,
                        message,
                        buttons: [
                            'OK',
                        ],
                    });
                }
            })
        } else {
            buttons.push({
                text: `Unblock ${match.user.firstName}`,
                handler: async () => {
                    const response: { data: { success: boolean } } = await unblock({
                        target: match.user.id,
                    })

                    var message = translate(`Failed to unblock the :firstName`, {firstName: match.user.firstName});
                    var header = translate("Error");
                    if (response.data.success) {
                        message = translate(`Successfully unblocked :firstName`, {firstName: match.user.firstName});
                        header = translate("Success");
                    }

                    alert({
                        header,
                        message,
                        buttons: [
                            translate('OK'),
                        ],
                    });
                }
            })
        }

        buttons.push({
            text: translate('Cancel'),
            role: 'cancel'
        })

        return buttons;
    }, [eventToggles, alert, block, history, unblock]);

    const loadMore = useCallback(
        async (e: CustomEvent<void>) => {
            if (matches.length <= pageCount * itemPerPage) { return };
            const delay = Math.floor(Math.random() * 500) + 300;
            setTimeout(() => {
                setPageCount(pageCount + 1);
                (e.target as HTMLIonInfiniteScrollElement).complete();
            }, delay);
        }, [matches.length, pageCount]
    )

    return (
        <IonPage>
            <TabHeader title={translate('Your Matches')} />

            <IonContent>
                <IonLoading
                    isOpen={showDMAlert}
                    message={translate('Loading Chat...')}
                />
                <IonLoading
                    isOpen={loadingVideoCall}
                    message={translate('Preparing Video Call...')}
                />

                {(matches && matches.length <= 0) ?
                    EmptyContentComponent
                    :
                    <Fragment>
                        <IonList lines="none">
                            {
                                matches.map((match, idx) => (
                                    idx < pageCount * itemPerPage &&
                                    <IonItem
                                        key={`matches_${match.user.id}`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => present({ buttons: createAlertMessageBtn(match) })}
                                    >
                                        <UserAvatar
                                            id={idx}
                                            firstName={match.user.firstName}
                                            img={match.user.profilePicture}
                                        // status={match.user.online ? 'online' : 'offline'}
                                        />
                                        <IonLabel>
                                                <h2>{`${match.user.firstName} ${match.user.lastName}`} {match.user.is_sponsor_user ? <IonChip>{sponsorTitle}</IonChip> : null}</h2>
                                            <p><UserInfoChecked jobTitle={match.user.jobTitle} organisation={match.user.organisation} /></p>
                                            <br />
                                        </IonLabel>
                                        <IonBadge
                                            slot="end"
                                            color={match.match > 50 ? 'primary' : 'secondary'}
                                        ><IonIcon icon={thumbsUpSharp} />{` ${match.match}%`}</IonBadge>

                                    </IonItem>
                                ))
                            }
                        </IonList>
                        <IonInfiniteScroll
                            disabled={matches.length <= pageCount * itemPerPage}
                            onIonInfinite={(e: CustomEvent<void>) => loadMore(e)}
                        >
                            <IonInfiniteScrollContent
                                loadingText={translate("Loading more matches...")}>
                            </IonInfiniteScrollContent>
                        </IonInfiniteScroll>
                    </Fragment>
                }
            </IonContent>
        </IonPage>
    )
}

export default Matches;