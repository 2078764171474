import { Fragment } from "react";
import { IonIcon, IonAvatar, IonText } from '@ionic/react';


interface Props{
    // chidren?: JSX.Element;
    header: string;
    message: string;
    icon?: string;
}

const EmptyContent: React.FC<Props> = ({
    header,
    message,
    icon,
    children
}) => {

    return (
        <Fragment>
            <div className='ion-justify-content-center ion-align-items-center' style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                padding: '16px',
                textAlign: 'center'
            }} >
                {icon &&
                    <IonAvatar
                        className='ion-justify-content-center ion-align-items-center'
                        style={{
                            width: '76px',
                            height: '76px',
                            display: 'flex',
                            backgroundColor: 'var(--ion-color-primary)'
                        }}
                    >
                        <IonIcon size="large" color='light' icon={icon}/>
                    </IonAvatar>
                }
                <IonText color='primary' ><h3>{header}</h3></IonText>
                <p>{message}</p>
                {children}
            </div>
        </Fragment>
    )
}

export default EmptyContent;