import {
    IonActionSheet, IonAvatar, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRow, IonText, IonTitle, IonToolbar
} from '@ionic/react';
import {
    banOutline, checkmarkOutline,
    closeOutline,
    eyeOffOutline, lockClosedOutline, logOutOutline, pencil, personOutline, starHalf, swapHorizontalOutline
} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { userLogout } from '../app/firebase';
import { useAppSelector } from '../app/hooks';
import { useUpdateUserSettingsMutation } from '../app/services/userApi';
import { selectEventToggles } from '../app/slices/eventSlice';
import { selectIsAutoAuth } from '../app/slices/initDataSlice';
import { selectMagicEvents, selectMagicTokens } from '../app/slices/magicEventsSlice';
import { selectUser, selectUserSettings } from '../app/slices/userSlice';
import { EventToggles } from '../app/types/eventToggles';
import { UserSettings } from '../app/types/user';
import { colorMaps } from '../components/Classes/ColorMap';
import { User, UserSettingsPayload } from '../pages/Auth/types';
import UserInfoChecked from './Classes/UserInfoChecked';
import { translate } from '../app/translate';

interface ItemBtnProps {
    onClick?: () => void;
    children?: any;
}

interface statusStyles {
    status: string,
    color: string,
    icon: string;
}

const ItemBtn: React.FC<ItemBtnProps> = (props: ItemBtnProps) => {
    return (
        <IonItem
            onClick={props.onClick}
            style={{ cursor: 'pointer' }}
        >
            {props.children}
        </IonItem>
    );
};

const UserMenu: React.FC = () => {
    const user: User = useAppSelector(selectUser);
    const userSettings: UserSettings = useAppSelector(selectUserSettings);
    const [updateUserSettings] = useUpdateUserSettingsMutation();
    const isAutoAuth: boolean = useAppSelector(selectIsAutoAuth);
    const [bgColor, setBgColor] = useState('#888888');
    const eventToggles: EventToggles | never[] = useAppSelector(selectEventToggles);
    const [showStatusAction, setShowStatusAction] = useState(false);
    const magicEvents = useAppSelector(selectMagicEvents);
    const magicTokens = useAppSelector(selectMagicTokens);

    const history = useHistory();

    const statusStyleMap: Array<statusStyles> = [
        {
            status: 'online',
            color: 'success',
            icon: checkmarkOutline
        },
        {
            status: 'offline',
            color: 'danger',
            icon: closeOutline
        },
        {
            status: 'hidden',
            color: 'warning',
            icon: eyeOffOutline
        }
    ];

    const logoutDispatch = () => {
        userLogout();
    };

    const statusActionOptions = [
        {
            text: translate('Online'),
            // icon: checkmarkOutline,
            handler: () => updateUserStatus('online')
        },
        {
            text: translate('Offline'),
            // icon: closeOutline,
            handler: () => updateUserStatus('offline')
        },
        {
            text: translate('Hide from Everyone'),
            // icon: eyeOffOutline,
            handler: () => updateUserStatus('hidden')
        },
        {
            text: translate('Cancel'),
            role: 'cancel'
        }
    ];

    useEffect(() => {
        if (user && user.firstName[0] && colorMaps) {
            const finalColor:string | any = colorMaps.find(color => color.initials.includes(user.firstName[0].toLowerCase()))?.color;
            finalColor && setBgColor(finalColor)
        }
    }, [user])


    const updateUserStatus = async (value: string) => {
        const payload: UserSettingsPayload = {
            flg_alert_mail: userSettings.missedChatEmail,
            flg_mail_freq: userSettings.missedChatFrequency,
            flg_alert_push: userSettings.pushNotifications,
            status: {
                manual: true,
                status: value,
            }

        };

        const result = await updateUserSettings(payload);

        if (result.data) {
            updateUserSettings({
                status: value,
                manual: true,
            });
        }
    };

    const handleSwitchEvents = async () => {
        history.push(`/gateway`)
    }

    return (
        <IonMenu side='end' contentId='main' type="overlay" menuId='user-menu'>
            <IonContent fullscreen>
                <IonActionSheet
                    isOpen={showStatusAction}
                    buttons={statusActionOptions}
                    onDidDismiss={() => setShowStatusAction(false)}
                />

                <IonGrid className='ion-text-center ion-padding-top'>
                    <IonRow>
                        <IonCol size='12' className='ion-justify-content-start ion-align-items-start ion-padding-top'>
                            <IonAvatar
                                className='ion-align-self-center ion-justify-content-center ion-align-items-center ion-margin-bottom'
                                style={{
                                    margin: '12px auto',
                                    backgroundColor: bgColor,
                                    display: 'flex',
                                }}
                            >
                                { user.profilePicture ?
                                        <img
                                            src={user.profilePicture}
                                            alt='My profile'
                                        />
                                    :
                                    <IonText color='white' style={{
                                        fontSize: '24px',
                                    }}>{user.firstName.substring(0, 1)}</IonText>
                                }
                            </IonAvatar>
                            <div>
                                <IonChip
                                    color={statusStyleMap.filter(item => item.status === userSettings.status.status)[0].color}
                                    className="ion-align-self-end"
                                    onClick={() => setShowStatusAction(true)}
                                >
                                    <IonIcon icon={statusStyleMap.filter(item => item.status === userSettings.status.status)[0].icon} />
                                    <IonLabel>{translate(userSettings.status.status.charAt(0).toUpperCase() + userSettings.status.status.slice(1))}</IonLabel>
                                </IonChip>
                                <IonChip
                                    color='medium'
                                    onClick={() => setShowStatusAction(true)}
                                >
                                    <IonIcon icon={pencil} size='small' className='ion-no-margin' />
                                </IonChip>
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12' data-user-id={user.id}>
                            <IonLabel>
                                <h2>{user.firstName} {user.lastName}</h2>
                                <p><UserInfoChecked jobTitle={user.jobTitle} organisation={user.organisation} /></p>
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonMenuToggle menu='user-menu'>
                    <IonList className="ion-margin-top">
                        <ItemBtn
                            onClick={() => history.push(`${process.env.PUBLIC_URL}/profilesettings`)}
                        >
                            <IonIcon icon={personOutline} className="ion-margin-end" /> {translate('Edit Profile')}
                        </ItemBtn>
                        {
                            eventToggles.matchmaking &&
                            // !user.is_sponsor_user &&
                            <ItemBtn
                                onClick={() => history.push(`${process.env.PUBLIC_URL}/matchmakingsettings`)}
                            >
                                <IonIcon icon={starHalf} className="ion-margin-end" /> {translate('Matchmaking Settings')}
                            </ItemBtn>
                        }
                        
                        {/* <ItemBtn
                            onClick={() => history.push(`${process.env.PUBLIC_URL}/notificationssettings`)}
                        >
                            <IonIcon icon={notificationsOutline} className="ion-margin-end" /> Notification Settings
                        </ItemBtn> */}

                        <ItemBtn
                            onClick={() => history.push(`${process.env.PUBLIC_URL}/blockedusers`)}
                        >
                            <IonIcon icon={banOutline} className="ion-margin-end" /> {translate('Blocked Users')}
                        </ItemBtn>
                        {
                            !isAutoAuth &&
                            <>
                                <ItemBtn
                                    onClick={() => history.push(`${process.env.PUBLIC_URL}/resetpassword`)}
                                >
                                    <IonIcon icon={lockClosedOutline} className="ion-margin-end" /> {translate('Reset Password')}
                                </ItemBtn>
                                <ItemBtn onClick={() => logoutDispatch()} >
                                    <IonIcon icon={logOutOutline} className="ion-margin-end" /> {translate('Logout')}
                                </ItemBtn>
                            </>
                        }
                        
                        {
                            (magicTokens.length > 0 && magicEvents.length > 1) &&
                            <>
                                <br/><br/>
                                <ItemBtn
                                    onClick={() => handleSwitchEvents()}
                                >
                                    <IonIcon icon={swapHorizontalOutline} className="ion-margin-end" /> {translate('Switch Event')}
                                </ItemBtn>
                            </>
                            
                        }
                    </IonList>
                </IonMenuToggle>

            </IonContent>
            <IonFooter className=''>
                <IonToolbar>
                    <IonTitle size="small">{translate('version :version', { version: '2.2' })}</IonTitle>
                </IonToolbar>
            </IonFooter>
        </IonMenu>
    );
};

export default UserMenu;