import { Camera, CameraResultType } from '@capacitor/camera';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {
    IonAvatar, IonButton, IonChip, IonCol, IonContent, IonGrid, IonIcon,
    IonInput, IonItem,
    IonLabel, IonList, IonRow, IonSpinner,
    IonText,
    useIonAlert, IonPage
} from "@ionic/react";
import { arrowUpCircle, personOutline } from 'ionicons/icons';
import { Fragment, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { useUpdateProfilePictureMutation, useUpdateUserMutation } from "../../app/services/userApi";
import { selectUser } from "../../app/slices/userSlice";
import ProfileSettingsSkeleton from "../../components/Skeleton/ProfileSettingsSkeleton";
import TabHeader from "../../components/TabHeader";
import { UserUpdatePayload } from "../Auth/types";
import './ProfileSettings.css';
import Vapor from '../../app/services/CustomVapor'
import { selectUserToken } from '../Auth/authSlice';
import { v4 as uuidv4 } from 'uuid';
import { translate } from '../../app/translate';

const vp = new Vapor();

defineCustomElements(window);

function urltoFile(url: string, filename: string, mimeType: string){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
}

function resizedataURL(datas:  string, wantedWidth: number){
    return new Promise<string>(async function(resolve,reject){

        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function()
        {        
            // We create a canvas and get its context.
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var wantedHeight = img.height * (wantedWidth / img.width)

            // We set the dimensions at the wanted size.
            canvas.width = wantedWidth;
            canvas.height = wantedHeight;

            // We resize the image with the canvas method drawImage();
            ctx && ctx.drawImage(img, 0, 0, wantedWidth, wantedHeight);

            var dataURI = canvas.toDataURL();

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    })
}

const ProfileSettings: React.FC = props => {
    const initUser = useAppSelector(selectUser);
    const [user, setUser] = useState(initUser);
    const [ updateUser, { isLoading } ] = useUpdateUserMutation();
    const [ updateUserProfilePicture ] = useUpdateProfilePictureMutation();
    const [ present ] = useIonAlert();
    const [isLoadingProfile, setIsLoadingProfile] = useState(false);
    const userToken = useAppSelector(selectUserToken);

    const [ formErrorState, setFormErrorState ] = useState({
        firstName: '',
        lastName: '',
        organisation: '',
        jobTitle: '',
    })

    const handleChange = (e: CustomEvent<object>) => {
        const { name, value } = e.target as HTMLIonInputElement;

        setUser((prev) => ({ ...prev, [name] : value }))
        handleValidationError(name.replace(/(.)([A-Z][a-z]+)/, '$1_$2').replace(/([a-z0-9])([A-Z])/, '$1_$2').toLowerCase(), '')
    };

    const handleValidationError = (name: string, value: string) => {
        setFormErrorState((prev) => ({ ...prev, [name] : value }));
    }

    // const byteSize = (str: string) => new Blob([str]).size;

    const handleUpload = async () => {
        let header: string = translate('Error');
        let message: string = translate('An error occurred, please try again later');

        try {
            const image = await Camera.getPhoto({
                quality: 60,
                allowEditing: false,
                saveToGallery: true,
                resultType: CameraResultType.DataUrl,
                width: 120
            });
            setIsLoadingProfile(true);            
            if (!image.dataUrl) { return };

            let imgFile = await urltoFile(image.dataUrl, `${uuidv4()}.${image.format}`, `image/${image.format}`);

            if (imgFile.size > 500000) {
                const resizedData: string = await resizedataURL(image.dataUrl, 120);
                imgFile = await urltoFile(resizedData, `${uuidv4()}.${image.format}`, `image/${image.format}`);
            }

            const res = await vp.store(imgFile, {
                visibility: 'public-read',
                signedStorageUrl: `${process.env.REACT_APP_CHATGO_API}/chat/user/upload`,
                headers: {
                    authorization: `Bearer ${userToken}`
                }
            });

            const profile_img = { 
                profile_img: JSON.stringify({
                    key: res.key,
                    name: imgFile.name
                })
            };

            const result = await updateUserProfilePicture(profile_img);
            if (result.data) {
                setUser((prev) => ({ ...prev, ['profilePicture'] : result.data.url }))
                header = translate("Success");
                message = translate("Successfully updated your profile picture");
            }

            present({
                header: header,
                message: message,
                buttons: [
                    translate('OK')
                ],
            });
            
            setIsLoadingProfile(false);

            return
        } catch (error: any) {
            switch (error.message) {
                case translate("User cancelled photos app"): 
                    return; 
                break;
                default:
                    console.error(error.message)
                break;
            }
        }

        setIsLoadingProfile(false);

        present({
            header: header,
            message: message,
            buttons: [
                translate('OK')
            ],
        });
    }
    
    const handleSubmit = async() => {
        let header: string = '';
        let message: string = '';

        // Validate email + password are acceptable
        const payload: UserUpdatePayload = {
            first_name: user.firstName,
            last_name: user.lastName,
            organisation: user.organisation,
            job_title: user.jobTitle,
            profile_picture: user.profilePicture,
        };

        try {
            const result = await updateUser(payload);
            if (result.data) {
                header = translate('Updated');
                message = translate('Successfully updated your profile');
            } else {
                switch (result.error.status) {
                    case 422:
                        header = translate('Invalid Data');
                        message = translate('Please fix errors, and resubmit');
                        Object.keys(result.error.data.errors).forEach((key) => {
                            const keyConvert = key.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase(); });
                            
                            handleValidationError(keyConvert, result.error.data.errors[key]);
                        })
                        break;
                    default:
                        header = translate('Error');
                        message = translate('An error occurred, please try again later');
                }
            }
        } catch (error) {
            header = translate('Error');
            message = translate('An error occurred, please try again later');
        }

        present({
            header: header,
            message: message,
            buttons: [
                translate('OK')
            ],
        });
    }

    useEffect(() => {
        initUser.isInit && setUser(initUser);
    }, [initUser])

    return (
        <IonPage>
            <TabHeader title={translate('Profile Settings')} back hideUser />
            <IonContent fullscreen>
                {!initUser.isInit ? <ProfileSettingsSkeleton/> :
                    <Fragment>
                        <IonGrid className='ion-margin-bottom'>
                            <IonRow>
                                <IonCol size='12' className='ion-text-center ion-margin-top'>
                                    {isLoadingProfile ?
                                            <IonSpinner />
                                        : (
                                        <div>
                                                <IonAvatar
                                                    className='profile-avatar-lg ion-align-items-center ion-justify-content-center'
                                                    style={{
                                                        display: 'flex',
                                                        backgroundColor: 'var(--ion-color-primary)'
                                                    }}
                                                >
                                                {user.profilePicture ?
                                                    <img src={user.profilePicture} alt={`${user.id}`} />
                                                    : <IonIcon icon={personOutline} color='white' size='large' />
                                                }
                                            </IonAvatar>
                                            <IonChip>
                                                <IonIcon icon={arrowUpCircle} />
                                                <IonLabel
                                                    onClick={handleUpload}
                                                >
                                                {translate('Upload')}
                                                </IonLabel>
                                            </IonChip>
                                        </div>
                                    )}
                                </IonCol>
                            </IonRow>
                        </IonGrid>

                        <IonList lines='full'>
                            <IonItem>
                                <IonLabel position='fixed'>{translate('Email')}</IonLabel>
                                <IonInput 
                                    disabled 
                                    type='text' 
                                    value={user.email} 
                                    name="email"
                                />
                            </IonItem>

                            <IonItem>
                                <IonLabel position='floating'>{translate('First Name')}</IonLabel>
                                <IonInput 
                                    type='text' 
                                    placeholder={translate('Your First Name')}
                                    value={user.firstName} 
                                    name="firstName"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {
                                formErrorState.firstName.length > 0 &&
                                <IonText color='danger' className="ion-margin-start">{ formErrorState.firstName }</IonText>
                            }

                            <IonItem>
                                <IonLabel position='floating'>{translate('Last Name')}</IonLabel>
                                <IonInput 
                                    type='text' 
                                    placeholder={translate('Your Last Name')}
                                    value={user.lastName} 
                                    name="lastName"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {
                                formErrorState.lastName.length > 0 &&
                                <IonText color='danger' className="ion-margin-start">{ formErrorState.lastName }</IonText>
                            }

                            <IonItem>
                                <IonLabel position='floating'>{translate('Job Title')}</IonLabel>
                                <IonInput 
                                    type='text' 
                                    placeholder={translate('Your Job Title')}
                                    value={user.jobTitle} 
                                    name="jobTitle"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {
                                formErrorState.jobTitle.length > 0 &&
                                <IonText color='danger' className="ion-margin-start">{ formErrorState.jobTitle }</IonText>
                            }

                            <IonItem>
                                <IonLabel position='floating'>{translate('Organisation')}</IonLabel>
                                <IonInput 
                                    type='text' 
                                    placeholder={translate('Your Organisation')} 
                                    value={user.organisation} 
                                    name="organisation"
                                    onIonChange={handleChange}
                                />
                            </IonItem>
                            {
                                formErrorState.organisation.length > 0 &&
                                <IonText color='danger' className="ion-margin-start">{ formErrorState.organisation }</IonText>
                            }
                        </IonList>

                        <IonButton 
                            expand='block' 
                            color='primary' 
                            className='ion-margin'
                            onClick={handleSubmit}
                            disabled={isLoading || isLoadingProfile}
                        >
                        {(isLoading) ?
                            <IonSpinner />
                            : translate('Save Changes')
                        }
                        </IonButton>
                        <br/>
                    </Fragment>
                }
            </IonContent>
        </IonPage>
    )
}

export default ProfileSettings;