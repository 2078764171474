import { store } from "./store";

const replaceArgs = (value: string, args?: Record<string, string>) => {
    if (typeof args !== 'undefined') {
        for (const [key, val] of Object.entries(args)) {
            const regexp = new RegExp(`:${key}`, 'g');
            value = value.replace(regexp, val);
        }
    }
    return value;
};

export const translate = (translationKey: string, translationArgs?: Record<string, string>) => {
    const state = store.getState();
    const dictionary = state.initData.translations;

    if (!dictionary || !(translationKey in dictionary)) {
        return replaceArgs(translationKey, translationArgs);
    }

    return replaceArgs(dictionary[translationKey], translationArgs);
};
