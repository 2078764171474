import { Room } from "../../app/types/rooms";
import { Group } from "../../app/types/group";
import { User, UserTimestamp } from "../../pages/Auth/types";
import { initUserTimestamp, userNotBlockedBy } from '../../app/firebase';

interface lastMessages{
    [key: string]: {
        message: string,
        time: number,
        sender: User
    }
}

const updateRoomsNotifications = (rooms: Room[], lastRoomMessages: lastMessages, userTimestamps: UserTimestamp) => {
    var count = 0;
    rooms.forEach(room => {
        if (
            (
                lastRoomMessages[room.id] &&
                userTimestamps[room.id] &&
                lastRoomMessages[room.id].time > parseInt(userTimestamps[room.id])
            )
            ||
            (lastRoomMessages[room.id] && !userTimestamps[room.id])
        ) {
            count++;
            if (lastRoomMessages[room.id] && !userTimestamps[room.id]) {
                initUserTimestamp(room.id, Math.round(new Date().getTime() / 1000))
            }
        }
    })
    return count;
}

const updateGroupNotifications = (
    groups: Group[],
    lastGroupMessages: lastMessages,
    userTimestamps: UserTimestamp,
    userId: number
) => {
    var count = 0;
    groups.forEach(group => {
        if (group && group.directMessage && userId) {
            const target = group.members.find(member => member != userId);
            if (!userNotBlockedBy(String(target))) { return };
        }
        if (
            (lastGroupMessages[group.id] && userTimestamps[group.id] && lastGroupMessages[group.id].time > parseInt(userTimestamps[group.id]))
            ||
            (lastGroupMessages[group.id] && !userTimestamps[group.id])
        ){
            count++;
            if (lastGroupMessages[group.id] && !userTimestamps[group.id]) {
                initUserTimestamp(group.id, Math.round(new Date().getTime() / 1000) - 5000)
            }
        }
    })
    return count;
}

export {
    updateRoomsNotifications,
    updateGroupNotifications
}