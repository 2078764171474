export type AppType = 'event' | 'org' | 'chatgo';

export const CheckEnv = ():AppType => {
    if (process.env.REACT_APP_EVENT_TOKEN) {
        return 'event'
    } else if (process.env.REACT_APP_ORGANISATION_KEY) {
        return 'org'
    } else {
        return 'chatgo'
    }
}