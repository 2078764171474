import { SplashScreen } from "@capacitor/splash-screen";
import { Storage } from "@capacitor/storage";
import { getApp, getApps, initializeApp } from "firebase/app";
import {
	browserLocalPersistence, getAuth, initializeAuth, inMemoryPersistence, signInWithCustomToken,
	signOut
} from "firebase/auth";
import {
	getDatabase, onDisconnect, onValue, ref, set
} from "firebase/database";
import {
	collection, deleteDoc, doc, DocumentData, endBefore, Firestore, getDoc, getDocs, getFirestore, limit,
	limitToLast, onSnapshot, orderBy, query, QueryConstraint, QueryDocumentSnapshot, setDoc, startAfter, Timestamp, Unsubscribe,
	updateDoc, where, WhereFilterOp, writeBatch
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import CheckLocal from "../components/Classes/CheckLocal";
import CheckWidget from "../components/Classes/CheckWidget";
import { PostMessageToParent } from "../components/Classes/PostMessage";
import { EventFilter } from "../models/event";
import { Message, MessagePayload } from "../models/message";
import { logout } from "../pages/Auth/authSlice";
import { User } from "../pages/Auth/types";
import videoCallStatus from "./enums/videoCallStatus";
import {
	selectChatFilters,
	selectGlobalChatFilters,
	updateEventToggles,
	updateFilters,
	updateGlobalFilters
} from "./slices/eventSlice";
import {
	checkDMExists,
	checkRealDMExists,
	deleteGroup,
	resetGroupSlice,
	setGroup,
	setGroupCount,
	setNewGroupId, updateGroupMessage,
	updateGroupUsers
} from "./slices/groupSlice";
import { clearMagicEvents, clearMagicTokens } from "./slices/magicEventsSlice";
import { setMatches } from "./slices/matchSlice";
import { setQuestions } from "./slices/questionSlice";
import {
	addMessageBulk, addRoom, addRoomBulk, deleteMessage, deleteRoom, incrementCount, resetRoomSlice, setRoomCount, updateRoomMessage
} from "./slices/roomSlice";
import { setAllSponsors, setSponsorTitle } from "./slices/sponsorSlice";
import {
	selectUser, setUser,
	setUserAnswers, setUserId, setUserMatchmaking,
	setUserSettings, setUserTimestamps, updateUserStatus
} from "./slices/userSlice";
import { store } from "./store";
import { Group } from "./types/group";
import { Match } from "./types/match";
import { Question } from "./types/questions";
import { Room } from "./types/rooms";
import { UserAnswer, UserSettings } from "./types/user";
import { DolbyLoginResponse } from "./types/videoCall";
import { translate } from "./translate";

const isWidget = CheckWidget();

const unsubscribers: Array<Unsubscribe> = [];

const unsubscriberss: { [key: string]: Unsubscribe | undefined } = {
	QUESTION_LISTENER: undefined,
	MATCHES_LISTENER: undefined,
	FILTER_LISTENER: undefined,
	ROOM_LISTENER: undefined,
	GROUP_LISTENER: undefined,
};

const setStorageUser = async (user: any) => {
	await Storage.set({
		key: "user",
		value: JSON.stringify(user),
	});
};

const getStorageUser = async () => {
	const { value } = await Storage.get({ key: "user" });
	return JSON.parse(String(value));
};

const removeStorageUser = async () => {
	await Storage.remove({ key: "user" });
};

export const boostrapFirebase = async () => {
	if (getApps().length === 0 || unsubscribers.length === 0) {
		await SplashScreen.show({
			autoHide: false,
		});

		const appSettings = {
			apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
			authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
			projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
			databaseURL: process.env.REACT_APP_FIRESTORE_URL,
		};

		initializeApp(appSettings);

		const isAutoAuth = store.getState().initData.isAutoAuth;
		const authPersistence = !isWidget
			? browserLocalPersistence
			: isAutoAuth || !CheckLocal()
				? inMemoryPersistence
				: browserLocalPersistence;
		const fToken: string = store.getState().auth.firebase_token;

		let auth = initializeAuth(getApp(), {
			persistence: authPersistence,
		});

		auth.onAuthStateChanged((user) => {
			if (user) {
				const existingUserSetup = async (user: any) => {
					await setUpListeners(user);
					await SplashScreen.hide();
				};

				existingUserSetup(user);
			} else {
				const checkLocalUser = async () => {
					if (CheckLocal() && !isAutoAuth) {
						//if is Mobile, check user storage first
						const storageUser = await getStorageUser();
						if (storageUser) {
							await setUpListeners(storageUser);
							await SplashScreen.hide();
							return;
						} else {
							unsubscribers.forEach((unsub) => unsub());
							await SplashScreen.hide();
						}
					}
				};
				checkLocalUser();
			}
		});
	} else {
		console.log("already boostrapped");
	}
};

export const firebaseLogin = async (
	access_token: string | null = null,
	firebase_token: string | null = null
) => {
	const localAccess = CheckLocal();
	const isAutoAuth = store.getState().initData.isAutoAuth;
	const fToken: string = firebase_token || store.getState().auth.firebase_token;

	if (!fToken) { return };

	try {
		const userCredential = await signInWithCustomToken(getAuth(), fToken);
		localAccess && !isAutoAuth && (await setStorageUser(userCredential.user));
		localAccess && localStorage.setItem('token_timestamp', String(Date.now()));
	} catch (error) {
		console.error(error);
		store.dispatch(logout());
	}
	await SplashScreen.hide();
};

export const setUpListeners = async (user: any) => {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	await (async function () {
		// Listen for user details
		const unsubscribe = onSnapshot(
			doc(db, `events/${key}/users`, user.uid),
			(doc) => {
				const data: any = doc.data();
				const videoCallState = data.callStatus || {
					status: videoCallStatus.available,
				};

				const user: User = {
					id: data.id,
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					organisation: data.organisation,
					jobTitle: data.jobTitle,
					profilePicture: data.profilePicture,
					callStatus: videoCallState,
					online: data.online || false,
					isInit: true,
					is_sponsor_user: data.is_sponsor_user || false
				};

				const timestamps = data.timestamps;
				const answers: Array<UserAnswer> = data.answers;
				const matchmaking: boolean = data.matchmaking
					? Boolean(data.matchmaking)
					: false;

				store.dispatch(setUser(user));
				store.dispatch(setUserId(user.id));
				store.dispatch(setUserTimestamps(timestamps));
				store.dispatch(setUserMatchmaking(matchmaking));
				store.dispatch(setUserAnswers(answers));
			}
		);

		unsubscribers.push(unsubscribe);
	})();

	// Listen for user settings
	await (async function () {
		const unsubscribe = onSnapshot(
			doc(db, `events/${key}/users/${user.uid}/private`, "settings"),
			(doc) => {
				const data: any = doc.data();
				const blockedData = data.blocked as { [key: string]: Timestamp };
				const blockedByData = data.blockedBy as { [key: string]: Timestamp };

				const blocked: { [key: string]: number } = {};
				if (typeof blockedData === "object") {
					Object.keys(blockedData).forEach((key: string) => {
						blocked[`uid-${key}`] = parseInt(String(blockedData[key].seconds));
					});
				}

				let blockedBy: { [key: string]: number } = {};
				if (typeof blockedByData === "object") {
					Object.keys(blockedByData).forEach((key: string) => {
						blockedBy[`uid-${key}`] = parseInt(
							String(blockedByData[key].seconds)
						);
					});
				}

				const userSettings: UserSettings = {
					missedChatEmail: data.missedChatEmail,
					missedChatFrequency: data.missedChatFrequency,
					pushNotifications: data.pushNotifications,
					status: data.status,
					blocked,
					blockedBy,
					isInit: true,
					// temporary hardcoding moderator
					moderator: data.moderator || false,
				};

				store.dispatch(setUserSettings(userSettings));
				PostMessageToParent({ type: "userStatus", message: data.status });
			}
		);

		unsubscribers.push(unsubscribe);
	})();

	// listeners for sponsors
	await (async function () {
		const q = query(
			collection(db, `events/${key}/users`)
			, where('is_sponsor_user', '==', true)
		);
		const unsubscribe = onSnapshot(q, async (sss) => {
			let sponsors: User[] = [];
			sss.forEach((ss) => {
				sponsors.push({
					...ss.data()
				} as User);
			})
			store.dispatch(setAllSponsors(sponsors));
		})
		unsubscribers.push(unsubscribe);
	})();

	await (async function () {
		const unbsubscribe = onSnapshot(doc(db, `events/${key}`), async (doc) => {
			const details: any = doc.data();

			// get event start time
			const startDate: any = details.start;
			// hard coded cutoff date to use empty dm filtered listeners
			const legacyDmCutoffDate: number = 1654732800;
			// boolean for isLegacyEvent?
			const isLegacyEvent: boolean =
				!startDate || startDate.seconds <= legacyDmCutoffDate;

			store.dispatch(updateEventToggles(details.toggles));

			store.dispatch(setSponsorTitle(details.sponsor_title || 'Sponsors'));

			const promises: Array<Promise<Unsubscribe>> = [];
			const eKey: string = key;
			const fifo: Array<string> = [];
			Object.keys(details.toggles).forEach((key: string) => {
				switch (key) {
					case "chat_rooms":
						if (
							details.toggles[key] === true &&
							("ROOM_LISTENER" in unsubscriberss === false ||
								unsubscriberss["ROOM_LISTENER"] === undefined)
						) {
							fifo.push("ROOM_LISTENER");
							promises.push(roomSnapshotListener(db, eKey));
						}

						if (
							"ROOM_LISTENER" in unsubscriberss &&
							unsubscriberss["ROOM_LISTENER"] !== undefined
						) {
							unsubscriberss["ROOM_LISTENER"]();
							unsubscriberss["ROOM_LISTENER"] = undefined;


							while (roomHooks.length > 0) {
								const room = roomHooks.shift();

								room?.unsubscribers.details();
								room?.unsubscribers.messages();
							}
							store.dispatch(resetRoomSlice());
						}
						break;
					case "direct_messaging":
						if (
							details.toggles[key] === true &&
							("DM_LISTENER" in unsubscriberss === false ||
								unsubscriberss["DM_LISTENER"] === undefined)
						) {
							fifo.push("DM_LISTENER");
							// if (isLegacyEvent) {
							// 	promises.push(legacyGroupSnapshotListener(db, eKey, user));
							// } else {
							// 	promises.push(myGroupSnapshotListener(db, eKey, user));
							// 	promises.push(othersGroupSnapshotListener(db, eKey, user));
							// }
							promises.push(legacyGroupSnapshotListener(db, eKey, user));
						}

						if (
							"DM_LISTENER" in unsubscriberss &&
							unsubscriberss["DM_LISTENER"] !== undefined
						) {
							unsubscriberss["DM_LISTENER"]();
							unsubscriberss["DM_LISTENER"] = undefined;


							while (groupHooks.length > 0) {
								const group = groupHooks.shift();

								group?.unsubscribers.details();
								group?.unsubscribers.messages();
							}

							store.dispatch(resetGroupSlice());
						}
						break;
					case "filters":
						if (
							details.toggles[key] === true &&
							("FILTER_LISTENER" in unsubscriberss === false ||
								unsubscriberss["FILTER_LISTENER"] === undefined)
						) {
							fifo.push("FILTER_LISTENER");
							promises.push(filterSnapshotListener(db, eKey));
							promises.push(globalFilterSnapshotListener(db));
						}

						if (
							"FILTER_LISTENER" in unsubscriberss &&
							unsubscriberss["FILTER_LISTENER"] !== undefined
						) {
							unsubscriberss["FILTER_LISTENER"]();
							unsubscriberss["FILTER_LISTENER"] = undefined;

							// store.dispatch(updateFilters([]));
						}
						break;
					case "matchmaking":
						if (
							details.toggles[key] === true &&
							("QUESTION_LISTENER" in unsubscriberss === false ||
								unsubscriberss["QUESTION_LISTENER"] === undefined)
						) {
							fifo.push("QUESTION_LISTENER");
							promises.push(questionSnapshotListener(db, eKey));
						}

						if (
							details.toggles[key] === true &&
							("MATCHES_LISTENER" in unsubscriberss === false ||
								unsubscriberss["MATCHES_LISTENER"] === undefined)
						) {
							fifo.push("MATCHES_LISTENER");
							promises.push(matchesSnapshotListener(db, eKey, user));
						}

						if (details.toggles[key] === false) {
							if (
								"QUESTION_LISTENER" in unsubscriberss &&
								unsubscriberss["QUESTION_LISTENER"] !== undefined
							) {
								unsubscriberss["QUESTION_LISTENER"]();
								unsubscriberss["QUESTION_LISTENER"] = undefined;

								store.dispatch(setQuestions([]));
							}

							if (
								"MATCHES_LISTENER" in unsubscriberss &&
								unsubscriberss["MATCHES_LISTENER"] !== undefined
							) {
								unsubscriberss["MATCHES_LISTENER"]();
								unsubscriberss["MATCHES_LISTENER"] = undefined;

								store.dispatch(setMatches([]));
							}
						}
						break;
					default:
						console.error("unknown event toggle", key);
						break;
				}
			});

			await Promise.all(promises).then((response) => {
				const dd = response;
				while (fifo.length > 0) {
					const action = String(fifo.shift());
					const unsubscriber = dd.shift();

					unsubscriberss[action] = unsubscriber;
				}
			});
		});
	})();

	const promises = [];

	const statusPromise = (async function () {
		// Fetch the current user's ID from Firebase Authentication.
		const firebase = getDatabase();
		var uid = user.uid;

		// Create a reference to this user's specific status node.
		// This is where we will store data about being online/offline.
		var userStatusDatabaseRef = ref(
			firebase,
			`chat_data_collection/${key}/status/` + uid
		);

		// We'll create two constants which we will write to
		// the Realtime database when this device is offline
		// or online.
		var isOfflineForDatabase = {
			state: "offline",
			last_changed: Timestamp.now(),
			last_seen_location: store.getState().user.currentChannel,
		};

		var isOnlineForDatabase = {
			state: "online",
			last_changed: Timestamp.now(),
			last_seen_location: store.getState().user.currentChannel,
		};

		// Create a reference to the special '.info/connected' path in
		// Realtime Database. This path returns `true` when connected
		// and `false` when disconnected.
		onValue(ref(firebase, ".info/connected"), function (snapshot) {
			console.log(`DB onValue for info/connected, value:`, snapshot.val());

			// If we're not currently connected, don't do anything.
			if (snapshot.val() == false) {
				// If we are offline and current status is not manually set
				// then update it to offline
				const current = store.getState().user.settings.status;
				if (current.manual === false) {
					store.dispatch(
						updateUserStatus({
							status: "offline",
							manual: false,
						})
					);
				}
				return;
			}

			// If we are currently connected, then use the 'onDisconnect()'
			// method to add a set which will only trigger once this
			// client has disconnected by closing the app,
			// losing internet, or any other means.
			onDisconnect(userStatusDatabaseRef)
				.set(isOfflineForDatabase)
				.then(function () {
					console.log('onDisconnect > then called');
					// The promise returned from .onDisconnect().set() will
					// resolve as soon as the server acknowledges the onDisconnect()
					// request, NOT once we've actually disconnected:
					// https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

					// We can now safely set ourselves as 'online' knowing that the
					// server will mark us as offline once we lose connection.
					set(userStatusDatabaseRef, isOnlineForDatabase);

					// If we are online and current status is not manually set
					// them update it to online
					const current = store.getState().user.settings.status;
					if (current.manual === false) {
						console.log(`current.manual === false, will dispatch user status online`);
						store.dispatch(
							updateUserStatus({
								status: "online",
								manual: false,
							})
						);
					} else {
						console.log(`current.manual === true, not touching user status`);
					}
				});
		});
	})();

	promises.push(statusPromise);

	await Promise.all(promises);
};

export const sendMessage = async (message: string, id: number | string) => {
	return new Promise(async (resolve) => {
		if (message.length > 0) {
			const filters = selectChatFilters(store.getState());
			const globalFilters = selectGlobalChatFilters(store.getState());
			const db = getFirestore();
			const key: string = store.getState().auth.event_key;
			const user: User = store.getState().user.user;
			const isGroup: boolean = typeof id === "string";
			let groupMsgCount: number = 0;

			// check if group, and if group isEmpty
			if (isGroup) {
				groupMsgCount =
					store.getState().groups.groups.find((group) => group.id === id)
						?.messages.length || 0;
			}

			if (filters.length > 0) {
				if (message.toLowerCase().match(new RegExp(filters))) {
					resolve("ILLEGAL");
					return;
				}
			}

			if (globalFilters.length > 0) {
				if (message.toLowerCase().match(new RegExp(globalFilters))) {
					resolve("ILLEGAL");
					return;
				}
			}

			if (message.length >= 500) {
				resolve("EXCEEDED");
				return;
			}

			const payload: MessagePayload = {
				id: null,
				message: message,
				sender: {
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					jobTitle: user.jobTitle,
					organisation: user.organisation,
					profilePicture: user.profilePicture,
					email: user.email,
					online: user.online,
					is_sponsor_user: user.is_sponsor_user,
					callStatus: user.callStatus,
				},
				status: "sent",
				recipient: id,
				systemMessage: false,
				timestamp: new Timestamp(Math.floor(Date.now() / 1000), 0),
			};

			const batch = writeBatch(db);

			batch.set(doc(collection(db, `events/${key}/messages`)), payload);

			await batch.commit();

			if (isGroup && groupMsgCount <= 0) {
				// set Group doc > isEmpty to false
				await setDoc(
					doc(db, `events/${key}/groups/${id}`),
					{ isEmpty: false },
					{ merge: true }
				);
			}

			resolve("SENT");
		}
	});
};

// update message
export const updateMessage = async (message: MessagePayload) => {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	const messageRef = doc(db, `events/${key}/messages/${message.id}`);

	// await updateDoc(userRef, {
	//     status: { groupId, status: videoCallStatus.answeredCall },
	// });

	await updateDoc(messageRef, { ...message });
};

// Get or Create DM Group
export const getDMGroup = async (recipient: number) => {
	const key: string = store.getState().auth.event_key;

	// Check if there is already a group chat between users
	const id = checkRealDMExists(recipient);
	if (id.length > 0) {
		return id;
	}

	const functions = getFunctions(getApp(), "europe-west2");
	const addDM = httpsCallable(functions, "createDM");

	// Create the group chat
	const data: any = await addDM({
		recipient,
		key,
	});

	if (data.data.groupId) {
		// const groupCurrentCount = store.getState().groups.count || 0;
		// store.dispatch(setGroupCount(groupCurrentCount + 1));
		// setUpGroupSnaphots(data.data.groupId);
		return data.data.groupId;
	} else {
		return null;
	}
};

// new get DM group function
export const getDMGroup2 = async (recipient: User) => {
	// check if DM group exist on redux
	const existGroupId = checkDMExists(recipient.id)(store.getState());

	// if dm exist on redux, return id for use
	if (existGroupId.length > 0) {
		return existGroupId;
	}

	const authUser = store.getState().user.user;
	const userIds: number[] = [authUser.id, recipient.id];
	const tempGroupId: string = `new`;
	let users: User[] = [];
	users[authUser.id] = authUser;
	users[recipient.id] = recipient;

	// otherwise, create a temp dm with the recipient id
	const tempGroup: Group = {
		id: tempGroupId,
		name: "",
		directMessage: true,
		admins: userIds,
		members: userIds,
		meta: {
			createdBy: Date.now(),
			thumbnail: "",
		},
		modifiedAt: "",
		createdAt: "",
		messages: [],
		users,
	};
	store.dispatch(setGroup(tempGroup));

	return tempGroupId;
};

export const createNewDM = async (
	recipient: number
): Promise<string | null> => {
	const key: string = store.getState().auth.event_key;

	const functions = getFunctions(getApp(), "europe-west2");
	const addDM = httpsCallable(functions, "createDM");

	// Create the group chat
	const data: any = await addDM({
		recipient,
		key,
	});

	if (data.data.groupId) {
		return data.data.groupId;
	} else {
		return null;
	}
};

// Delete DM group in firebase (used for groups created with no message)
export const cleanupDMGroup = async () => {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;
	const currentEmptyGroups = store
		.getState()
		.groups.groups.filter((group) => group.messages.length <= 0);
	if (currentEmptyGroups.length > 0) {
		let userTimestamps = { ...store.getState().user.timestamps };
		const userRef = doc(
			getFirestore(),
			`events/${key}/users/${store.getState().user.user.id}`
		);

		currentEmptyGroups.forEach((group) => {
			deleteDoc(doc(db, `events/${key}/groups`, group.id));
			delete userTimestamps[group.id];
		});

		updateDoc(userRef, { timestamps: userTimestamps });
	}
	return;
};

export const roomHooks: Array<{
	id: string;
	unsubscribers: {
		details: Unsubscribe;
		messages: Unsubscribe;
	};
}> = [];

export const groupHooks: Array<{
	id: string;
	unsubscribers: {
		details: Unsubscribe;
		users: Unsubscribe;
		messages: Unsubscribe;
	};
}> = [];

function setUpGroupSnaphots(id: string) {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;
	const existedGroup = store
		.getState()
		.groups.groups.find((group) => group.id === id);
	if (existedGroup) {
		return;
	}

	const details = onSnapshot(doc(db, `events/${key}/groups`, id), (doc) => {
		const data: any = doc.data();
		var messages: Array<Message> = [];
		var users: Array<User> = [];

		const existing = store
			.getState()
			.groups.groups.find((group: Group) => group.id === data.id);

		if (!existing) {
			const group: Group = {
				id: data.id,
				name: data.name,
				directMessage: data.directMessage,
				admins: data.admins,
				members: data.members,
				meta: {
					createdBy: data.meta.createdBy,
					thumbnail: data.meta.thumbnail,
				},
				modifiedAt: data.modifiedAt.toDate().toUTCString(),
				createdAt: data.createdAt.toDate().toUTCString(),
				messages,
				users,
			};

			store.dispatch(setGroup(group));

			// check for exist new group
			const authUserId: number = store.getState().user.userId;
			const recipientId: number | undefined = group.members.find(
				(x) => x !== authUserId
			);
			if (recipientId) {
				const existTempGroup: Group | undefined = store
					.getState()
					.groups.groups.find(
						(x) => x.id === "new" && x.members.includes(recipientId)
					);
				if (existTempGroup) {
					store.dispatch(setNewGroupId(data.id));
				}
			}
		}
	});

	const users = onSnapshot(
		query(collection(db, `events/${key}/groups/${id}/users`)),
		(querySnapshot) => {
			const users: Record<number, User> = {};
			querySnapshot.forEach((doc) => {
				const data = doc.data();
				const user: User = {
					id: data.id,
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					jobTitle: data.jobTitle,
					organisation: data.organisation,
					profilePicture: data.profilePicture,
					online: data.online,
					callStatus: data.callStatus,
					is_sponsor_user: data.is_sponsor_user || false
					// timestamps: data.timestamps,
				};

				users[user.id] = user;
			});

			store.dispatch(
				updateGroupUsers({
					id,
					users,
				})
			);
		}
	);

	const messages = onSnapshot(
		query(
			collection(db, `events/${key}/messages`),
			where("recipient", "==", id),
			limitToLast(50),
			orderBy("timestamp", "asc")
		),
		(querySnapshot) => {
			const messages: Array<Message> = [];
			querySnapshot.docChanges().forEach((change) => {
				if (change.type == "added") {
					const message = change.doc.data();

					// Check if the user is not from a blocked user
					if (
						userNotBlocked(message.sender.id, message.timestamp.seconds) &&
						userNotBlockedBy(message.sender.id)
					) {
						let sender: User = {
							id: message.sender.id,
							firstName: message.sender.firstName,
							lastName: message.sender.lastName,
							jobTitle: message.sender.jobTitle,
							organisation: message.sender.organisation,
							profilePicture: message.sender.profilePicture,
							email: message.sender.email,
							online: message.sender.online,
							callStatus: message.sender.callStatus || {
								status: videoCallStatus.available,
							},
							is_sponsor_user: message.sender.is_sponsor_user || false
							// timestamps: message.sender.timestamps,
						};

						messages.push({
							id: change.doc.id,
							message: message.message,
							sender,
							status: message.status,
							recipient: message.recipient,
							systemMessage: message.systemMessage,
							timestamp: message.timestamp.seconds,
						});

						PostMessageToParent({
							type: "newChats",
							message: {
								id: change.doc.id,
								message: message.message,
								sender,
								timestamp: message.timestamp.seconds,
								recipient: message.recipient,
								systemMessage: message.systemMessage,
								status: message.status,
							},
						});
					}
				} else if (change.type == "removed") {
					// pending handling
				} else if (change.type == "modified") {
					// pending handling
				}
			});

			if (messages.length > 0) {
				const last = messages[messages.length - 1];
				updateUserLastRead(id, last.timestamp);
				store.dispatch(
					updateGroupMessage({
						id,
						messages,
					})
				);
			}
		}
	);

	groupHooks.push({
		id,
		unsubscribers: {
			details,
			users,
			messages,
		},
	});

	unsubscribers.push(details);
	unsubscribers.push(users);
	unsubscribers.push(messages);
}

// setup load more group messages function
export async function getNextPageGroupMessages(
	id: string,
	lastMessageId: string
) {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	const olderMessages: Array<Message> = [];

	// get last message doc using id
	const lastMessageDoc = await getDoc(
		doc(db, `events/${key}/messages/${lastMessageId}`)
	);

	const messageQuery = query(
		collection(db, `events/${key}/messages`),
		where("recipient", "==", id),
		limitToLast(50),
		orderBy("timestamp", "asc"),
		endBefore(lastMessageDoc)
	);

	const querySnapshot = await getDocs(messageQuery);

	querySnapshot.forEach((doc) => {
		const message = doc.data();
		// Check if the user is not from a blocked user
		if (
			userNotBlocked(message.sender.id, message.timestamp.seconds) &&
			userNotBlockedBy(message.sender.id)
		) {
			let sender: User = {
				id: message.sender.id,
				firstName: message.sender.firstName,
				lastName: message.sender.lastName,
				jobTitle: message.sender.jobTitle,
				organisation: message.sender.organisation,
				profilePicture: message.sender.profilePicture,
				email: message.sender.email,
				online: message.sender.online,
				is_sponsor_user: message.sender.is_sponsor_user || false,
				callStatus: message.sender.callStatus || {
					status: videoCallStatus.available,
				},
				// timestamps: message.sender.timestamps,
			};

			olderMessages.push({
				id: doc.id,
				message: message.message,
				sender,
				status: message.status,
				recipient: message.recipient,
				systemMessage: message.systemMessage,
				timestamp: message.timestamp.seconds,
			});
		}
	});
	return olderMessages;
}

function setUpRoomSnapshots(id: string) {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	// First set up listener for room itself
	const details = onSnapshot(doc(db, `events/${key}/rooms`, id), (doc) => {
		const data: any = doc.data();

		store.dispatch(
			addRoom({
				id: parseInt(doc.id),
				name: data.name,
				order: data.order,
			})
		);
	});

	// Then set up listener for room messages
	const messages = onSnapshot(
		query(
			collection(db, `events/${key}/messages`),
			where("recipient", "==", parseInt(id)),
			limitToLast(50),
			orderBy("timestamp", "asc")
		),
		(querySnapshot) => {
			const added: Array<Message> = [];
			querySnapshot.docChanges().forEach((change) => {
				if (change.type == "added") {
					const message = change.doc.data();
					const userTimestamps = store.getState().user.timestamps;
					const userTimestampsRooms =
						userTimestamps && Object.keys(userTimestamps).length > 0
							? Object.entries(store.getState().user.timestamps)
								.filter((item) => !isNaN(parseInt(item[0])))
								.sort((a, b) => Number(b[1]) - Number(a[1]))
							: [];
					const isFromLatestRoom =
						userTimestampsRooms.length > 0
							? Number(userTimestampsRooms[0][0]) === message.recipient
							: false;
					const isNotSelf = message.sender.id !== store.getState().user.userId;

					// Check if the user is not from a blocked user
					if (
						userNotBlocked(message.sender.id, message.timestamp.seconds) &&
						userNotBlockedBy(message.sender.id)
					) {
						let sender: User = {
							id: message.sender.id,
							firstName: message.sender.firstName,
							lastName: message.sender.lastName,
							jobTitle: message.sender.jobTitle,
							organisation: message.sender.organisation,
							profilePicture: message.sender.profilePicture,
							email: message.sender.email,
							online: message.sender.online,
							callStatus: message.sender.callStatus || {
								status: videoCallStatus.available,
							},
							is_sponsor_user: message.sender.is_sponsor_user ? message.sender.is_sponsor_user : false
							// timestamps: message.sender.timestamps,
						};

						added.push({
							id: change.doc.id,
							message: message.message,
							sender,
							timestamp: message.timestamp.seconds,
							recipient: message.recipient,
							systemMessage: message.systemMessage,
							status: message.status,
						});

						isFromLatestRoom &&
							isNotSelf &&
							PostMessageToParent({
								type: "newChats",
								message: {
									id: change.doc.id,
									message: message.message,
									sender,
									timestamp: message.timestamp.seconds,
									recipient: message.recipient,
									systemMessage: message.systemMessage,
									status: message.status,
								},
							});
					}
				} else if (change.type == "removed") {
					store.dispatch(
						deleteMessage({
							id: parseInt(id),
							message: change.doc.id,
						})
					);
				} else if (change.type == "modified") {
					const message = change.doc.data();

					let sender: User = {
						id: message.sender.id,
						firstName: message.sender.firstName,
						lastName: message.sender.lastName,
						jobTitle: message.sender.jobTitle,
						organisation: message.sender.organisation,
						profilePicture: message.sender.profilePicture,
						email: message.sender.email,
						online: message.sender.online,
						is_sponsor_user: message.sender.is_sponsor_user || false,
						callStatus: message.sender.callStatus || {
							status: videoCallStatus.available,
						},
					};
					const modified: Message = {
						id: change.doc.id,
						message: message.message,
						sender,
						timestamp: message.timestamp.seconds,
						recipient: message.recipient,
						systemMessage: message.systemMessage,
						status: message.status,
					};
					store.dispatch(
						updateRoomMessage({
							id: parseInt(id),
							message: modified,
						})
					);
				}
			});

			if (added.length > 0) {
				const last = added[added.length - 1];
				updateUserLastRead(parseInt(id), last.timestamp);

				store.dispatch(
					addMessageBulk({
						id: parseInt(id),
						added,
					})
				);
			}
		}
	);
	roomHooks.push({
		id,
		unsubscribers: {
			details,
			messages,
		},
	});

	unsubscribers.push(details);
	unsubscribers.push(messages);
}

// setup load more room message function
export async function getNextPageRoomMessages(
	id: string,
	lastMessageId: string
) {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	const olderMessages: Array<Message> = [];

	// get last message doc using id
	const lastMessageDoc = await getDoc(
		doc(db, `events/${key}/messages/${lastMessageId}`)
	);

	//return
	const messageQuery = query(
		collection(db, `events/${key}/messages`),
		where("recipient", "==", parseInt(id)),
		limitToLast(50),
		orderBy("timestamp", "asc"),
		endBefore(lastMessageDoc)
	);
	const querySnapshot = await getDocs(messageQuery);
	querySnapshot.forEach((doc) => {
		const message = doc.data();
		if (
			userNotBlocked(
				message.sender.id,
				message.timestamp.seconds && userNotBlockedBy(message.sender.id)
			)
		) {
			let sender: User = {
				id: message.sender.id,
				firstName: message.sender.firstName,
				lastName: message.sender.lastName,
				jobTitle: message.sender.jobTitle,
				organisation: message.sender.organisation,
				profilePicture: message.sender.profilePicture,
				email: message.sender.email,
				online: message.sender.online,
				is_sponsor_user: message.sender.is_sponsor_user || false,
				callStatus: message.sender.callStatus || {
					status: videoCallStatus.available,
				},
				// timestamps: message.sender.timestamps,
			};

			olderMessages.push({
				id: doc.id,
				message: message.message,
				sender,
				timestamp: message.timestamp.seconds,
				recipient: message.recipient,
				systemMessage: message.systemMessage,
				status: message.status,
			});
		}
	});

	return olderMessages;
	// olderMessages.length > 0 && store.dispatch(addOlderMessageBulk({
	//     id: parseInt(id),
	//     added: olderMessages
	// }));
}

export function userNotBlocked(uid: string, time?: number): boolean {
	const ident = `uid-${uid}`;

	if (ident in store.getState().user.settings.blocked) {
		if (time === undefined) {
			return false;
		}

		const seconds = store.getState().user.settings.blocked[ident];
		return seconds > time;
	}

	return true;
}

export function userNotBlockedBy(uid: string, time?: number): boolean {
	const ident = `uid-${uid}`;

	if (ident in store.getState().user.settings.blockedBy) {
		if (time === undefined) {
			return false;
		}

		const seconds = store.getState().user.settings.blockedBy[ident];
		return seconds > time;
	}

	return true;
}

export async function videoCallGroup(groupId: string): Promise<number[]> {
	const key: string = store.getState().auth.event_key;
	const functions = getFunctions(getApp(), "europe-west2");
	const startCall = httpsCallable(functions, "startVideoCallWithUser");

	// start the video call for the group
	const data: any = await startCall({
		key,
		groupId,
	});

	return data.data;
}

export async function pickUpVideoCall(groupId: string): Promise<void> {
	const userId = store.getState().user.user.id;
	const key: string = store.getState().auth.event_key;

	const userRef = doc(getFirestore(), `events/${key}/users/${userId}`);

	await updateDoc(userRef, {
		callStatus: { groupId, status: videoCallStatus.answeredCall },
	});
}

export async function hangUpVideoCall(groupId: string): Promise<number[]> {
	try {
		const userId = store.getState().user.user.id;
		const key: string = store.getState().auth.event_key;

		const userRef = doc(getFirestore(), `events/${key}/users/${userId}`);

		// update current user immediately to reflect in UI
		await updateDoc(userRef, {
			callStatus: { status: videoCallStatus.available },
		});

		const functions = getFunctions(getApp(), "europe-west2");

		// hangup call for other users using function
		const hangUpCall = httpsCallable(functions, "endVideoCallForGroup");

		// hang up the video call for the group
		const data: any = await hangUpCall({
			key,
			groupId,
			endpoint:
				process.env.REACT_APP_WEBCAM_API_URL || "https://api.streamgo.vc",
		});

		return data.data;
	} catch (err) {
		console.error(err);
		return [];
	}
}

export const videoCallRecipient = (
	recipientId: number,
	setLoadingVideoCall: (loading: boolean) => void,
	showAlert: (alert: string) => void
) => {
	let groupId: string;

	setLoadingVideoCall(true);
	getDMGroup(recipientId)
		.then((id: string) => {
			groupId = id;
			return videoCallGroup(id);
		})
		.catch((err) => {
			console.error(err);
			if (err.toString().startsWith("FirebaseError")) {
				showAlert(err.toString().substring(15));
			} else {
				showAlert(translate("Could not start the call"));
			}

			if (groupId) hangUpVideoCall(groupId);
			setLoadingVideoCall(false);
		});
};

export async function getDolbyToken(
	groupId: string
): Promise<DolbyLoginResponse | null> {
	try {
		const key: string = store.getState().auth.event_key;
		const functions = getFunctions(getApp(), "europe-west2");

		const getDolbyToken = httpsCallable(functions, "getDolbyToken");

		// get the Dolby Token needed for connecting
		const data: any = await getDolbyToken({
			key,
			groupId,
			endpoint:
				process.env.REACT_APP_WEBCAM_API_URL || "https://api.streamgo.vc",
		});

		return data.data;
	} catch (err) {
		console.error(err);
		return null;
	}
}

export const listenForUser = (
	id: number,
	callback: (data: any) => void
): Unsubscribe => {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	const unsubscribe = onSnapshot(
		doc(db, `events/${key}/users`, String(id)),
		(doc) => {
			const data: any = doc.data();
			callback(data);
		}
	);

	return unsubscribe;
};

export const getUserById = async (id: number) => {
	const db = getFirestore();
	const key: string = store.getState().auth.event_key;
	const userRef = doc(db, `events/${key}/users/${id}`);
	const snap = await getDoc(userRef);
	if (snap.exists()) {
		return snap.data();
	}
};

export async function fetchBlockedUserDetails() {
	const list = Object.keys(store.getState().user.settings.blocked);
	const users: Array<User> = [];

	if (list.length <= 0) {
		return users;
	}

	const db = getFirestore();
	const key: string = store.getState().auth.event_key;

	for (const uid of list) {
		const id = parseInt(uid.replace("uid-", ""));
		const blockUserRef = doc(db, `events/${key}/users/${id}`);
		const blockUserSnap = await getDoc(blockUserRef);
		if (blockUserSnap.exists()) {
			users.push(blockUserSnap.data() as User);
		}
	}

	// const chunkArray = (list: any[], chunk: number): any[][] => {
	//     const result = [];

	//     for (let i = 0; i < list.length; i += chunk) {
	//         result.push(list.slice(i, i + chunk));
	//     }

	//     return result;
	// };

	// const chunks = chunkArray(list, 1);

	// for await (const snap of chunks.map(
	//     async (chunk) => {
	//         const processed = chunk.map((chunk) => parseInt(chunk.replace("uid-", "")));
	//         return await getDocs(
	//             query(
	//                 collection(db, `events/${key}/users`),
	//                 where('id', 'in', processed)
	//             )
	//         )
	//     }
	// )) {
	//     snap.forEach((doc) => {
	//     users.push((doc.data()) as User);
	//   })
	// }

	return users;
}

export function updateUserLastRead(id: number | string, timestamp: number) {
	const currentChannel = store.getState().user.currentChannel;
	const lastTime = store.getState().user.timestamps[id];
	const key: string = store.getState().auth.event_key;
	if (
		currentChannel.includes(id) &&
		(lastTime === undefined || timestamp > parseInt(lastTime))
	) {
		// update users last read
		const userRef = doc(
			getFirestore(),
			`events/${key}/users/${store.getState().user.user.id}`
		);

		updateDoc(userRef, {
			[`timestamps.${id}`]: timestamp,
		});
	}
}

export function updateLastLocation(payload: {
	location: string;
	id: string;
	timestamp: number;
}) {
	const apps = getApps();
	if (apps.length <= 0) {
		return;
	}

	const key: string = store.getState().auth.event_key;
	const userRef = doc(
		getFirestore(),
		`events/${key}/users/${store.getState().user.user.id}`
	);
	updateDoc(userRef, { lastLocation: payload });
}

export function initUserTimestamp(id: number | string, timestamp: number) {
	// const currentChannel = store.getState().user.currentChannel;
	const lastTime = store.getState().user.timestamps[id];
	const key: string = store.getState().auth.event_key;
	if (lastTime === undefined || timestamp > parseInt(lastTime)) {
		// update users last read
		const userRef = doc(
			getFirestore(),
			`events/${key}/users/${store.getState().user.user.id}`
		);

		updateDoc(userRef, {
			[`timestamps.${id}`]: timestamp,
		});
	}
}

export async function userLogout(remain: boolean = false) {
	if (getApps().length === 0) {
		return Promise.resolve(false);
	}
	const userId = store.getState().user.user.id;
	const key: string = store.getState().auth.event_key;

	const userRef = doc(getFirestore(), `events/${key}/users/${userId}`);

	await updateDoc(userRef, {
		online: false,
	});

	const user = await getStorageUser();

	if (user) {
		removeStorageUser();
	} else {
	}

	const auth = getAuth();
	try {
		await signOut(auth);
		store.dispatch(clearMagicTokens());
		store.dispatch(clearMagicEvents());
		store.dispatch(logout());
		!remain && (window.location.href = `/`);
		return Promise.resolve(true);
	} catch (error) {
		return Promise.resolve(false);
	}
}

const questionSnapshotListener = async (db: Firestore, key: string) => {
	const q = query(collection(db, `events/${key}/questions`));

	const unsubscribe = onSnapshot(q, (querySnapshot) => {
		const questions: Array<Question> = [];
		querySnapshot.forEach((doc) => {
			let data = doc.data();
			const question: Question = {
				id: parseInt(doc.id),
				question: data.question,
				type: data.type,
				answers: data.answers,
				order: data.order || 0,
			};

			questions.push(question);
		});

		store.dispatch(setQuestions(questions));
	});

	return unsubscribe;
};

const matchesSnapshotListener = async (
	db: Firestore,
	key: string,
	user: any
) => {
	const matches = collection(db, `events/${key}/matches`);
	const unsubscribe = onSnapshot(
		query(
			matches,
			orderBy("match", "desc"),
			limit(360),
			where("subject", "==", parseInt(user.uid))
		),
		(querySnapshot) => {
			const matches: Array<Match> = [];
			querySnapshot.forEach((doc) => {
				let data = doc.data();
				
				matches.push({
					match: data.match,
					user: data.user,
				});
			});

			store.dispatch(setMatches(matches));
		}
	);

	return unsubscribe;
};

const filterSnapshotListener = async (db: Firestore, key: string) => {
	const filtersQuery = query(collection(db, `events/${key}/filters`));

	const unsubscribe = onSnapshot(filtersQuery, (querySnapshot) => {
		const filters: Array<EventFilter> = [];

		querySnapshot.forEach((change) => {
			const filter = change.data();
			filters.push(filter as EventFilter);
		});

		let chatExpress = "";
		const user = selectUser(store.getState());

		filters.forEach((filter) => {
			const value = filter.value.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
			if (filter.type === "chat" && filter.allow === false) {
				if (filter.wildcard === true) {
					chatExpress += `${filter.value}|`;
				} else {
					chatExpress += `\\b${filter.value}\\b|`;
				}
			} else if (filter.type === "email") {
				if (filter.wildcard === true) {
					var expression = `/(${value})/`;
				} else {
					var expression = `/(\\b${value}\\b)/`;
				}

				if (user.email === filter.value && !filter.allow) {
					userLogout();
				}
			}
		});

		if (chatExpress.length > 0) {
			chatExpress = chatExpress.substring(0, chatExpress.length - 1);
			const regex = new RegExp(`(${chatExpress})`);

			store.dispatch(updateFilters(regex.source));
		}
	});

	return unsubscribe;
};

const globalFilterSnapshotListener = async (db: Firestore) => {
	const filtersQuery = query(collection(db, `filters`));

	const unsubscribe = onSnapshot(filtersQuery, (querySnapshot) => {
		const filters: Array<EventFilter> = [];

		querySnapshot.forEach((change) => {
			const filter = change.data();
			filters.push(filter as EventFilter);
		});

		let chatExpress = "";
		const user = selectUser(store.getState());

		filters.forEach((filter) => {
			const value = filter.value.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
			if (filter.type === "chat" && filter.allow === false) {
				if (filter.wildcard === true) {
					chatExpress += `${filter.value}|`;
				} else {
					chatExpress += `\\b${filter.value}\\b|`;
				}
			} else if (filter.type === "email") {
				if (filter.wildcard === true) {
					var expression = `/(${value})/`;
				} else {
					var expression = `/(\\b${value}\\b)/`;
				}

				if (user.email === filter.value && !filter.allow) {
					userLogout();
				}
			}
		});

		if (chatExpress.length > 0) {
			chatExpress = chatExpress.substring(0, chatExpress.length - 1);
			const regex = new RegExp(`(${chatExpress})`);

			store.dispatch(updateGlobalFilters(regex.source));
		}
	});

	return unsubscribe;
};

const roomSnapshotListener = async (db: Firestore, key: string) => {
	const rooms = query(collection(db, `events/${key}/rooms`));

	const roomsData: { [key: number]: Room } = {};
	const querySnapshot = await getDocs(rooms);
	querySnapshot.forEach((doc) => {
		const data: any = doc.data();

		roomsData[parseInt(doc.id)] = {
			id: parseInt(doc.id),
			name: data.name,
			order: data.order,
		};
	});

	store.dispatch(setRoomCount(Object.keys(roomsData).length));

	store.dispatch(addRoomBulk(roomsData));

	Object.keys(roomsData).forEach((id) => {
		setUpRoomSnapshots(id);
	});

	// Set up mass listener
	// this listens for creation/ deletion events for rooms
	const unsubscribe = onSnapshot(rooms, (querySnapshot) => {
		// const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
		if (
			store.getState().rooms.count !== null &&
			querySnapshot.size !== store.getState().rooms.count &&
			(store.getState().rooms.count ?? 0) < Object.keys(roomsData).length
		) {
			querySnapshot.docChanges().forEach((change) => {
				if (change.type == "added") {
					store.dispatch(incrementCount());
					setUpRoomSnapshots(change.doc.id);
				}

				if (change.type == "removed") {
					const hooks = roomHooks.filter((hook) => hook.id === change.doc.id);

					if (hooks.length === 1) {
						hooks[0].unsubscribers.details();
						hooks[0].unsubscribers.messages();

						store.dispatch(deleteRoom(parseInt(change.doc.id)));
					} else {
						console.error(
							"cannot find unsubscibe methods for " + change.doc.id
						);
					}
				}
			});
		}
	});

	return unsubscribe;
};

const legacyGroupSnapshotListener = async (
	db: Firestore,
	key: string,
	user: any
) => {
	let ids: Array<string> = [];

	// legacy gorup query getting all dm regardless init by and isEmpty
	const groupsQuery = query(
		collection(db, `events/${key}/groups`),
		where("members", "array-contains", parseInt(user.uid)),
		orderBy("meta.lastSent", "desc")
	);

	const querySnapshot = await getDocs(groupsQuery);

	querySnapshot.forEach((doc) => {
		ids.push(doc.id);
	});

	store.dispatch(setGroupCount(ids.length));

	ids.forEach((id) => {
		setUpGroupSnaphots(id);
	});

	const unsubscribe = onSnapshot(groupsQuery, (querySnapshot) => {
		// if (querySnapshot.size !== store.getState().groups.count) {
		querySnapshot.docChanges().forEach((change) => {
			if (change.type == "added") {
				setUpGroupSnaphots(change.doc.id);
				const groupCurrentCount = store.getState().groups.count || 0;
				store.dispatch(setGroupCount(groupCurrentCount + 1));
			}

			if (change.type == "removed") {
				const hooks = groupHooks.filter((hook) => hook.id === change.doc.id);

				if (hooks.length > 0) {
					hooks[0].unsubscribers.details();
					hooks[0].unsubscribers.messages();
					hooks[0].unsubscribers.users();
					store.dispatch(deleteGroup);
					const groupCurrentCount = store.getState().groups.count || 1;
					store.dispatch(setGroupCount(groupCurrentCount - 1));
				} else {
					console.error("cannot find unsubscibe methods for " + change.doc.id);
				}
			}
		});
		// }
	});

	return unsubscribe;
};

const myGroupSnapshotListener = async (
	db: Firestore,
	key: string,
	user: any
) => {
	let ids: Array<string> = [];

	// Get individual ids of groups
	// so we can add individual listeners
	// UPDATED: We need 2 queries, 1 for groups init by me, 1 for init by others
	// for init by me, disregard the isEmpty, for init by others, don't query the empty groups
	const myGroupsQuery = query(
		collection(db, `events/${key}/groups`),
		where("members", "array-contains", parseInt(user.uid)),
		where("initBy", "==", parseInt(user.uid)),
		// where('isEmpty', "!=", true),
		orderBy("meta.lastSent", "desc")
		// orderBy('isEmpty')
	);

	const querySnapshot = await getDocs(myGroupsQuery);

	querySnapshot.forEach((doc) => {
		ids.push(doc.id);
	});

	store.dispatch(setGroupCount(ids.length));

	ids.forEach((id) => {
		setUpGroupSnaphots(id);
	});

	// Set up mass listener
	// this listens for creation/ deletion events
	const unsubscribe = onSnapshot(myGroupsQuery, (querySnapshot) => {
		querySnapshot.docChanges().forEach((change) => {
			if (change.type == "added") {
				setUpGroupSnaphots(change.doc.id);
				const groupCurrentCount = store.getState().groups.count || 0;
				store.dispatch(setGroupCount(groupCurrentCount + 1));
			}

			if (change.type == "removed") {
				const hooks = groupHooks.filter((hook) => hook.id === change.doc.id);
			}

			if (change.type == "removed") {
				const hooks = groupHooks.filter((hook) => hook.id === change.doc.id);

				if (hooks.length > 0) {
					hooks[0].unsubscribers.details();
					hooks[0].unsubscribers.messages();
					hooks[0].unsubscribers.users();
					store.dispatch(deleteGroup);
					const groupCurrentCount = store.getState().groups.count || 1;
					store.dispatch(setGroupCount(groupCurrentCount - 1));
				} else {
					console.error("cannot find unsubscibe methods for " + change.doc.id);
				}
			}
		});
	});

	return unsubscribe;
};

const othersGroupSnapshotListener = async (
	db: Firestore,
	key: string,
	user: any
) => {
	let ids: Array<string> = [];

	// Get individual ids of groups
	// so we can add individual listeners
	// UPDATED: We need 2 queries, 1 for groups init by me, 1 for init by others
	// for init by me, disregard the isEmpty, for init by others, don't query the empty groups
	const othersGroupsQuery = query(
		collection(db, `events/${key}/groups`),
		where("members", "array-contains", parseInt(user.uid)),
		// where('initBy', '!=', parseInt(user.uid)),
		where("isEmpty", "!=", true),
		// orderBy('initBy'),
		orderBy("isEmpty"),
		orderBy("meta.lastSent", "desc")
	);

	const querySnapshot = await getDocs(othersGroupsQuery);

	querySnapshot.forEach((doc) => {
		if (doc.data().initBy === parseInt(user.uid)) {
			return;
		}

		ids.push(doc.id);
	});

	store.dispatch(setGroupCount(ids.length));

	ids.forEach((id) => {
		setUpGroupSnaphots(id);
	});

	// Set up mass listener
	// this listens for creation/ deletion events
	const unsubscribe = onSnapshot(othersGroupsQuery, (querySnapshot) => {
		// if (querySnapshot.size !== store.getState().groups.count) {
		querySnapshot.docChanges().forEach((change) => {
			const data = change.doc.data();
			if (data.initBy === parseInt(user.uid)) {
				return
			};

			if (change.type == "added") {
				setUpGroupSnaphots(change.doc.id);
				const groupCurrentCount = store.getState().groups.count || 0;
				store.dispatch(setGroupCount(groupCurrentCount + 1));
			}

			if (change.type == "added") {
				setUpGroupSnaphots(change.doc.id);
				const groupCurrentCount = store.getState().groups.count || 0;
				store.dispatch(setGroupCount(groupCurrentCount + 1));
			}

			if (change.type == "removed") {
				const hooks = groupHooks.filter((hook) => hook.id === change.doc.id);

				if (hooks.length > 0) {
					hooks[0].unsubscribers.details();
					hooks[0].unsubscribers.messages();
					hooks[0].unsubscribers.users();
					store.dispatch(deleteGroup);
					const groupCurrentCount = store.getState().groups.count || 1;
					store.dispatch(setGroupCount(groupCurrentCount - 1));
				} else {
					console.error("cannot find unsubscibe methods for " + change.doc.id);
				}
			}
		});
		// }
	});

	return unsubscribe;
};

export const getAllOnlineUsers = async () => {
	const db = getFirestore();
	const event_key: string = store.getState().auth.event_key;
	const q = query(
		collection(db, `events/${event_key}/users`),
		where('online', '==', true),
		limit(200)
	);
	const sss = await getDocs(q);
	const users: User[] = [];
	sss.forEach(ss => {
		const data = ss.data();
		users.push({ ...data } as User)
	});
	return users;
}

export const getAllUsersPaginated = async (cursorDoc: DocumentData | null = null) => {
	const db = getFirestore();
	const event_key: string = store.getState().auth.event_key;
	const queryConditions: QueryConstraint[] = [];
	const limitCount = 25;
	if (cursorDoc) {
		queryConditions.push(startAfter(cursorDoc))
	}

	const q = query(
		collection(db, `events/${event_key}/users`),
		...queryConditions,
		limit(limitCount)
	);
	const sss = await getDocs(q);
	const lastDoc = sss.size >= limitCount ? sss.docs[sss.docs.length - 1] : null;
	const users: User[] = [];
	sss.forEach(ss => {
		const data = ss.data();
		users.push({ ...data } as User)
	});

	return { users, lastDoc }
}