import { Fragment } from "react"
import { IonAvatar, IonList, IonItem, IonSkeletonText, IonLabel } from "@ionic/react";


interface Props{
    children?: any;
    count?: number;
}

const UserListSkeleton: React.FC<Props> = (props: Props) => {
    const skeletons: Array<number> = [];

    const count = props.count ? props.count : 10;
    for (var i = 0; i < count; i++){
        skeletons.push(i);
    }
    
    return (
        <Fragment>
            <IonList>
                {
                skeletons.map((item, index) => (
                    <IonItem key={`skeleton-${index}`} lines='none'>
                        <IonAvatar slot='start'>
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonLabel>
                            <h2><IonSkeletonText animated style={{ width: '80%' }} /></h2>
                            <p><IonSkeletonText animated style={{ width: '60%' }} /></p>
                            <br/>
                        </IonLabel>
                    </IonItem>
                ))
                }
            </IonList>
        </Fragment>
    )
}

export default UserListSkeleton;