import { IonAvatar, IonChip, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow, IonSkeletonText } from "@ionic/react";
import { Fragment } from "react";


interface Props{
    children?: any;
}

const ProfileSettingsSkeleton: React.FC<Props> = (props: Props) => {
    
    return (
        <Fragment>
            <IonGrid className='ion-margin-bottom'>
                <IonRow>
                    <IonCol size='12' className='ion-text-center ion-margin-top'>
                        <IonAvatar className='profile-avatar-lg'>
                            <IonSkeletonText animated />
                        </IonAvatar>
                        <IonChip>
                            <IonLabel><IonSkeletonText animated style={{width: '86px'}} /></IonLabel>
                        </IonChip>
                    </IonCol>
                </IonRow>
            </IonGrid>

            <IonList lines='full'>
                <IonItem>
                    <IonSkeletonText animated style={{width: '80%'}} />
                </IonItem>

                <IonItem>
                    <IonLabel position='stacked'><IonSkeletonText animated style={{width: '20%'}} /></IonLabel>
                    <IonSkeletonText animated style={{width: '50%'}} />
                </IonItem>

                <IonItem>
                    <IonLabel position='stacked'><IonSkeletonText animated style={{width: '30%'}} /></IonLabel>
                    <IonSkeletonText animated style={{width: '80%'}} />
                </IonItem>

                <IonItem>
                    <IonLabel position='stacked'><IonSkeletonText animated style={{width: '30%'}} /></IonLabel>
                    <IonSkeletonText animated style={{width: '80%'}} />
                </IonItem>

                <IonItem>
                    <IonLabel position='stacked'><IonSkeletonText animated style={{width: '30%'}} /></IonLabel>
                    <IonSkeletonText animated style={{width: '80%'}} />
                </IonItem>
            </IonList>
        </Fragment>
    )
}

export default ProfileSettingsSkeleton;