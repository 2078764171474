import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../pages/Auth/types";
import { RootState } from "../store";

interface SponsorsState{
    sponsor_title: string;
    sponsorUsers: User[];
}

const initialState: SponsorsState = {
    sponsor_title: 'Sponsors',
    sponsorUsers: []
}

export const sponsorSlice = createSlice({
    name: 'sponsors',
    initialState,
    reducers: {
        setAllSponsors: (state: SponsorsState, action: PayloadAction<User[]>) => {
            state.sponsorUsers = action.payload
        },
        setSponsorTitle: (state: SponsorsState, action: PayloadAction<string>) => {
            state.sponsor_title = action.payload
        }
    }
})

export const selectAllSponsors = (state: RootState) => state.sponsors.sponsorUsers;
export const selectSponsorTitle = (state: RootState) => state.sponsors.sponsor_title;

export const { setAllSponsors, setSponsorTitle } = sponsorSlice.actions;

export default sponsorSlice.reducer;