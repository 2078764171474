import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
	isAuthenticated,
	setCredentials,
	setEvent_key,
} from "../pages/Auth/authSlice";
import { IonLoading } from "@ionic/react";
import { AppType, CheckEnv } from "./Classes/CheckEnv";
import { setMagicEvents } from "../app/slices/magicEventsSlice";
import { userLogout, firebaseLogin } from "../app/firebase";
import { getBrandings } from "./Classes/Brandings";
import { translate } from "../app/translate";

const AppUrlListener: React.FC<any> = () => {
	const appType: AppType = CheckEnv();
	const history = useHistory();
	const dispatch = useAppDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const isAuthed = useAppSelector(isAuthenticated);

	const singleEventLogin = async (data: {
		event_key: string;
		access_token: string;
		firebase_token: string;
	}) => {
		dispatch(setEvent_key(data.event_key));
		await getBrandings(data.event_key);
		dispatch(
			setCredentials({
				access_token: data.access_token,
				firebase_token: data.firebase_token,
			})
		);
		await firebaseLogin();
		setIsLoading(false);
		history.push(`/home`);
	};

	useEffect(() => {
		// listen for deeplink
		App.addListener("appUrlOpen", (e: URLOpenListenerEvent) => {
		});
		return () => {
			App.removeAllListeners();
		};
	}, []);

	useEffect(() => {
		const checkMagicLink = async (MagicUrl: string | null = null) => {
			// else check window location for magic link
			const targetPath = MagicUrl || window.location.href;

			if (!targetPath.includes(`/magic-link/`)) {
				return;
			}

			const paths = targetPath.split("magic-link/")[1].split("/");
			const magicKeys = {
				organisation_key: paths[0],
				event_key: paths[1],
				magic_token: paths[2],
			};

			let body: { event_key?: string; organisation_key?: string } = {};

			setIsLoading(true);

			const url = `${process.env.REACT_APP_CHATGO_API}/chat-users/magic-link/${magicKeys.magic_token}`;
			const options = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
			};

			// check magic link keys with appType
			switch (appType) {
				case "event":
					if (isAuthed) {
						const loggedOut = await userLogout(true);
						if (!loggedOut) {
							setIsLoading(false);
							return;
						}
					}

					if (magicKeys.event_key !== process.env.REACT_APP_EVENT_TOKEN) {
						setIsLoading(false);
						history.push(`/invalidMagicLink`);
						return;
					}

					body.event_key = process.env.REACT_APP_EVENT_TOKEN;

					try {
						const res = await fetch(url, {
							...options,
							body: JSON.stringify(body),
						});
						const data = await res.json();
						if(res.status === 401){
							setIsLoading(false);
							history.push(`/invalidMagicLink`);
							return
						} else if (!data || !data.access_token || !data.firebase_token) {
							setIsLoading(false);
							history.push(`/invalidMagicLink`);
							return;
						}
						
						dispatch(
							setCredentials({
								access_token: data.access_token,
								firebase_token: data.firebase_token,
							})
						);
						await firebaseLogin();
						setIsLoading(false);
						history.push(`/home`);
					} catch (error) {
						console.log(error);
					}

					break;

				case "org":
					if (
						magicKeys.organisation_key !==
						process.env.REACT_APP_ORGANISATION_KEY
					) {
						setIsLoading(false);
						history.push(`/invalidMagicLink`);
						return;
					}
					body.organisation_key = process.env.REACT_APP_ORGANISATION_KEY;

					try {
						const res = await fetch(url, {
							...options,
							body: JSON.stringify(body),
						});
						const data = await res.json();
						if(res.status === 401){
							history.push(`/invalidMagicLink`);
						} else if (res.status === 300) {
							// has multiple events
							if (!data.events || data.events.length <= 0) {
								return;
							}
							dispatch(setMagicEvents(data.events));
							history.push(`/gateway`);
						} else if (res.status === 200) {
							singleEventLogin(data);
						}
						setIsLoading(false);
					} catch (error) {
						console.log(error);
					}

					break;

				default:
					try {
						const res = await fetch(url, {
							...options,
						});
						const data = await res.json();
						if(res.status === 401){
							history.push(`/invalidMagicLink`);
						} else if (res.status === 300) {
							// has multiple events
							if (!data.events || data.events.length <= 0) {
								return;
							}
							dispatch(setMagicEvents(data.events));
							history.push(`/gateway`);
						} else if (res.status === 200) {
							singleEventLogin(data);
						}
						setIsLoading(false);
					} catch (error) {
						console.log(error);
					}
					break;
			}
		};
		checkMagicLink();

		// listen for deeplink
		App.addListener("appUrlOpen", (e: URLOpenListenerEvent) => {
			checkMagicLink(e.url);
		});
		return () => {
			App.removeAllListeners();
		};
	}, []);

	return (
		<>
			<IonLoading
				isOpen={isLoading}
				message={translate(`Looking for your chats...`)}
				onDidDismiss={() => setIsLoading(false)}
			/>
		</>
	);
};

export default AppUrlListener;
