import { IonAlert, IonButton, IonHeader, IonIcon, IonLabel, IonToolbar } from "@ionic/react";
import { arrowDownCircleOutline, closeOutline, chevronBackOutline } from "ionicons/icons";
import React, { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shallowEqual } from "react-redux";
import { Redirect, useParams, useHistory } from "react-router";
import { getNextPageRoomMessages, sendMessage, updateUserLastRead } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { resetRoomMessages, selectRoomById, selectRoomMessagesById } from "../../app/slices/roomSlice";
import { addCurrentChannel, removeCurrentChannel, selectUserId, selectCurrentChannel } from "../../app/slices/userSlice";
import { store } from "../../app/store";
import { Room } from "../../app/types/rooms";
import MessageBubbleMemo from "../../components/Chat/MessageBubbleMemo";
import MessageInput from "../../components/Chat/MessageInput";
import NotificationBell from "../../components/NotificationBell";
import { Message, MessagePayload } from '../../models/message';
import { translate } from "../../app/translate";

interface Props{
    viewportInfo?: { width: number, height: number };
    roomId?: Number;
    isWindow?: boolean;
    onClose?: () => void;
}
const ChatRoomNonIonic: React.FC<Props> = (props: Props) => {
    const params: any = useParams<{ id: string }>();
    const history = useHistory();
    const id = props.roomId ? Number(props.roomId) : parseInt(params.id);
    const room: Room = useAppSelector(selectRoomById(id), shallowEqual);
    const rawMessages: Array<Message> = useAppSelector(selectRoomMessagesById(id));
    const [messages, setmessages] = useState<Array<Message>>(rawMessages);
    const userId = useAppSelector(selectUserId);
    const CurrentChannel = useAppSelector(selectCurrentChannel);
    const contentRef = useRef<HTMLDivElement>(null);
    const [showBackToBottom, setShowBackToBottom] = useState(false);
    const allowLoadMore = useRef(true);
    const [alertController, setAlertController] = useState({
        isOpen: false,
        header: '',
        message: '',
    });

    // update last read
    useEffect(() => {
        if (userId > 0 && id && CurrentChannel.includes(id)) {
            updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
        }
        return () => {
            if (userId > 0 && id && CurrentChannel.includes(id)) { 
                updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
            }
        }
    }, [userId, id, CurrentChannel]);

    // if not desktop, remove channel listener
    useEffect(() => {
        store.dispatch(addCurrentChannel(id))
        if (!props.isWindow) {
            return function cleanup() { store.dispatch(removeCurrentChannel(id)) }
        }
    }, [id, props.isWindow]);

    // send message hook
    const addNewMessage = async (newMessage: MessagePayload) => {
        const sendResult = await sendMessage(newMessage.message, id);
        if (sendResult === 'ILLEGAL') {
            setAlertController({
                isOpen: true,
                header: translate('Message Cannot be Sent'),
                message: translate('This message contains illegal characters / words and cannot be sent!'),
            });
        } else {
            const element = contentRef.current as HTMLDivElement;
            element.scrollTop = 0;
        }
    }

    // update messages state from rawMessages
    useEffect(() => {
        if (rawMessages && rawMessages.length > 0) {
            let temp: Array<Message> = [...rawMessages];
            setmessages(temp);
        }
    }, [rawMessages]);


    // load more messages
    const loadMore = useCallback(
        async () => {
            // allowLoadMore.current = false;
            if (messages && messages.length > 0) {
                const olderMessages: Array<Message> = await getNextPageRoomMessages(String(id), String(messages[0].id));
                let temp: Array<Message> = [...messages];
                temp.unshift(...olderMessages);
                setmessages(temp);
            }
            // allowLoadMore.current = true;
        },
        [messages, id],
    )

    // handle when chat window scrolls
    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const element = (e.target as HTMLDivElement), scrollTop = element.scrollTop, clientHeight = element.clientHeight;
        if (clientHeight + scrollTop < 0) {
            setShowBackToBottom(true)
        } else {
            setShowBackToBottom(false)
        }
    }

    // back to bottom and reset messages to 50
    const backToBottom = () => {
        allowLoadMore.current = false;
        //messages.length > 50 && store.dispatch(resetRoomMessages(id));
        if (messages.length > 50) {
            const temp = [...messages];
            const sliced = temp.slice(Math.max(temp.length - 50, 0));
            setmessages(sliced);
        }
        const element = contentRef.current as HTMLDivElement;
        element.scrollTop = 0;

        setTimeout(() => {
            allowLoadMore.current = true;
        }, 300);
    }

    return (
        <Fragment>
            {
                props.viewportInfo &&
                props.viewportInfo.width > 960 &&
                !props.isWindow &&
                <Redirect to={`${process.env.PUBLIC_URL}/home/chatrooms`} />
            }
            <div className='chat-window-wrapper'>
                {
                    props.isWindow ?
                        <div className='chat-window-header'>
                            <p>{String(room.name)}</p>
                            <IonButton
                                className='ion-no-padding'
                                fill='clear'
                                onClick={props.onClose}
                            >
                                <IonIcon color='white' icon={closeOutline} />
                            </IonButton>
                        </div>
                        :
                        // <TabHeader title={String(room.name)} back hideUser />
                        <IonHeader>
                            <IonToolbar color='primary'>
                                <IonButton slot='start' fill="clear" className='ion-no-padding'
                                    onClick={() => history.push('/home/chatrooms')}
                                >
                                    <IonIcon style={{color: `var(--ion-color-primary-contrast)`}} icon={chevronBackOutline} />
                                    <IonLabel style={{color: `var(--ion-color-primary-contrast)`}}>{String(room.name)}</IonLabel>
                                </IonButton>

                                <NotificationBell/>
                            </IonToolbar>
                        </IonHeader>
                }
                <div className={`message-back-to-bottom-btn${showBackToBottom ? ' show': ''}`}>
                    <IonButton
                        color='medium'
                        onClick={() => backToBottom()}
                    >
                        <IonIcon icon={arrowDownCircleOutline}/> {translate('Jump to Latest')}
                    </IonButton>
                </div>

                <div
                    className={`chat-window-content${props.isWindow ? ' isWindow': ''}`}
                    id={`chat-window-content_${room.id}`}
                    ref={contentRef}
                    onScroll={(e: React.UIEvent<HTMLDivElement>) => handleScroll(e)}
                >
                    <InfiniteScroll
                        dataLength={messages.length}
                        next={() => loadMore()}
                        inverse={true}
                        hasMore={allowLoadMore.current && messages.length > 49}
                        loader={''}
                        scrollableTarget={`chat-window-content_${room.id}`}
                        style={{paddingBottom: '54px'}}
                    >
                        {
                            messages.map((item, index) => (
                                    <MessageBubbleMemo
                                    key={`${id}_${item.id}`}
                                    messageData={item}
                                    lastMessage={messages[index - 1] || null}
                                    nextMessage={messages[index +1] || null}
                                    />
                            ))
                        }
                    </InfiniteScroll>
                </div>
                <div className='chat-window-footer'>
                    <MessageInput
                        recipient={props.roomId ? props.roomId : params.id}
                        addNewMessage={(newMessage: MessagePayload) => addNewMessage(newMessage)}
                        lastMessage={messages.length}
                        type="chatroom"
                    />
                </div>
            </div>
            <IonAlert
                isOpen={alertController.isOpen}
                onDidDismiss={() => setAlertController({
                    isOpen: false,
                    header: '',
                    message: '',
                })}
                header={alertController.header}
                message={alertController.message}
                buttons={['OK']}
            />
        </Fragment>
    )
}

export default ChatRoomNonIonic;