import { IonIcon, IonLoading } from '@ionic/react';
import { callOutline } from 'ionicons/icons';
import { useState } from 'react';
import { translate } from '../../app/translate';

interface DolbyIframeProps {
    videoCallUrl?: string;
    declineVideoCall: () => void;
}

export default function DolbyIframe(props: DolbyIframeProps) {
    const { videoCallUrl, declineVideoCall } = props;
    const [loading, setLoading] = useState(true)

    return (
        <>
            <IonLoading
                isOpen={loading}
                message={'Connecting Video Call...'}
            />
            {
                videoCallUrl && (
                    <>
                        <iframe
                            title="video call"
                            src={videoCallUrl}
                            allow="geolocation; microphone; camera"
                            className='videoCall__iframe'
                            onLoad={() => setLoading(false)}
                        />
                        <div className="videoCall__dolbyButtons">
                            <IonIcon
                                icon={callOutline}
                                color="black"
                                className="videoCall__button -callHangup"
                                onClick={declineVideoCall} />
                            <div className="videoCall__buttonLabel">{translate('Hangup')}</div>
                        </div>
                    </>
                )
            }
        </>
    )
}