import { createSlice, PayloadAction, current } from "@reduxjs/toolkit"
import { Message } from "../../models/message";
import { User } from "../../pages/Auth/types";
import { RootState } from "../store";
import { Room } from "../types/rooms";

interface RoomsState {
    data: {
        [key: number]: Room,
    },
    count: number | null,
    messages: {
        [key: number]: Array<Message>,
    },
    openChats: number[]
} 

const initialState: RoomsState = {
    data: {},
    count: null,
    messages: {},
    openChats: []
}

export const roomSlice = createSlice({
    name: 'rooms',
    initialState,
    reducers: {
        addMessageBulk: (state: RoomsState, action: PayloadAction<{ id: number, added: Array<Message> }>) => {
            if (!(action.payload.id in state.messages)) {
                state.messages[action.payload.id] = [];
            }
            state.messages[action.payload.id] = state.messages[action.payload.id].concat(action.payload.added);
        },
        addOlderMessageBulk: (state: RoomsState, action: PayloadAction<{ id: number, added: Array<Message> }>) => {
            if (!(action.payload.id in state.messages)) {
                state.messages[action.payload.id] = [];
            }
            state.messages[action.payload.id] = action.payload.added.concat(state.messages[action.payload.id]);
        },
        resetRoomMessages: (state: RoomsState, action: PayloadAction<number>) => {
            if (state.messages[action.payload].length > 50) {
                state.messages[action.payload] = state.messages[action.payload].filter((item, index) => index >= state.messages[action.payload].length - 50);
            }
        },
        addRoom: (state: RoomsState, action: PayloadAction<Room>) => {
            state.data[action.payload.id] = action.payload;
        },
        addRoomBulk: (state: RoomsState, action: PayloadAction<{ [key: string]: Room }>) => {
            state.data = action.payload;
        },
        setRoomCount: (state: RoomsState, action: PayloadAction<number>) => {
            state.count = action.payload;
        },
        incrementCount: (state: RoomsState) => {
            if (state.count === null) {
                return;
            }

            state.count++;
        },
        deleteRoom: (state: RoomsState, action: PayloadAction<number>) => {
            const clone = state.data;
            delete clone[action.payload];

            state.data = clone;
        },
        addMessage: (state: RoomsState, action: PayloadAction<{ id: number, message: Message }>) => {
            if (!(action.payload.id in state.messages)) {
                state.messages[action.payload.id] = [];
            }
            state.messages[action.payload.id].push(action.payload.message);
        },
        deleteMessage: (state: RoomsState, action: PayloadAction<{ id: number, message: string }>) => {
            state.messages[action.payload.id] = state.messages[action.payload.id].filter((message) => {
                return message.id !== action.payload.message;
            });
        },
        updateRoomMessage: (state: RoomsState, action: PayloadAction<{ id: number, message: Message }>) => {
            const temp = [...current(state).messages[action.payload.id]];            
            const targetIndex = temp.findIndex(element => element.id === action.payload.message.id);
            if (targetIndex !== -1) {
                state.messages[action.payload.id][targetIndex] = action.payload.message;
            }
        },
        resetRoomSlice: (state: RoomsState) => {
            state.data = {};
            state.count = null;
            state.messages = {};
        },
        updateOpenChats: (state: RoomsState, action: PayloadAction<number[]>) => {
            state.openChats = action.payload;
        }
    }
});

export const selectAllRooms = (state: RootState): Array<Room> => Object.values(state.rooms.data);
export const roomsIsInitialised = (state: RootState): boolean => state.rooms.count !== null && state.rooms.count === Object.keys(state.rooms.data).length;
export const selectRoomsCount = (state: RootState): number => Object.keys(state.rooms.data).length;
export const selectStateRoomsCount = (state: RootState): number | null => state.rooms.count;
export const selectOpenChats = (state: RootState) => state.rooms.openChats;

export const selectRoomById = (id: number) => (state: RootState): Room => {
    if (id in state.rooms.data) {
        return state.rooms.data[id];
    } else {
        return {
            id: 0,
            name: "",
            order: 0,
        };
    }
};

export const selectRoomMessagesById = (id: number) => (state: RootState): Array<Message> => {
    return id in state.rooms.messages && state.rooms.messages[id].length > 0
        ? state.rooms.messages[id]
        : [];
}

export const hasMessages = (id: number) => (state: RootState): boolean => {
    return id in state.rooms.messages && state.rooms.messages[id].length > 0;
}

export const selectRoomLastMessage = (id: number) => (state: RootState): string => {
    if (id in state.rooms.messages && state.rooms.messages[id].length > 0) {
        const last = state.rooms.messages[id][state.rooms.messages[id].length - 1];

        return last?.message || "";
    }

    return "";
}

export const selectLastMessages = (state: RootState): { [key: string]: { message: string; time: number; sender: User; } } => {
    const messages: { [key: string]: { message: string; time: number; sender: User; } } = {};
    Object.keys(state.rooms.messages).forEach((idx: string) => {
        const key = parseInt(idx);

        messages[key] = {
            message: state.rooms.messages[key][state.rooms.messages[key].length - 1].message,
            time: state.rooms.messages[key][state.rooms.messages[key].length - 1].timestamp,
            sender: state.rooms.messages[key][state.rooms.messages[key].length - 1].sender
        }
    });

    return messages;
}

// export const selectRoomById = (id: Number) => (state: RootState) => state.rooms.rooms.filter((room: Object) => room.id === id);

export const { addMessageBulk,
    addOlderMessageBulk,
    addRoom,
    addRoomBulk,
    setRoomCount,
    incrementCount,
    deleteRoom,
    addMessage,
    deleteMessage,
    resetRoomSlice,
    resetRoomMessages,
    updateOpenChats,
    updateRoomMessage
} = roomSlice.actions;

export default roomSlice.reducer;
