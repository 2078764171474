import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CheckLocal from "../../components/Classes/CheckLocal";
import { RootState } from "../store";
import { MagicEvent, MagicToken } from "../types/events";

const localAccess = CheckLocal();
interface MagicEventsState {
    magicEvents: MagicEvent[];
    magicTokens: MagicToken[];
}

const initialState: MagicEventsState = {
    magicEvents: !localAccess ? [] : JSON.parse(localStorage.getItem('magicEvents') || `[]`),
    magicTokens: !localAccess ? [] : JSON.parse(localStorage.getItem('magicTokens') || `[]`),
};



export const magicEventsSlice = createSlice({
    name: "magicEvents",
    initialState,
    reducers: {
        setMagicEvents: (state: MagicEventsState, action: PayloadAction<MagicEvent[]>) => {
            state.magicEvents = action.payload;
            localAccess && localStorage.setItem('magicEvents', JSON.stringify(state.magicEvents));
        },
        setMagicTokens: (state: MagicEventsState, action: PayloadAction<MagicToken[]>) => {
            state.magicTokens = action.payload;
            localAccess && localStorage.setItem('magicTokens', JSON.stringify(state.magicTokens));
        },
        addMagicTokens: (state: MagicEventsState, action: PayloadAction<MagicToken>) => {
            state.magicTokens = state.magicTokens.concat([action.payload]);
            localAccess && localStorage.setItem('magicTokens', JSON.stringify(state.magicTokens));
        },
        clearMagicEvents: (state: MagicEventsState) => {
            state.magicEvents = [];
            localAccess && localStorage.setItem('magicEvents', `[]`);
        },
        clearMagicTokens: (state: MagicEventsState) => {
            state.magicTokens = [];
            localAccess && localStorage.setItem('magicTokens', `[]`);
        }
    },
});

export const selectMagicEvents = (state: RootState) => state.magicEvents.magicEvents;
export const selectMagicTokens = (state: RootState) => state.magicEvents.magicTokens;
export const { setMagicEvents, setMagicTokens, addMagicTokens, clearMagicEvents, clearMagicTokens } = magicEventsSlice.actions;

export default magicEventsSlice.reducer;