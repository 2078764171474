import { IonLoading } from "@ionic/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getDMGroup2, getUserById } from "../app/firebase";
import { useAppSelector } from "../app/hooks";
import { selectDmId, selectRoomId } from "../app/slices/initDataSlice";
import { selectAllRooms } from "../app/slices/roomSlice";
import { userIdSelector } from "../app/slices/userSlice";
import { User } from "../pages/Auth/types";
import { translate } from "../app/translate";

interface Props {
	setDidDmRoute: (bool: boolean) => void;
}

const OverrideRouting: React.FC<Props> = (props: Props) => {
	const { setDidDmRoute } = props;
	const userId = useAppSelector(userIdSelector);
	const history = useHistory();
	const dmId = useAppSelector(selectDmId);
	const roomId = useAppSelector(selectRoomId);
	const rooms = useAppSelector(selectAllRooms);
	const [showAlert, setShowAlert] = useState(false);
	const [showCover, setShowCover] = useState(false);
	const [localDidDmRoute, setLocalDidDmRoute] = useState(false);

	const doPush = useCallback((url: string) => {
		history.push(url);
	}, [history]);

	// add a use effect to check if props changed
	useEffect(() => {
		const doDmRouting = async () => {
			if (dmId && userId !== 0 && rooms) {
				setShowCover(true);
				if (parseInt(dmId) === userId) {
					setShowCover(false);
					setDidDmRoute(true);
					setLocalDidDmRoute(true);
					doPush(`/home/inbox`);
				} else {
					setShowAlert(true);
					const targetUser = (await getUserById(Number(dmId))) as User;
					const targetGroupId = await getDMGroup2(targetUser);
					setShowAlert(false);
					setShowCover(false);
					setLocalDidDmRoute(true);
					doPush(`/group/${targetGroupId}`);
				}
			} else if (roomId && rooms.length > 0) {
				setDidDmRoute(true);
				setLocalDidDmRoute(true);
				doPush(`/chatroom/${roomId}`);
			}
		};
		!localDidDmRoute && doDmRouting();
	}, [localDidDmRoute, dmId, userId, roomId, rooms, setDidDmRoute]);

	return (
		<Fragment>
			{showCover && (
				<div
					style={{
						background: `#ddd`,
						width: `100vw`,
						height: `100vh`,
						position: `fixed`,
						zIndex: 999999,
						top: 0,
						left: 0,
					}}
				></div>
			)}
			<IonLoading isOpen={showAlert} message={translate("Loading Chat...")} />
		</Fragment>
	);
};

export default OverrideRouting;
