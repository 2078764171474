import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { userLogout } from '../firebase';

export const unauthenticatedMiddleware: Middleware = () => (next) => (action) => {

    if (isRejectedWithValue(action) && action.payload.status === 403) {
        userLogout();
    }
  
    return next(action);
}