import { IonAlert, IonButton, IonHeader, IonIcon, IonLabel, IonToolbar, IonFooter, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonFab, IonFabButton, IonPage, IonSkeletonText, IonLoading } from "@ionic/react";
import { ScrollDetail } from "@ionic/core";
import { chevronBackOutline, arrowDownOutline, videocamOutline } from "ionicons/icons";
import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import { shallowEqual } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getNextPageGroupMessages, sendMessage, updateUserLastRead, cleanupDMGroup, createNewDM } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { resetGroupMessages, selectGroupById, selectGroupMessagesById, selectGroupsIsInit, selectGroupCounts, selectNewGroupId, setNewGroupId, clearNewGroup } from "../../app/slices/groupSlice";
import { addCurrentChannel, removeCurrentChannel, selectUserId, selectCurrentChannel } from "../../app/slices/userSlice";
import { store } from "../../app/store";
import MessageBubbleMemo from "../../components/Chat/MessageBubbleMemo";
import MessageInput from "../../components/Chat/MessageInput";
import UserAvatar from "../../components/UserAvatar";
import { MessagePayload } from '../../models/message';
import NotificationBell from '../../components/NotificationBell';
import UserInfoChecked from "../../components/Classes/UserInfoChecked";
import { selectDmBack, selectDmId } from "../../app/slices/initDataSlice";
import { PostMessageToParent } from "../../components/Classes/PostMessage";
import { translate } from "../../app/translate";

interface Props {
    groupId?: string;
}

const DirectMessageIonic: React.FC<Props> = (props: Props) => {
    const parma = useParams<{ id: string }>();
    // const id = parma.id;
    const [id, setId] = useState(parma.id);
    const dmId = useAppSelector(selectDmId);
    const dmBack = useAppSelector(selectDmBack);
    const newGroupId: string | null = useAppSelector(selectNewGroupId);
    const initGroup = useAppSelector(selectGroupById(id), shallowEqual);
    const [group, setGroup] = useState(initGroup);
    const rawMessages = useAppSelector(selectGroupMessagesById(id));
    const [messages, setMessages] = useState(rawMessages);
    const userId = useAppSelector(selectUserId);
    const CurrentChannel = useAppSelector(selectCurrentChannel);
    const target = group.members.find((member: number) => member !== userId) || 0;
    const contentRef = useRef<HTMLIonContentElement>(null);
    const [showBackToBottom, setShowBackToBottom] = useState(false);
    const [alertController, setAlertController] = useState({
        isOpen: false,
        header: '',
        message: '',
    });
    const [disableLoadmore, setDisableLoadmore] = useState(true);
    const [showLoading, setShowLoading] = useState(false);

    var thumbnail = group.meta.thumbnail;
    var status = "disabled";
    var name = group.name;

    if (group.directMessage === true && target != undefined && target in group.users) {
        thumbnail = group.users[target].profilePicture;
        status = translate(group.users[target].online ? "online" : "offline");
        name = group.users[target].firstName + ` ` + group.users[target].lastName;
    }

    const updateGroup = (groupId: string) => {
        setId(groupId);
        store.dispatch(setNewGroupId(null));
        store.dispatch(clearNewGroup());
        history.push(`/group/${groupId}`);
    }

    //use effect to check if newGroup id has changed
    useEffect(() => {
        if (!newGroupId || id !== 'new') { return };
        updateGroup(newGroupId);
    }, [id, newGroupId])


    const addNewMessage = async (newMessage: MessagePayload) => {
        const recipient = group.users[target];
        if (!recipient) { return };
        let sendResult = null;

        if (id === 'new') {
            setShowLoading(true);
            const newDmId: string | null = await createNewDM(recipient.id);
            if (!newDmId) {
                setShowLoading(false);
                return
            };
            updateGroup(newDmId)
            setShowLoading(false);
            sendResult = await sendMessage(newMessage.message, newDmId);
            PostMessageToParent({
                type: 'chatStarted',
                message:{
                    email: recipient.email,
                    sponsor: recipient.is_sponsor_user
                }
            })
        } else {
            sendResult = await sendMessage(newMessage.message, id);
        }

        if (!sendResult) { return };

        if (sendResult === 'ILLEGAL') {
            setAlertController({
                isOpen: true,
                header: translate('Message Cannot be Sent'),
                message: translate('This message contains illegal characters / words and cannot be sent!'),
            });
        } else if (sendResult === 'EXCEEDED') {
            setAlertController({
                isOpen: true,
                header: translate('Message Cannot be Sent'),
                message: translate('This message exceeded the 500 characters limit. Consider sending it in few separate messages.'),
            });
        } else {
            contentRef.current?.scrollToBottom(500);
        }
    }

    const history = useHistory();

    useEffect(() => {
        setId(parma.id);
        setDisableLoadmore(true);
    }, [parma]);

    useEffect(() => {
        (rawMessages && rawMessages.length > 0) && setMessages(rawMessages);
    }, [rawMessages]);

    useEffect(() => {
        store.dispatch(addCurrentChannel(id));
        return function cleanup() {
            store.dispatch(removeCurrentChannel(id));
        }
    }, [id])

    useEffect(() => {
        if (userId > 0 && id && CurrentChannel.includes(id)) {
            updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
        }
        return () => {
            if (userId > 0 && id && CurrentChannel.includes(id)) {
                updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
            }
        }
    }, [userId, id, CurrentChannel]);

    // load more messages
    const loadMore = useCallback(
        async (e: CustomEvent<void>) => {
            if (messages && messages.length > 0) {
                const olderMessages = await getNextPageGroupMessages(String(id), String(messages[0].id));
                if (olderMessages.length <= 0) {
                    (e.target as HTMLIonInfiniteScrollElement).complete();
                    return
                };
                let temp = [...messages];
                temp.unshift(...olderMessages);
                setMessages(temp);
                (e.target as HTMLIonInfiniteScrollElement).complete();
            }
        },
        [messages, id],
    )

    // handle when chat window scrolls
    const handleScroll = useCallback(
        async (e: CustomEvent<ScrollDetail>) => {
            const element = await contentRef.current?.getScrollElement();
            const scrollTop: number = element?.scrollTop || 0, scrollHeight: number = element?.scrollHeight || 0, clientHeight: number = element?.clientHeight || 0;
            if (element && clientHeight !== 0) {
                if (scrollHeight - scrollTop <= clientHeight * 1.5) {
                    setDisableLoadmore(true)
                    setShowBackToBottom(false)
                } else {
                    setDisableLoadmore(false)
                    setShowBackToBottom(true)
                }
            };
        }
        , [])

    // back to bottom and reset messages to 50
    const backToBottom = useCallback(
        async () => {
            if (messages.length > 50) {
                const temp = [...messages];
                const sliced = temp.slice(Math.max(temp.length - 50, 0));
                setMessages(sliced);
            }
            setTimeout(() => {
                contentRef.current?.scrollToBottom(500);
            }, 300);
        },
        [messages],
    )

    // onLoad go to bottom
    useEffect(() => {
        backToBottom();
    }, []);

    useEffect(() => {
        const checkAndScroll = async () => {
            const element = await contentRef.current?.getScrollElement();
            const scrollTop: number = element?.scrollTop || 0, scrollHeight: number = element?.scrollHeight || 0, clientHeight: number = element?.clientHeight || 0;
            if (element && clientHeight !== 0) {
                if (scrollHeight - scrollTop <= clientHeight * 1.5) {
                    setDisableLoadmore(true)
                    setShowBackToBottom(false)
                    setTimeout(() => {
                        contentRef.current?.scrollToBottom(500);
                    }, 300);
                } else {
                    setDisableLoadmore(false)
                    setShowBackToBottom(true)
                }
            };
        }
        checkAndScroll();
        window.addEventListener('focus', () => checkAndScroll());
        return () => {
            window.removeEventListener('focus', () => checkAndScroll());
        }
    }, [messages, backToBottom]);

    // delete group when unmount if it is empty
    // useEffect(() => {
    //     return () => {
    //         cleanupDMGroup()
    //     }
    // },[])

    return (
        <IonPage>

            <IonHeader>
                <IonToolbar color='primary'>
                    {
                        (dmId && (dmId !== '') && (parseInt(dmId) !== userId) && !dmBack) ? null :
                            <IonButton slot='start' fill="clear" className='ion-no-margin ion-no-padding'
                                // onClick={() => history.push({
                                //     pathname: `/home/inbox`,
                                //     state: {direction: 'back'}
                                // })}
                                onClick={() => {
                                    history.length > 0 ?
                                        history.goBack()
                                        :
                                        history.push({
                                            pathname: `/home/inbox`,
                                            state: { direction: 'back' }
                                        })
                                }}
                            >
                                <IonIcon style={{ color: `var(--ion-color-primary-contrast)` }} icon={chevronBackOutline} />
                            </IonButton>
                    }
                    <IonButton slot='start' className='ion-no-margin ion-no-padding' fill='clear'
                        onClick={() => {
                            group.directMessage
                                ? history.push({
                                    pathname: `${process.env.PUBLIC_URL}/userprofile/${target}`,
                                    state: group.users[target]
                                })
                                : history.push(`${process.env.PUBLIC_URL}/groupinfo/${group.id}`)
                        }}
                    >
                        <UserAvatar
                            id={2}
                            firstName={name}
                            img={thumbnail}
                            status={status}
                            size={28}
                            className='ion-margin-end ion-margin-start'
                        />
                        <IonLabel style={{ color: `var(--ion-color-primary-contrast)` }} className='ion-text-start'>
                            <h2><b>{name}</b></h2>
                            <p style={{ fontSize: '12px' }}>
                                {group.users[target] &&
                                    <UserInfoChecked jobTitle={group.users[target].jobTitle} organisation={group.users[target].organisation} />
                                }
                            </p>
                        </IonLabel>
                    </IonButton>
                    {/* <IonButton slot='end' fill="clear">
                        <IonIcon color='white' icon={videocamOutline}/>
                    </IonButton> */}
                    <NotificationBell />
                </IonToolbar>
            </IonHeader>

            <IonContent
                // fullscreen
                scrollEvents={true}
                ref={contentRef}
                onIonScroll={(e: CustomEvent<ScrollDetail>) => handleScroll(e)}
            >
                {(messages && messages.length > 0) &&
                    <Fragment>
                        <IonInfiniteScroll
                            onIonInfinite={(e: CustomEvent<void>) => loadMore(e)}
                            position='top'
                            disabled={disableLoadmore}
                        >
                            <IonInfiniteScrollContent loadingText={'Loading'}>

                            </IonInfiniteScrollContent>
                        </IonInfiniteScroll>

                        {
                            messages.map((item, index) => (
                                <MessageBubbleMemo
                                    key={`${id}_${item.id}`}
                                    messageData={item}
                                    lastMessage={messages[index - 1] || null}
                                    nextMessage={messages[index + 1] || null}
                                />
                            ))
                        }


                        <IonFab vertical="bottom" horizontal="center" slot="fixed"
                            style={{ opacity: showBackToBottom ? '1' : '0' }}
                        >
                            <IonFabButton
                                onClick={() => backToBottom()}
                                disabled={showBackToBottom ? false : true}
                            >
                                <IonIcon icon={arrowDownOutline} />
                            </IonFabButton>
                        </IonFab>
                    </Fragment>
                }
            </IonContent>

            <IonFooter>
                {
                    Object.values(group.users).length > 0 ?
                        <MessageInput
                            recipient={parma.id}
                            addNewMessage={(newMessage: MessagePayload) => addNewMessage(newMessage)}
                            lastMessage={messages.length}
                            type="dm"
                            dmId={group.users[target].id || group.users[1].id}
                        />
                        :
                        <div style={{
                            padding: '12px'
                        }}>
                            <IonSkeletonText animated style={{ height: '24px' }}></IonSkeletonText>
                        </div>
                }
            </IonFooter>

            <IonAlert
                isOpen={alertController.isOpen}
                onDidDismiss={() => setAlertController({
                    isOpen: false,
                    header: '',
                    message: '',
                })}
                header={alertController.header}
                message={alertController.message}
                buttons={['OK']}
            />

            <IonLoading
                isOpen={showLoading}
                message={translate(`Updating Chat...`)}
            />
        </IonPage>
    )
}

export default DirectMessageIonic;