import { IonContent, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import { useAppSelector } from '../../app/hooks';
import { selectAllSponsors, selectSponsorTitle } from "../../app/slices/sponsorSlice";
import { selectUser, selectUserId } from '../../app/slices/userSlice';
import TabHeader from "../../components/TabHeader";
import UserItem from "../../components/UserItem";
import { User } from '../Auth/types';

const SponsorsList: React.FC = props => {
    const user: User = useAppSelector(selectUser);
    const authUserId = useAppSelector(selectUserId);
    const sponsorTitle = useAppSelector(selectSponsorTitle);
    const sponsors = useAppSelector(selectAllSponsors);

    return (
        <IonPage>
            <TabHeader title={sponsorTitle}>
            </TabHeader>
            <IonContent>
                <IonList lines='none'>
                    {
                        sponsors.filter(x => x.id !== authUserId).map(item => (
                            <UserItem 
                                id={item.id}
                                key={`sponsors-item-${item.id}`}
                                firstName={item.firstName}
                                lastName={item.lastName}
                                organisation={item.organisation}
                                profileImage={item.profilePicture ? item.profilePicture : null}
                                is_sponsor_user={true}
                                user={item}
                            />
                        ))
                    }
                </IonList>
            </IonContent>
        </IonPage>
    )
}

export default SponsorsList;