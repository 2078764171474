import { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import { useAppSelector, useVideoCallWatcher } from "../app/hooks";
import { selectEventToggles } from "../app/slices/eventSlice";
import { selectDmId } from "../app/slices/initDataSlice";
import { selectUser, selectUserId, selectUserMatchmaking } from "../app/slices/userSlice";
import { EventToggles } from "../app/types/eventToggles";
import { isAuthenticated } from "../pages/Auth/authSlice";
import { selectQuestions } from "../app/slices/questionSlice";
import { Question } from "../app/types/questions";
import { updateLastLocation } from "../app/firebase";
import MatchmakingReminder from '../pages/Home/MatchmakingReminder';

interface Props {
	children: any;
	checkedMatchmaking?: boolean;
}

const AuthGuard: React.FC<Props> = (props: Props) => {
	const isAuth: boolean = useAppSelector(isAuthenticated);
	const location = useLocation();
	const history = useHistory();
	useVideoCallWatcher();
	const dmId = useAppSelector(selectDmId);
	const userId = useAppSelector(selectUserId);
	const authUser = useAppSelector(selectUser);
	const matchmaking = useAppSelector(selectUserMatchmaking);
	const [checkedMatchmaking, setCheckedMatchmaking] = useState(false);
  	const [showMatchMakingReminder, setShowMatchMakingReminder] = useState(false);
	const eventToggles: EventToggles | never[] =
		useAppSelector(selectEventToggles);
	const questions: Array<Question> = useAppSelector(selectQuestions);
  
	// to track user history / location as soon as he/she navigated
	useEffect(() => {
		if (!isAuth || !userId) {
			return;
		}
		let currentLocation = {
			location: "others",
			id: "",
			timestamp: Math.floor(Date.now() / 1000),
		};
		const getId = (pathname: string) => {
			const splited: Array<string> = pathname.split("/");
			const id = splited[splited.length - 1];
			return id;
		};
		if (location.pathname.includes("chatroom/")) {
			currentLocation.location = "chatroom";
			currentLocation.id = getId(location.pathname);
		}
		if (location.pathname.includes("group/")) {
			currentLocation.location = "group";
			currentLocation.id = getId(location.pathname);
		}
		updateLastLocation(currentLocation);
		return () => {};
	}, [location, isAuth, userId]);

  useEffect(() => {
		if (
			isAuth === true &&
			!dmId &&
			!matchmaking &&
			userId !== 0 &&
			// !authUser.is_sponsor_user &&
			!checkedMatchmaking &&
			eventToggles.matchmaking &&
			questions &&
			questions.length > 0
		) {
      setShowMatchMakingReminder(true);
		}
	}, [
		isAuth,
		dmId,
		matchmaking,
		userId,
		authUser,
		checkedMatchmaking,
		history,
		eventToggles,
		questions,
	]);

	if (isAuth === true) {
		return location.pathname === `${process.env.PUBLIC_URL}/login` ||
      location.pathname === `${process.env.PUBLIC_URL}/register`
    //   || location.pathname === `/gateway`
      ? (
			<Redirect
				to={{
					pathname: `/home`,
					state: { from: location.pathname },
				}}
			/>
		) : (
        <>
          {props.children}
          <MatchmakingReminder
            setCheckedMatchmaking={(bool: boolean) => setCheckedMatchmaking(bool)}
            isOpen={showMatchMakingReminder}
            onClose={() => setShowMatchMakingReminder(false)}
          />
        </>
		);
	} else {
		return location.pathname === `${process.env.PUBLIC_URL}/login` ||
			location.pathname === `${process.env.PUBLIC_URL}/register`
			|| location.pathname === `/gateway` || location.pathname === `/invalidMagicLink`
			? (
			<>{props.children}</>
		) : (
			<Redirect
				to={{
					pathname: `${process.env.PUBLIC_URL}/login`,
					state: { from: location.pathname },
				}}
			/>
		);
	}
};

export default AuthGuard;
