import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonRow, IonSpinner, IonText, IonTitle, IonToolbar, IonLoading, useIonAlert, IonPage, IonChip } from "@ionic/react";
import { briefcaseOutline, businessOutline, chevronBackOutline, videocamOutline, chatbubblesOutline, banOutline } from "ionicons/icons";
import { Fragment, useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useUserCompareQuery } from "../../app/services/userApi";
import { selectEventToggles } from '../../app/slices/eventSlice';
import { EventToggles } from '../../app/types/eventToggles';
import UserAvatar from "../../components/UserAvatar";
import { getDMGroup, getDMGroup2, userNotBlocked, videoCallGroup, videoCallRecipient } from '../../app/firebase';
import { selectUserId } from "../../app/slices/userSlice";
import { useUserBlockMutation, useUserUnBlockMutation } from "../../app/services/userApi";
import { User } from "../Auth/types";
import { selectSponsorTitle } from "../../app/slices/sponsorSlice";
import { translate } from "../../app/translate";

const UserProfile: React.FC = props => {
    const history = useHistory();
    const sponsorTitle = useAppSelector(selectSponsorTitle);
    const [user] = useState<User>(history.location.state as User);
    const { data = [], isFetching } = useUserCompareQuery({ target: user.id });
    const eventToggles: EventToggles | never[] = useAppSelector(selectEventToggles);
    const [showDMLoading, setShowDMLoading] = useState(false);
    const [loadingVideoCall, setLoadingVideoCall] = useState(false);
    const [block] = useUserBlockMutation();
    const [unblock] = useUserUnBlockMutation();
    const [alert] = useIonAlert();

    const startDM = async () => {
        setShowDMLoading(true);
        const targetGroupId = await getDMGroup2(user);
        setShowDMLoading(false);
        history.push(`/group/${targetGroupId}`);
    }

    const blockUser = async () => {
        const response: { data: { success: boolean } } = await block({
            target: user.id,
        })

        var message = translate(`Failed to block the :firstName`, { firstName: user.firstName });
        var header = translate("Error");
        if (response.data.success) {
            message = translate(`Successfully blocked :firstName`, { firstName: user.firstName });
            header = translate("Success");
        }

        alert({
            header,
            message,
            buttons: [
                'OK',
            ],
        });
    }
    const unblockUser = async () => {
        const response: { data: { success: boolean } } = await unblock({
            target: user.id,
        })

        var message = translate(`Failed to unblock :firstName`, { firstName: user.firstName });
        var header = translate("Error");
        if (response.data.success) {
            message = translate(`Successfully unblocked :firstName`, { firstName: user.firstName });
            header = translate("Success");
        }

        alert({
            header,
            message,
            buttons: [
                'OK',
            ],
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color='primary'>
                    <IonButton slot="start" fill="clear" color='white' className='ion-no-padding'
                        onClick={() => history.goBack()}
                    >
                        <IonIcon icon={chevronBackOutline} />
                        <IonLabel>{user.firstName}</IonLabel>
                    </IonButton>

                    <IonTitle>{user.is_sponsor_user ? `${user.organisation}` : translate(`Attendee Profile`)}</IonTitle>
                    {/* <IonButton slot='end' fill="clear" className='ion-no-padding'>
                        <IonIcon color='white' icon={videocamOutline}/>
                    </IonButton> */}
                    {eventToggles.direct_messaging &&
                        <IonButton slot='end' fill="clear"
                            onClick={() => startDM()}
                        >
                            <IonIcon color='white' icon={chatbubblesOutline} />
                        </IonButton>
                    }
                </IonToolbar>
            </IonHeader>

            <IonContent color='light'>
                <IonLoading
                    isOpen={showDMLoading}
                    message={translate('Loading Chat...')}
                />
                <IonLoading
                    isOpen={loadingVideoCall}
                    message={translate('Preparing Video Call...')}
                />

                <div
                    className='ion-padding-top ion-margin-top'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        flexDirection: 'column',
                        boxSizing: 'border-box'
                    }}
                >
                    <UserAvatar id={user.id} firstName={user.firstName} size={96} status={`disabled`} img={user.profilePicture} />
                </div>
                <IonCard>
                    <IonCardHeader>
                        <IonCardSubtitle>
                            {
                                user.is_sponsor_user ?
                                    <b color="secondary" >{sponsorTitle}</b>
                                    :
                                    <b>{translate('Profile')}</b>
                            }
                        </IonCardSubtitle>
                        <IonCardTitle>
                            <b>{`${user.firstName} ${user.lastName}`}</b>

                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonGrid>
                            {
                                user.jobTitle &&
                                <IonRow>
                                    <IonCol size='2'>
                                        <IonIcon size="small" icon={briefcaseOutline} color='dark' />
                                    </IonCol>
                                    <IonCol size='10'>
                                        <IonLabel>{user.jobTitle}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            }

                            {
                                user.organisation &&
                                <IonRow>
                                    <IonCol size='2'>
                                        <IonIcon size="small" icon={businessOutline} color='dark' />
                                    </IonCol>
                                    <IonCol size='10'>
                                        <IonLabel>{user.organisation}</IonLabel>
                                    </IonCol>
                                </IonRow>
                            }
                        </IonGrid>
                    </IonCardContent>
                </IonCard>
                <Fragment>
                    {
                        eventToggles.matchmaking && !user.is_sponsor_user &&
                        <IonCard>
                            {isFetching ? <IonSpinner /> :
                                <div>
                                    <IonCardHeader>
                                        {
                                            data.questions && (Object.keys(data.questions).length > 1) &&
                                            <IonCardTitle>{translate(`You're a`) + ' '}
                                                <IonText
                                                    color={
                                                        data.score < 50 ? 'danger' :
                                                            data.score > 90 ? 'success' :
                                                                'primary'
                                                    }
                                                >
                                                    {Math.ceil(data.score)}%
                                                </IonText>{' ' + translate('match with') + ' '}<IonText color='primary'>{user.firstName}</IonText>.
                                            </IonCardTitle>
                                        }
                                    </IonCardHeader>
                                    <Fragment>
                                        {(data.questions && (Object.keys(data.questions).length > 0)) &&
                                            <IonCardHeader>
                                                <IonCardSubtitle>{translate(`Here's what you have in common:`)}</IonCardSubtitle>
                                            </IonCardHeader>
                                        }
                                    </Fragment>
                                    {
                                        !user.is_sponsor_user &&
                                        <IonCardContent>
                                            {
                                                data.questions && (Object.keys(data.questions).length < 1 ?
                                                    <IonText>{translate(`You and :firstName aren't a match this time.`, { firstName: user.firstName })}</IonText>
                                                    :
                                                    Object.values(data.questions).map((question: any, idx: number) => (
                                                        <Fragment key={idx}>
                                                            <h2>{question.question}</h2>
                                                            <div className='ion-margin-bottom ion-padding-bottom'>
                                                                {question.matches.map((answer: string) => (
                                                                    // <IonChip color='primary' key={answer}>{answer}</IonChip>
                                                                    <span
                                                                        key={answer}
                                                                        style={{
                                                                            // backgroundColor: 'var(--ion-color-primary-tint)',
                                                                            color: 'var(--ion-color-primary)',
                                                                            paddingRight: '16px',
                                                                            marginTop: '6px',
                                                                            display: 'inline-block',
                                                                            // borderRadius: '99px',
                                                                            fontWeight: 'bold',
                                                                            fontSize: '16px'
                                                                        }}
                                                                    >{answer}</span>
                                                                ))}
                                                            </div>
                                                        </Fragment>
                                                    )))
                                            }
                                        </IonCardContent>

                                    }
                                </div>
                            }
                        </IonCard>
                    }
                    <br />
                    {eventToggles.direct_messaging &&
                        <IonButton
                            expand='block'
                            className='ion-margin'
                            onClick={() => startDM()}
                        ><IonIcon icon={chatbubblesOutline} className='ion-margin-end' /> {translate('Message :firstName', { firstName: user.firstName })}</IonButton>
                    }


                    <IonButton
                        expand='block'
                        className='ion-margin'
                        color={userNotBlocked(String(user.id)) ? `danger` : `success`}
                        onClick={
                            userNotBlocked(String(user.id)) ?
                                () => blockUser() : () => unblockUser()
                        }
                    >
                        <IonIcon icon={banOutline} className='ion-margin-end' />
                        {
                            userNotBlocked(String(user.id)) ?
                                translate('Block :firstName', {firstName: user.firstName}) : translate('Unblock :firstName', {firstName: user.firstName})
                        }
                    </IonButton>
                </Fragment>
            </IonContent>
        </IonPage>
    )
}

export default UserProfile;