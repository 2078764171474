import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { UserSettingsPayload, UserUpdatePayload, UserPasswordPayload } from '../../pages/Auth/types'
import { RootState } from "../store";
import { UserQuestionUpdatePayload } from "../types/questions";

export const userApi: any = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: String(process.env.REACT_APP_CHATGO_API),
        prepareHeaders: (headers, { getState }) => {
            let token: string = (getState() as RootState).auth.token;
            headers.set('authorization', `Bearer ${token}`);
            headers.set('accept', 'application/json')

            return headers;
        }
    }),
    endpoints: (builder) => ({
        updateUser: builder.mutation<any, UserUpdatePayload>({
            query: ({ ...patch }) => ({
                url: "chat/user",
                method: "PATCH",
                body: patch,
            })
        }),
        updateUserSettings: builder.mutation<any, UserSettingsPayload>({
            query: ({ ...patch }) => ({
                url: "chat/user/settings",
                method: "PATCH",
                body: patch,
            })
        }),
        updatePassword: builder.mutation<any, UserPasswordPayload>({
            query: ({ ...patch }) => ({
                url: "chat/user/password",
                method: "PATCH",
                body: patch,
            })
        }),
        updateMatchMaking: builder.mutation<any, Array<UserQuestionUpdatePayload>>({
            query: ({ ...post }) => ({
                url: "chat/user/answers",
                method: "POST",
                body: post,
            })
        }),
        updateProfilePicture: builder.mutation<any, any>({
            query: (body) => ({
              url: "chat/user/profile",
              method: 'POST',
              body,
            })
        }),
        userSearch: builder.query<any, string>({
            query: (query) => `chat/user/search?query=${query}`
        }),
        userBlock: builder.mutation<any, {target: number}>({
            query: ({ ...post }) => ({
                url: "chat/user/block",
                method: "POST",
                body: post,
            })
        }),
        userUnBlock: builder.mutation<any, {target: number}>({
            query: ({ ...post }) => ({
                url: "chat/user/unblock",
                method: "POST",
                body: post,
            })
        }),
        userCompare: builder.query<any, {target: number}>({
            query: (query) => `chat/user/compare?target=${query.target}`
        }),
    }),
});

export const { 
    useUpdateUserMutation, 
    useUpdateUserSettingsMutation, 
    useUpdatePasswordMutation,
    useUpdateMatchMakingMutation,
    useUpdateProfilePictureMutation,
    useUserSearchQuery,
    useUserBlockMutation,
    useUserUnBlockMutation,
    useUserCompareQuery,
} = userApi;
