import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { EventToggles } from '../types/eventToggles';

interface EventInterface {
    toggles: Array<any>;
    filters: {
        chat: string;
        globalChat: string;
    };
}

const initialState: EventInterface = {
    toggles: [],
    filters: {
        chat: '',
        globalChat: ''
    },
}

export const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        updateEventToggles: (state: EventInterface, action: PayloadAction<{ [key: string]: number }>) => {
            state.toggles = { ...state.toggles, ...action.payload };
        },
        updateFilters: (state: EventInterface, action: PayloadAction<string>) => {
            state.filters.chat = action.payload;
        },
        updateGlobalFilters: (state: EventInterface, action: PayloadAction<string>) => {
            state.filters.globalChat = action.payload;
        },
    },
});

export const selectEventToggles = (state: RootState) => (state.events.toggles as EventToggles);
export const selectChatFilters = (state: RootState) => state.events.filters.chat
export const selectGlobalChatFilters = (state: RootState) => state.events.filters.globalChat;

export const { updateEventToggles, updateFilters, updateGlobalFilters } = eventSlice.actions;

export default eventSlice.reducer;