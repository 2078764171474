import {
    IonItem,
    IonLoading,
    IonText,
    useIonActionSheet,
    useIonAlert,
    IonChip,
    IonModal,
    isPlatform
} from "@ionic/react";
import React, { Fragment, useCallback, useState, useEffect } from "react";
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import { getDMGroup, userNotBlocked, userNotBlockedBy, videoCallRecipient, updateMessage, getDMGroup2 } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { useUserBlockMutation, useUserUnBlockMutation } from "../../app/services/userApi";
import { ActionSheetButton } from '@ionic/core';
import { selectUserId, selectUserModerator } from "../../app/slices/userSlice";
import { selectEventToggles } from "../../app/slices/eventSlice";
import { selectLang } from '../../app/slices/initDataSlice';
import { EventToggles } from "../../app/types/eventToggles";
import { Message, MessagePayload } from '../../models/message';
import UserInfoChecked from "../Classes/UserInfoChecked";
import UserAvatar from "../UserAvatar";
import './MessageBubble.css';
import UrlConverter from "./UrlConverter";
import { Timestamp } from 'firebase/firestore';
import AndroidVideoCallReminder from '../AndroidVideoCallReminder';
import { selectSponsorTitle } from "../../app/slices/sponsorSlice";
import { translate } from "../../app/translate";

interface Props {
    key: String;
    messageData: Message;
    lastMessage?: Message | null;
    nextMessage?: Message | null;
    timeDivider?: boolean | null;
    top?: boolean | null;
    bottom?: boolean | null;
    showSender?: boolean;
    showTime?: boolean;
}

const arrowLeft = <svg width="210px" height="171px" viewBox="0 0 210 171" className='arrow'>
    <path d="M105.791827,17.2938505 C103.062539,63.9800046 109.626062,103.157205 125.462593,134.830267 C141.105019,166.115118 153.288324,187.671905 162.007147,199.504232 L162.007147,199.504232 L7.84121644,213.518407 C6.43413805,182.972239 14.6460543,149.88802 32.4587727,114.262583 C50.218474,78.7431807 74.6648241,46.4161016 105.791827,17.2938505 Z" id="Triangle" stroke="#979797" fill="#D8D8D8" transform="translate(85.063599, 115.063599) rotate(-135.000000) translate(-85.063599, -115.063599) "></path>
</svg>


const MessageBubbleMomo: React.FC<Props> = ({ messageData, lastMessage, nextMessage }) => {
    const lang: string | undefined = useAppSelector(selectLang);
    const myId: number = useAppSelector(selectUserId);
    const isModerator = useAppSelector(selectUserModerator);
    const eventToggles: EventToggles | never[] = useAppSelector(selectEventToggles);
    const [present] = useIonActionSheet();
    const [presentModeration] = useIonActionSheet();
    const [loadingVideoCall, setLoadingVideoCall] = useState(false);
    const history = useHistory();
    const [block] = useUserBlockMutation();
    const [unblock] = useUserUnBlockMutation();
    const [alert] = useIonAlert();
    const [androidVideoCallBrowserReminderOpen, setAndroidVideoCallBrowserReminderOpen] = useState(false);
    const sponsorTitle: string = useAppSelector<string>(selectSponsorTitle);

    const calendarStrings = {
        lastDay: `[${translate('Yesterday')}]`,
        sameDay: `[${translate('Today')}]`,
        nextDay: `[${translate('Tomorrow')}]`,
        lastWeek: 'DD MMM YYYY',
        nextWeek: 'DD MMM YYYY',
        sameElse: 'DD MMM YYYY'
    };

    const timeDivider = !lastMessage ? true :
        new Date(messageData.timestamp * 1000).getFullYear() !== new Date(lastMessage.timestamp * 1000).getFullYear() ? true :
            new Date(messageData.timestamp * 1000).getMonth() !== new Date(lastMessage.timestamp * 1000).getMonth() ? true :
                new Date(messageData.timestamp * 1000).getDate() !== new Date(lastMessage.timestamp * 1000).getDate() ? true : false;

    const date = new Date(messageData.timestamp * 1000);

    const isTop = (
        (!lastMessage) ||
        (lastMessage.sender.id !== messageData.sender.id) ||
        (messageData.timestamp - lastMessage.timestamp >= 60)
    );
    const isBottom = (
        (!nextMessage) ||
        (nextMessage.sender.id !== messageData.sender.id) ||
        (nextMessage.timestamp - messageData.timestamp >= 60)
    );

    const createAlertMessageBtn = useCallback(
        () => {
            let buttons: Array<ActionSheetButton> = [];
            if (userNotBlocked(String(messageData.sender.id))) {
                buttons = [
                    {
                        text: translate('View Profile'),
                        handler: () => history.push({
                            pathname: `/userprofile/${messageData.sender.id}`,
                            state: messageData.sender,
                        })
                    },
                ];

                eventToggles.direct_messaging && buttons.unshift(
                    {
                        text: translate('Message :firstName', {firstName: messageData.sender.firstName}),
                        handler: async () => {
                            // getDMGroup(messageData.sender.id)
                            //     .then((id: number) => {
                            //         history.push(`/group/${id}`)
                            //     })
                            const targetGroupId = await getDMGroup2(messageData.sender);
                            history.push(`/group/${targetGroupId}`);
                        }
                    }
                );

                buttons.push({
                    text: translate('Block :firstName', {firstName: messageData.sender.firstName}),
                    handler: async () => {
                        const response: { data: { success: boolean } } = await block({
                            target: messageData.sender.id,
                        })

                        var message = translate(`Failed to block :firstName`, {firstName: messageData.sender.firstName});
                        var header = translate("Error");
                        if (response.data.success) {
                            message = translate(`Successfully blocked :firstName`, {firstName: messageData.sender.firstName});
                            header = translate("Success");
                        }

                        alert({
                            header,
                            message,
                            buttons: [
                                translate('OK'),
                            ],
                        });
                    },
                })
            } else {
                buttons.push(
                    {
                        text: translate(`Unblock :firstName`, {firstName: messageData.sender.firstName}),
                        handler: async () => {
                            const response: { data: { success: boolean } } = await unblock({
                                target: messageData.sender.id,
                            })

                            var message = translate(`Failed to unblock :firstName`, {firstName: messageData.sender.firstName});
                            var header = translate("Error");
                            if (response.data.success) {
                                message = translate(`Successfully unblocked :firstName`, {firstName: messageData.sender.firstName});
                                header = translate("Success");
                            }

                            alert({
                                header,
                                message,
                                buttons: [
                                    translate('OK'),
                                ],
                            });
                        },
                    }
                )
            }

            buttons.push({
                text: translate('Cancel'),
                role: 'cancel'
            })
            return buttons;
        },
        [alert, block, eventToggles.direct_messaging, history, messageData.sender, unblock],
    )

    const createModerationBtn = useCallback(
        () => {
            let buttons: Array<ActionSheetButton> = [];
            messageData.status === 'hidden' ?
                buttons.push({
                    text: translate('Unhide Message'),
                    handler: () => {
                        const temp = { ...messageData };
                        temp.status = 'sent';

                        const tempPayload: MessagePayload = {
                            ...temp,
                            timestamp: new Timestamp(temp.timestamp, 0)
                        }
                        updateMessage(tempPayload);
                    }
                })
                :
                buttons.push({
                    text: translate('Hide Message'),
                    handler: () => {
                        const temp = { ...messageData };
                        temp.status = 'hidden';

                        const tempPayload: MessagePayload = {
                            ...temp,
                            timestamp: new Timestamp(temp.timestamp, 0)
                        }
                        updateMessage(tempPayload);
                    }
                })
            buttons.push({
                text: translate('Cancel'),
                role: 'cancel'
            })
            return buttons
        }, [messageData.status]
    )

    if (messageData.systemMessage) {
        return (
            <Fragment>
                <div className="message-divider ion-margin">
                    <div className='message-divider-content'>
                        {messageData.message}
                        <br />
                        <Moment
                            locale={lang}
                            calendar={calendarStrings}
                            style={{
                                textAlign: 'center',
                                width: '100%'
                            }}
                        >
                            {date}
                        </Moment>
                    </div>
                </div>
            </Fragment>
        )
    }
    return (
        <Fragment>
            <IonLoading
                isOpen={loadingVideoCall}
                message={translate('Preparing Video Call...')}
            />

            <IonModal
                isOpen={androidVideoCallBrowserReminderOpen}
                swipeToClose={true}
                onDidDismiss={() => setAndroidVideoCallBrowserReminderOpen(false)}
            >
                <AndroidVideoCallReminder
                    startCallCallback={() => {
                        setAndroidVideoCallBrowserReminderOpen(false)
                        videoCallRecipient(messageData.sender.id, setLoadingVideoCall, (str: string) => alert(str))
                    }}
                    cancelCallback={() => setAndroidVideoCallBrowserReminderOpen(false)}
                />
            </IonModal>

            {timeDivider &&
                <div className="message-divider ion-margin">
                    <div className='message-divider-content'>
                        <Moment
                            locale={lang}
                            calendar={calendarStrings}
                            style={{
                                textAlign: 'center',
                                width: '100%'
                            }}
                        >
                            {date}
                        </Moment>
                    </div>
                </div>
            }

            <IonItem lines='none' className={`memo-bubble${isModerator ? ' isModerator' : ''}${messageData.status === "hidden" ? ' isHidden' : ''}`} >
                <div className={`message-bubble-container${myId === messageData.sender.id ? ' self' : ''}`}>
                    {
                        (!lastMessage || (lastMessage.sender.id !== messageData.sender.id)) &&

                        <div className={`message-top-layer`}>
                            <div
                                className={`message-sender-wrapper`}
                                style={{
                                    cursor: messageData.sender.id !== myId && userNotBlockedBy(String(messageData.sender.id)) ? 'pointer' : 'default',
                                }}
                                onClick={
                                    messageData.sender.id !== myId && userNotBlockedBy(String(messageData.sender.id)) ?
                                        () => present({ buttons: createAlertMessageBtn() }) : () => { }
                                }
                            >
                                <UserAvatar
                                    id={messageData.sender.id}
                                    firstName={messageData.sender.firstName}
                                    img={messageData.sender.profilePicture ? messageData.sender.profilePicture : null}
                                    size={32}
                                    className='message-avatar'
                                />
                                <div className="sender-info" data-id={messageData.sender.id} >
                                    <b className='sender-name' >
                                        {messageData.sender.is_sponsor_user && <span className="sponsor-badge">{sponsorTitle}</span>}
                                        <span>{`${messageData.sender.firstName} ${messageData.sender.lastName}`}</span>
                                    </b>
                                    <IonText color="medium" style={{
                                        fontSize: '10px'
                                    }}>
                                        <UserInfoChecked jobTitle={messageData.sender.jobTitle} organisation={messageData.sender.organisation} />
                                    </IonText>
                                </div>
                            </div>
                        </div>
                    }

                    <div className={`message-bottom-layer`}>
                        <div
                            id={messageData.id || 'msg'}
                            className={`message-bubble${isTop ? ' isTop' : ''}${isBottom ? ' isBottom' : ''}`}
                            onClick={() => {
                                isModerator && presentModeration({ buttons: createModerationBtn() })
                            }}
                        >
                            {arrowLeft}
                            <UrlConverter>{messageData.message}</UrlConverter>
                            {
                                messageData.status === 'hidden' ? <IonChip color="white" outline>{translate('Hidden')}</IonChip> : ''
                            }
                        </div>
                        {
                            (
                                (!nextMessage) ||
                                (nextMessage.sender.id !== messageData.sender.id) ||
                                (nextMessage.timestamp - messageData.timestamp >= 60)
                            ) &&
                            <p className='messaage-time'><Moment locale={lang} format={'hh:mm A'}>{date}</Moment></p>
                        }
                    </div>
                </div>
            </IonItem>

        </Fragment>
    )
}

export default React.memo(MessageBubbleMomo);
