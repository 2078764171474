import { IonButton, IonCard, IonCardContent, IonIcon, IonLabel, IonPage } from "@ionic/react"
import { arrowBackCircleOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { translate } from "../../app/translate";


const InvalidMagicLink: React.FC = () => {
    const history = useHistory();

    return(
        <IonPage>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                padding: '1rem',
                maxWidth: '480px',
                margin: 'auto'
            }}>
                <IonLabel style={{textAlign: 'center'}}>
                    <h1><strong>{translate('Oops!')}</strong></h1>
                    <h3>{translate('Something\'s not right...')}</h3>
                </IonLabel>
                <IonCard color="medium"  style={{textAlign: 'center'}}>
                    <IonCardContent>
                    <p>{translate('The Magic Link you used seems to be either exipred or invalid. Please get your magic link again from your event host.')}</p>
                    </IonCardContent>
                </IonCard>
                <IonButton
                    onClick={() => {
                        history.push('/');
                    }}
                >
                    <IonIcon icon={arrowBackCircleOutline} slot='start'/>
                    <IonLabel>{translate('Back')}</IonLabel>
                </IonButton>
            </div>
        </IonPage>
    )
}

export default InvalidMagicLink;