import { IonAvatar, IonIcon } from '@ionic/react';
import { callOutline, personOutline } from 'ionicons/icons';
import { User } from "../Auth/types";
import { translate } from '../../app/translate';

interface CallScreenProps {
    isCaller: boolean | undefined,
    user: User,
    startVideoCall: () => Promise<void>,
    declineVideoCall: () => void
}

export default function CallScreen(props: CallScreenProps) {
    const { isCaller, user, startVideoCall, declineVideoCall } = props;

    return <div className="videoCall">
        <div className="videoCall__title">{!isCaller ? 'Ringing...' : 'Calling...'}</div>
        <div className="videoCall__name">{user.firstName} {user.lastName}</div>
        <div className="videoCall__jobTitle">{user.organisation} | {user.jobTitle}</div>
        <div>
            <IonAvatar
                className='profile-avatar-xl ion-align-items-center ion-justify-content-center'
                style={{
                    display: 'flex',
                    backgroundColor: 'var(--ion-color-light)'
                }}
            >
                {user.profilePicture ?
                    <img src={user.profilePicture} alt={`${user.id}`} />
                    : <IonIcon icon={personOutline} color='black' size='large' />}
            </IonAvatar>
        </div>
        <div className="videoCall__buttonsArea">
            {(!isCaller) && (
                <div>
                    <IonIcon
                        icon={callOutline}
                        color="white"
                        className="videoCall__button -callOutline"
                        onClick={startVideoCall}
                    />
                    <div>{translate('Answer')}</div>
                </div>
            )}
            <div>
                <IonIcon
                    icon={callOutline}
                    color="white"
                    className="videoCall__button -callHangup"
                    onClick={declineVideoCall}
                />
                <div>{isCaller ? translate('Hangup') : translate('Decline')}</div>
            </div>
        </div>
    </div>;
}
