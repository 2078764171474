import { User } from './../pages/Auth/types';
import { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import videoCallStatus from './enums/videoCallStatus';
import type { RootState, AppDispatch } from './store';
import { DolbyLoginResponse } from './types/videoCall';
import { selectVideoCallStatus } from './slices/userSlice';
import { getDolbyToken } from './firebase';
import { isPlatform } from '@ionic/react';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useVideoCallWatcher = () => {
    const history = useHistory();
    const callStatus = useAppSelector(selectVideoCallStatus)
    const { status, groupId } = callStatus
    const historyRef = useRef<string[]>([])

    useEffect(() => {
        historyRef.current.push(history.location.pathname)
    }, [history.location.pathname])

    useEffect(() => {
        switch (status) {
            case videoCallStatus.outgoingCall:
                history.push(`/videoCall/${groupId}`);
                break;
            case videoCallStatus.incomingCall:
                history.push(`/videoCallReceived/${groupId}`);
                break;
            case videoCallStatus.available:
                let loc = historyRef.current.pop();
                while (loc && (loc.startsWith('/videoCall') || loc.startsWith('/userprofile'))) {
                    loc = historyRef.current.pop()
                }
                if (loc || historyRef.current.length === 0) {
                    if (loc) {
                        history.push(loc)
                    } else if (historyRef.current.length === 0) {
                        history.push('/home/chatrooms')
                    }
                }

                break;
        }
    }, [status])
}

export const useGetVideoCallUrl = (user: User, groupId: string, declineVideoCall: () => void) => {
    const [dolbyResponse, setDolbyResponse] = useState<DolbyLoginResponse>();
    const [videoCallUrl, setVideoCallUrl] = useState<string>();

    useEffect(() => {
        const async = async () => {
            if (groupId) {
                try {
                    const response = await getDolbyToken(groupId)
                    if (response) {
                        setDolbyResponse(response)
                    }
                } catch (err) {
                    alert('There was an error getting the Dolby Token: ' + err)
                    declineVideoCall()
                }
            }
        }

        async();
    }, [user, groupId])

    useEffect(() => {
        const isMobile = isPlatform('mobile')
        
        if (dolbyResponse) {
            let url = `${process.env.REACT_APP_WEBCAM_URL}?roomId=${groupId}&chatGo=true&displayName=${user.firstName}%20${user.lastName}&hangup=${isMobile}`;

            if (dolbyResponse?.unknown_referer === true) {
                url += `&unknownDomain=${dolbyResponse?.referer}`;
            } else if (dolbyResponse?.access_token) {
                url += `&token=${dolbyResponse?.access_token}`;
            }

            setVideoCallUrl(url);
        }
    }, [dolbyResponse, groupId, user.firstName, user.lastName])

    return {
        videoCallUrl
    }
}