import {
    IonBadge,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonText,
    IonPage
} from "@ionic/react";
import { businessOutline, chatbubble } from "ionicons/icons";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { roomsIsInitialised, selectAllRooms, selectLastMessages, selectOpenChats, updateOpenChats } from "../../app/slices/roomSlice";
import { removeCurrentChannel, selectUserTimestamps } from "../../app/slices/userSlice";
import EmptyContent from "../../components/EmptyContent";
import RoomListSkeleton from "../../components/Skeleton/RoomListSkeleton";
import TabHeader from "../../components/TabHeader";
import ChatRoomNonIonic from "./ChatRoomNonIonic";
import './ChatRooms.css';
import { translate } from "../../app/translate";
interface Props {
    viewportInfo: { width: number, height: number; };
}

const ChatRooms: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const initRooms = useAppSelector(selectAllRooms);
    const [rooms, setRooms] = useState(initRooms);
    const IsInitialised = useAppSelector(roomsIsInitialised);
    const lastMessages = useAppSelector(selectLastMessages);
    const userTimestamps = useAppSelector(selectUserTimestamps);
    const openChats = useAppSelector(selectOpenChats);

    // vars and Fn for desktop app

    const openExistingChats = (roomId: number) => {
        if (openChats.includes(roomId)) {
            return;
        }
        let temp: number[] = [];
        if (openChats.length <= 0) {
            temp.push(roomId);
        } else {
            temp = [...openChats];
            temp.push(roomId);
        }
        dispatch(updateOpenChats(temp));
    };

    const closeExistingChats = (roomId: number) => {
        dispatch(removeCurrentChannel(parseInt(String(roomId))));
        dispatch(updateOpenChats(openChats.filter((chat) => chat != roomId)));
    };

    useEffect(() => {
        setRooms(initRooms.sort((a, b) => a.order - b.order))
    }, [initRooms])

    const EmptyContentComponent =
        <EmptyContent
            header={translate(`Hang tight`)}
            message={translate(`Your chat rooms will be available to enter shortly.`)}
            icon={businessOutline}
        />

    return (
        <IonPage>
            {
                props.viewportInfo.width > 960 ?
                    <Fragment>
                        <TabHeader title={translate(`Your Chat Rooms`)} />
                        <IonContent>
                            <div className='chats-desktop-container'>
                                <div className='chats-desktop-sidebar'>
                                    { !IsInitialised ? <RoomListSkeleton count={3} /> : rooms.length <= 0 ?
                                        EmptyContentComponent
                                        :
                                        <IonList lines="full">
                                            {
                                                rooms.map((room) => (
                                                    <IonItem
                                                        key={String(room.id)}
                                                        style={{ cursor: openChats.includes(room.id) ? 'default' : 'pointer' }}
                                                        onClick={() => openExistingChats(room.id)}
                                                        color={openChats.includes(room.id) ? 'light' : ''}
                                                    >
                                                        <IonLabel>
                                                            <h2>
                                                                <b>{room.name}</b>
                                                            </h2>
                                                            <h3 style={{ color: 'var(--ion-color-medium)' }} >
                                                                {lastMessages[room.id] && lastMessages[room.id].message ? <IonIcon icon={chatbubble} className='ion-margin-end' /> : null}
                                                                <IonText>{lastMessages[room.id] && lastMessages[room.id].message ? lastMessages[room.id].message : ''}</IonText>
                                                            </h3>
                                                            {openChats.includes(room.id) ? <IonBadge color='warning' className=''>{translate('Opened')}</IonBadge> : <br />}
                                                        </IonLabel>
                                                        {
                                                            ((lastMessages[room.id] && userTimestamps[room.id] && lastMessages[room.id].time > parseInt(userTimestamps[room.id])) || (lastMessages[room.id] && !userTimestamps[room.id])) &&
                                                                <IonBadge slot='end'>{translate('new')}</IonBadge>
                                                        }
                                                    </IonItem>
                                                ))
                                            }
                                        </IonList>
                                    }
                                </div>
                                <div className='chats-desktop-content'>
                                    { openChats.map((roomId) => (
                                        <Fragment key={String(roomId)}>
                                            <div
                                                className='chats-desktop-window'
                                                key={String(roomId)}
                                                style={{
                                                    width: `${100 / (openChats.length <= 4 ? openChats.length : 4)}%`,
                                                    height: `${100 / Math.ceil(openChats.length/4)}%`
                                                }}
                                            >
                                                
                                                <ChatRoomNonIonic
                                                    roomId={roomId}
                                                    isWindow
                                                    onClose={() => closeExistingChats(roomId)}
                                                />
                                                
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </IonContent>
                    </Fragment>
                    :
                    <Fragment>
                        <TabHeader title={translate(`Your Chat Rooms`)} />
                        <IonContent
                            //fullscreen
                        >
                            { !IsInitialised ? <RoomListSkeleton count={3} /> : rooms.length <= 0 ?
                                EmptyContentComponent
                                :
                                <IonList lines="full">
                                    { rooms.map((room) => (
                                        <IonItem
                                            key={String(room.id)}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => history.push({
                                                pathname: `/chatroom/${room.id}`,
                                                state: {direction: 'forward'}
                                            })}
                                        >
                                            <IonLabel>
                                                <h2><b>{room.name}</b></h2>
                                                <h3 style={{
                                                    color: 'var(--ion-color-medium)',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'center'
                                                }}>
                                                    <IonIcon icon={chatbubble} style={{marginRight: '8px'}} />
                                                    <IonText>{lastMessages[room.id] && lastMessages[room.id].message ? lastMessages[room.id].message : ''}</IonText>
                                                </h3>
                                                <br />
                                            </IonLabel>
                                            {
                                                ((lastMessages[room.id] && userTimestamps[room.id] && lastMessages[room.id].time > parseInt(userTimestamps[room.id])) || (lastMessages[room.id] && !userTimestamps[room.id])) &&
                                                    <IonBadge slot='end'>{translate('new')}</IonBadge>
                                            }
                                        </IonItem>
                                    ))}
                                </IonList>
                            }
                        </IonContent>
                    </Fragment>
            }
        </IonPage>
    );
};

export default ChatRooms;
