export const colorMaps = [
    {
        initials: 'aeiou',
        color: '#519839'
    },
    {
        initials: 'bdglp',
        color: '#c377e0'
    },
    {
        initials: 'cfjmr',
        color: '#055a8c'
    },
    {
        initials: 'hnstw',
        color: '#cd8313'
    },
    {
        initials: 'kqv',
        color: '#091e42'
    },
    {
        initials: 'xyz1234567890',
        color: '#888'
    }
]