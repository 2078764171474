import { IonContent, IonLoading, IonPage, useIonAlert } from "@ionic/react";
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router";
import { useAppSelector, useGetVideoCallUrl } from "../../app/hooks";
import { selectUser } from "../../app/slices/userSlice";
import { Browser } from '@capacitor/browser';
import { isPlatform } from '@ionic/react';
import "./videoCall.scss"
import { selectGroupById, selectGroupsIsInit } from "../../app/slices/groupSlice";
import { shallowEqual } from "react-redux";
import videoCallStatus from "../../app/enums/videoCallStatus";
import CallScreen from "./CallScreen";
import DolbyIframe from "./DolbyIframe";
import { User } from "../Auth/types";
import { getUserById, hangUpVideoCall, listenForUser, pickUpVideoCall } from "../../app/firebase";
import { Unsubscribe } from "firebase/auth";
import { translate } from "../../app/translate";

interface Props { isCaller?: boolean }

const VideoCall: React.FC<Props> = (props: Props) => {
    const { isCaller } = props;
    const [alert] = useIonAlert();
    const parma = useParams<{ id: string }>();
    const [groupId] = useState(parma.id);
    const [showDolby, setShowDolby] = useState(false);
    const group = useAppSelector(selectGroupById(groupId), shallowEqual);
    const groupIsInit = useAppSelector(selectGroupsIsInit);
    const user = useAppSelector(selectUser);
    const [otherUser, setOtherUser] = useState<User>();
    const declineVideoCall = () => {
        if (groupId) hangUpVideoCall(groupId)
    }
    const { videoCallUrl } = useGetVideoCallUrl(user, groupId, declineVideoCall)
    const [loading, setLoading] = useState(!groupIsInit || !otherUser);
    const unsubscribe = useRef<Unsubscribe>();

    useEffect(() => {
        setLoading((!groupIsInit || !otherUser))
    }, [groupIsInit, otherUser])

    useEffect(() => {
        return () => {
            if (unsubscribe.current) {
                unsubscribe.current()
            }
            declineVideoCall();
        }
    }, [])

    useEffect(() => {
        if (!otherUser && groupId && videoCallUrl && Object.keys(group.members).length > 0) {
            console.error(group.members)
            if (group.members.length > 0) {
                const otherUser = group.members.find(x => x !== user.id)
                if (otherUser) {
                    unsubscribe.current = listenForUser(otherUser, updatedUserState)

                    getUserById(otherUser)
                        .then(data => {
                            if (data) {
                                setOtherUser(data as User)
                            } else {
                                errorCalling('Cannot call');
                                declineVideoCall();
                            }
                        })
                }
            }
        }
    }, [group.members, videoCallUrl])

    const updatedUserState = (data: User) => {
        const callStatus = data?.callStatus?.status;

        switch (callStatus) {
            case videoCallStatus.answeredCall:
                startVideoCall();
                break;
        }
    }

    const errorCalling = (errorMessage: string = `Failed to call user`) => {
        var message = errorMessage;
        var header = translate("Error");

        alert({
            header,
            message,
            buttons: [
                'OK',
            ],
        });

        declineVideoCall()
    }

    const startVideoCall = async () => {
        if (videoCallUrl) {
            if (!isCaller) {
                pickUpVideoCall(groupId);
            }

            if (isPlatform('mobile')) {
                await Browser.open({ url: `${videoCallUrl}${'&chatGo=true&screenshare=false&chat=false'}` });
                Browser.addListener('browserFinished', () => {
                    declineVideoCall()
                })
            }

            setShowDolby(true);
        } else {
            console.error('No dolby url found');
            errorCalling('No dolby url found');
        }
    }

    const showCallScreen = !showDolby && groupIsInit && otherUser;

    return (
        <IonPage>
            <IonLoading
                isOpen={loading}
                message={'Preparing Video Call...'}
            />
            <IonContent color='primary'>
                {
                    !loading && (
                        <>
                            {
                                // show call screen
                                showCallScreen && (
                                    <CallScreen
                                        isCaller={isCaller}
                                        user={otherUser}
                                        startVideoCall={startVideoCall}
                                        declineVideoCall={declineVideoCall}
                                    />
                                )
                            }
                            {
                                // dolby iframe view - desktop
                                showDolby && !isPlatform('mobile') && (
                                    <DolbyIframe
                                        videoCallUrl={videoCallUrl}
                                        declineVideoCall={declineVideoCall}
                                    />
                                )
                            }
                        </>
                    )
                }
            </IonContent>
        </IonPage>
    )
}

export default VideoCall;


