import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Questions, Question } from "../types/questions";

const initialState: Questions = {
    questions: [],
}

export const questionSlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setQuestions: (state: Questions, action: PayloadAction<Array<Question>>) => {
            state.questions = action.payload.sort((a, b) => a.order - b.order);
        },
    }
})

export const selectQuestions = (state: RootState): Array<Question> => state.questions.questions;

export const { setQuestions } = questionSlice.actions;

export default questionSlice.reducer;