
import { RadioGroupChangeEventDetail } from '@ionic/core';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel, IonList, IonListHeader, IonModal, IonPopover, IonRadio, IonRadioGroup, IonText, IonTitle, IonToolbar
} from "@ionic/react";
import { closeOutline } from 'ionicons/icons';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Answer, Question } from '../app/types/questions';
import './GroupedQuestion.css';
import { translate } from '../app/translate';

interface Props {
    question: Question;
    parentHandleChange: (data: { id: number, answers: Array<number> }) => void;
    userInitAnswers: Array<number>;
}
interface AnswerGroups {
    groupName: string;
    answers: Array<Answer>;
}

const GroupedQuestion: React.FC<Props> = ({ question, parentHandleChange, userInitAnswers }) => {
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const [groupedAnswers, setGroupedAnswers] = useState<Array<AnswerGroups>>([]);
    const [answerValue, setanswerValue] = useState<number | null>(userInitAnswers.length > 0 ? userInitAnswers[0] : null);

    useEffect(() => {
        userInitAnswers.length > 0 && setanswerValue(userInitAnswers[0])
    }, [userInitAnswers])

    useEffect(() => {
        let temp: Array<AnswerGroups> = [];
        question.answers.forEach(answer => {
            if (answer.group) {
                if (temp.filter(item => item.groupName === answer.group).length === 0) {
                    temp.push({
                        groupName: answer.group,
                        answers: [answer]
                    })
                } else {
                    temp.filter(item => item.groupName === answer.group)[0].answers.push(answer)
                }
            }
        });
        setGroupedAnswers(temp);
    }, [question]);

    const handleChange = (event: CustomEvent<RadioGroupChangeEventDetail>) => {
        setanswerValue(event.detail.value);
        const parentPayload = {
            id: question.id,
            answers: [event.detail.value]
        }
        parentHandleChange(parentPayload)
    }

    return (
        <Fragment>
            <div
                className=''
                onClick={(e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e })
                }}
                style={{
                    width: '100%'
                }}
            >
                <IonText
                    color={!answerValue ? 'medium' : 'dark'}
                    style={{cursor: 'pointer'}}
                ><IonLabel>{!answerValue ? translate('Select One') : question.answers.filter(answer => answer.id === answerValue)[0].answer}</IonLabel></IonText>
            </div>
            <IonModal
                // event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                showBackdrop={true}
                backdropDismiss={true}
                cssClass={`grouped-question-popover`}
            >
                <IonHeader>
                    <IonToolbar color='light'>
                        <IonButton 
                            slot='start'
                            fill='clear'
                            onClick={() => {
                                setShowPopover(s => Object.assign({ ...s }, { showPopover: false }))
                            }}
                        >
                            <IonIcon icon={closeOutline} slot='start' />
                            <IonLabel>{translate('Back')}</IonLabel>
                        </IonButton>
                        <IonTitle>{question.question}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent color='light'>
                    <>
                        <IonRadioGroup
                            name={String(question.id)}
                            value={answerValue ? answerValue : ''}
                            onIonChange={(event) => {
                                handleChange(event)
                                setShowPopover(s => Object.assign({ ...s }, { showPopover: false }))
                            }}
                            className='grouped-question-radio-group'
                        >
                            {
                                groupedAnswers.map(group => (
                                    <IonCard key={`question_${question.id}-group_${group.groupName}`}>
                                        <IonCardHeader>
                                            <IonCardSubtitle>{group.groupName}</IonCardSubtitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <IonList lines='full'>
                                                {
                                                    group.answers.map(answer => (
                                                        <IonItem key={`group_${group.groupName}-answer_${answer.id}`} >
                                                            <IonLabel>{answer.answer}</IonLabel>
                                                            <IonRadio slot="start" value={answer.id} />
                                                        </IonItem>
                                                    ))
                                                }
                                            </IonList>
                                        </IonCardContent>
                                    </IonCard>
                                ))
                            }
                        </IonRadioGroup>
                    </>
                </IonContent>
            </IonModal>
        </Fragment>
    )
}

export default GroupedQuestion;