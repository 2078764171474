import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Group } from "../types/group";
import { Message } from "../../models/message";
import { User } from "../../pages/Auth/types";

interface GroupState {
	groups: Group[];
	count: number | null;
	newId: string | null;
}

const initialState: GroupState = {
	groups: [],
	count: null,
	newId: null,
};

export const groupSlice = createSlice({
	name: "groups",
	initialState,
	reducers: {
		updateGroupMessage: (state: GroupState, action: PayloadAction<any>) => {
			const idx = state.groups.findIndex(
				(group: Group) => group.id == action.payload.id
			);
			if (idx == -1) {
				state.groups.push({
					id: action.payload.id,
					name: "",
					directMessage: false,
					admins: [],
					members: [],
					meta: {
						createdBy: 0,
						thumbnail: "",
					},
					modifiedAt: "",
					createdAt: "",
					messages: [],
					users: [],
				});
			}
			state.groups[idx].messages = state.groups[idx].messages.concat(
				action.payload.messages
			);
		},
		addOlderGroupMessagesBulk: (
			state: GroupState,
			action: PayloadAction<{ id: string; messages: Array<Message> }>
		) => {
			const idx = state.groups.findIndex(
				(group: Group) => group.id == action.payload.id
			);
			state.groups[idx].messages = action.payload.messages.concat(
				state.groups[idx].messages
			);
		},
		resetGroupMessages: (state: GroupState, action: PayloadAction<string>) => {
			const idx = state.groups.findIndex(
				(group: Group) => group.id == action.payload
			);
			if (state.groups[idx].messages.length > 50) {
				state.groups[idx].messages = state.groups[idx].messages.filter(
					(item, index) => index >= state.groups[idx].messages.length - 50
				);
			}
		},
		updateGroupUsers: (state: GroupState, action: PayloadAction<any>) => {
			const idx = state.groups.findIndex(
				(group: Group) => group.id == action.payload.id
			);
			if (idx == -1) {
				state.groups.push({
					id: action.payload.id,
					name: "",
					directMessage: false,
					admins: [],
					members: [],
					meta: {
						createdBy: 0,
						thumbnail: "",
					},
					modifiedAt: "",
					createdAt: "",
					messages: [],
					users: action.payload.users,
				});
			} else {
				state.groups[idx].users = action.payload.users;
			}
		},
		updateGroupDetails: (
			state: GroupState,
			action: PayloadAction<Omit<Group, "messages" | "users">>
		) => {
			let newDetails = action.payload;
			const idx = state.groups.findIndex(
				(group: Group) => group.id === newDetails.id
			);
			const newGroup: Group = Object.assign(
				{ ...state.groups[idx] },
				newDetails
			);
		},
		setGroup: (state: GroupState, action: PayloadAction<Group>) => {
			const payload = action.payload;

			const idx = state.groups.findIndex(
				(group: Group) => group.id == payload.id
			);

			if (idx === -1) {
				state.groups.push(payload);
			} else {
				state.groups[idx] = payload;
			}
		},
		setGroupCount: (state: GroupState, action: PayloadAction<number>) => {
			state.count = action.payload;
		},
		resetGroupSlice: (state: GroupState) => {
			state.groups = [];
			state.count = null;
		},
		deleteGroup: (state: GroupState, action: PayloadAction<string>) => {
			const groups = state.groups.filter(
				(group: Group) => String(group.id) !== String(action.payload)
			);
			state.groups = groups;
		},
		setNewGroupId: (state: GroupState, action: PayloadAction<string | null>) => {
			state.newId = action.payload
		},
		clearNewGroup: (state: GroupState) => {
			state.groups = [...state.groups.filter(x => x.id !== 'new')];
		}
	},
});

export const selectGroups = (state: RootState): Array<Group> => {
	return state.groups.groups.filter((group) => group.messages.length > 0);
};
// export const selectGroupsIsInit = (state: RootState): boolean => state.groups.count !== null && state.groups.count === state.groups.groups.length;
export const selectGroupsIsInit = (state: RootState): boolean =>
	state.groups.count !== null;
export const selectGroupCounts = (
	state: RootState
): { count: number | null; groupLength: number } => {
	return { count: state.groups.count, groupLength: state.groups.groups.length };
};

export const selectGroupById =
	(id: string) =>
	(state: RootState): Group => {
		if (state.groups.groups.length > 0) {
			var match = state.groups.groups.filter((group: Group) => group.id === id);

			if (match.length === 1) {
				return match[0];
			}
		}

		return {
			id: "",
			name: "",
			directMessage: false,
			admins: [],
			members: [],
			meta: {
				createdBy: 0,
				thumbnail: "",
			},
			modifiedAt: "",
			createdAt: "",
			messages: [],
			users: [],
		};
	};

export const selectGroupByRecipient =
	(id: number) =>
	(state: RootState): Group | undefined =>
		state.groups.groups.find((group) => group.members.includes(id));

export const selectTempGroupWithRecipientId =
	(recipientId: number) =>
	(state: RootState): Group | undefined =>
		state.groups.groups.find(
			(group) => group.members.includes(recipientId) && group.id === "new"
		);

export const checkDMExists = (recipient: number) => (state: RootState): string => {
	const group: Array<Group> = state.groups.groups.filter((group: Group) => {
		return group.directMessage === true && group.members.includes(recipient);
	});

	if (group.length > 0) {
		return group[0].id;
	}

	return "";
};

export const checkRealDMExists = (recipient: number) => (state: RootState): string => {
	const group: Array<Group> = state.groups.groups.filter((group: Group) => {
		return group.directMessage === true && group.members.includes(recipient) && group.id !== `new`;
	});

	if (group.length > 0) {
		return group[0].id;
	}

	return "";
};

export const selectGroupMessagesById =
	(id: string) =>
	(state: RootState): Array<Message> => {
		const idx = state.groups.groups.findIndex((group: Group) => group.id == id);
		if (idx == -1) {
			return [];
		} else {
			return state.groups.groups[idx].messages;
		}
	};

export const selectGroupsLastMessages = (
	state: RootState
): { [key: string]: { message: string; time: number; sender: User } } => {
	const messages: {
		[key: string]: { message: string; time: number; sender: User };
	} = {};
	state.groups.groups.forEach((group) => {
		if (group.messages.length > 0) {
			messages[group.id] = {
				message: group.messages[group.messages.length - 1].message,
				time: group.messages[group.messages.length - 1].timestamp,
				sender: group.messages[group.messages.length - 1].sender,
			};
		}
	});

	return messages;
};

export const selectNewGroupId = (state: RootState) => state.groups.newId;

export const {
	updateGroupMessage,
	addOlderGroupMessagesBulk,
	resetGroupMessages,
	updateGroupUsers,
	setGroup,
	setGroupCount,
	resetGroupSlice,
	deleteGroup,
	updateGroupDetails,
	setNewGroupId,
	clearNewGroup,
} = groupSlice.actions;

export default groupSlice.reducer;
