import { IonAlert, IonButton, IonHeader, IonIcon, IonLabel, IonToolbar } from "@ionic/react";
import { arrowDownCircleOutline, chevronBackOutline, closeOutline, videocamOutline, mailOutline } from "ionicons/icons";
import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shallowEqual } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router";
import { getNextPageGroupMessages, sendMessage, updateUserLastRead } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { resetGroupMessages, selectGroupById, selectGroupMessagesById, selectGroupsIsInit, selectGroupCounts } from "../../app/slices/groupSlice";
import { addCurrentChannel, removeCurrentChannel, selectUserId, selectCurrentChannel } from "../../app/slices/userSlice";
import { store } from "../../app/store";
import MessageBubbleMemo from "../../components/Chat/MessageBubbleMemo";
import MessageInput from "../../components/Chat/MessageInput";
import UserAvatar from "../../components/UserAvatar";
import { MessagePayload } from '../../models/message';
import NotificationBell from '../../components/NotificationBell';
import UserInfoChecked from "../../components/Classes/UserInfoChecked";
import { translate } from "../../app/translate";

interface Props{
    viewportInfo?: { width: number, height: number };
    groupId?: string;
    isWindow?: boolean;
    onClose?: () => void;
}

const DirectMessageNonIonic: React.FC<Props> = (props: Props) => {
    const parma = useParams<{ id: string }>();
    const id = props.groupId ? props.groupId : parma.id;
    const group = useAppSelector(selectGroupById(id), shallowEqual);
    const groupIsInit = useAppSelector(selectGroupsIsInit);
    const rawMessages = useAppSelector(selectGroupMessagesById(id));
    const [messages, setMessages] = useState(rawMessages);
    const allGroupsCount = useAppSelector(selectGroupCounts);
    const userId = useAppSelector(selectUserId);
    const CurrentChannel = useAppSelector(selectCurrentChannel);
    const target = group.members.filter((member: number) => member != userId)[0];
    const contentRef = useRef<HTMLDivElement>(null);
    const [showBackToBottom, setShowBackToBottom] = useState(false);
    const allowLoadMore = useRef(true);
    const [alertController, setAlertController] = useState({
        isOpen: false,
        header: '',
        message: '',
    });

    var thumbnail = group.meta.thumbnail;
    var status = "disabled";
    var name = group.name;

    if (group.directMessage === true && target != undefined && target in group.users) {
        thumbnail = group.users[target].profilePicture;
        status = translate(group.users[target].online ? "online" : "offline");
        name = group.users[target].firstName+` `+group.users[target].lastName;
    }

    const addNewMessage = async (newMessage: MessagePayload) => {
        const sendResult = await sendMessage(newMessage.message, id);
        if (sendResult === 'ILLEGAL') {
            setAlertController({
                isOpen: true,
                header: translate('Message Cannot be Sent'),
                message: translate('This message contains illegal characters / words and cannot be sent!'),
            });
        }
    }
    
    const history = useHistory();

    useEffect(() => {
        setMessages(rawMessages)
    }, [rawMessages]);

    useEffect(() => {
        store.dispatch(addCurrentChannel(id));

        if (!props.isWindow) {
            return function cleanup() {
                store.dispatch(removeCurrentChannel(id));
            }
        }
    }, [id, props.isWindow])

    useEffect(() => {
        if (userId > 0 && id && CurrentChannel.includes(id)) {
            updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
        }
        return () => {
            if (userId > 0 && id && CurrentChannel.includes(id)) { 
                updateUserLastRead(id, Math.round(new Date().getTime() / 1000))
            }
        }
    }, [userId, id, CurrentChannel]);

    // load more messages
    const loadMore = useCallback(
        async () => {
            if (messages && messages.length > 0) {
                const olderMessages = await getNextPageGroupMessages(String(id), String(messages[0].id));
                let temp = [...messages];
                temp.unshift(...olderMessages);
                setMessages(temp);
            }
        },
        [messages, id],
    )

    // handle when chat window scrolls
    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const element = (e.target as HTMLDivElement), scrollTop = element.scrollTop, clientHeight = element.clientHeight;
        if (clientHeight + scrollTop < 0) {
            setShowBackToBottom(true)
        } else {
            setShowBackToBottom(false)
        }
    }

    // back to bottom and reset messages to 50
    const backToBottom = () => {
        allowLoadMore.current = false;
        //messages.length > 50 && store.dispatch(resetGroupMessages(id));
        if (messages.length > 50) {
            const temp = [...messages];
            const sliced = temp.slice(Math.max(temp.length - 50, 0));
            setMessages(sliced);
        }
        const element = contentRef.current as HTMLDivElement;
        element.scrollTop = 0;

        setTimeout(() => {
            allowLoadMore.current = true;
        }, 300);
    }
    
    return (
        <Fragment>
            {
                props.viewportInfo &&
                props.viewportInfo.width > 960 &&
                !props.isWindow &&
                    <Redirect to={`${process.env.PUBLIC_URL}/home/inbox`} />
            }
            <div className='chat-window-wrapper'>
                { props.isWindow ?
                    <div className='chat-window-header'>
                        <p>{String(name)}</p>
                        <IonButton
                            className='ion-no-padding'
                            fill='clear'
                            onClick={props.onClose}
                        >
                            <IonIcon color='white' icon={closeOutline} />
                        </IonButton>
                    </div>
                    :
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButton slot='start' fill="clear" className='ion-no-margin ion-no-padding'
                                onClick={() => history.goBack()}
                            >
                                <IonIcon style={{color: `var(--ion-color-primary-contrast)`}} icon={chevronBackOutline} />
                            </IonButton>
                            <IonButton slot='start' className='ion-no-margin ion-no-padding' fill='clear'
                                onClick={() => {
                                    group.directMessage
                                    ? history.push({
                                        pathname: `${process.env.PUBLIC_URL}/userprofile/${target}`,
                                        state: group.users[target]
                                    })
                                    : history.push(`${process.env.PUBLIC_URL}/groupinfo/${group.id}`)
                                }}
                            >
                                <UserAvatar
                                    id={2}
                                    firstName={name}
                                    img={thumbnail}
                                    status={status}
                                    size={28}
                                    className='ion-margin-end ion-margin-start'
                                />
                                <IonLabel style={{color: `var(--ion-color-primary-contrast)`}} className='ion-text-start'>
                                    <h2><b>{name}</b></h2>
                                    <p style={{ fontSize: '12px' }}>
                                        {group.users[target] &&
                                            <UserInfoChecked jobTitle={group.users[target].jobTitle} organisation={group.users[target].organisation}/>
                                        }
                                    </p>
                                </IonLabel>
                            </IonButton>
                            {/* <IonButton slot='end' fill="clear">
                                <IonIcon color='white' icon={videocamOutline}/>
                            </IonButton> */}
                            <NotificationBell/>
                        </IonToolbar>
                    </IonHeader>
                }
                <div className={`message-back-to-bottom-btn${showBackToBottom ? ' show': ''}`}>
                    <IonButton
                        color='medium'
                        onClick={() => backToBottom()}
                    >
                        <IonIcon icon={arrowDownCircleOutline}/> {translate('Jump to Latest')}
                    </IonButton>
                </div>


                <div
                    className={`chat-window-content${props.isWindow ? ' isWindow': ''}`}
                    id={`chat-window-content_${group.id}`}
                    ref={contentRef}
                    onScroll={(e: React.UIEvent<HTMLDivElement>) => handleScroll(e)}
                >
                    {groupIsInit &&
                        <InfiniteScroll
                            dataLength={messages.length}
                            next={() => loadMore()}
                            inverse={true}
                            hasMore={allowLoadMore.current && messages.length > 49}
                            loader={''}
                            scrollableTarget={`chat-window-content_${group.id}`}
                            style={{paddingBottom: '54px'}}
                        >
                            {
                                messages.map((item, index) => (
                                        <MessageBubbleMemo
                                        key={`${id}_${item.id}`}
                                        messageData={item}
                                        lastMessage={messages[index - 1] || null}
                                        nextMessage={messages[index +1] || null}
                                        />
                                ))
                            }
                        </InfiniteScroll>
                    }
                </div>
                <div className='chat-window-footer'>
                    <MessageInput
                        // sender={user}
                        recipient={parma.id}
                        addNewMessage={(newMessage: MessagePayload) => addNewMessage(newMessage)}
                        lastMessage={messages.length}
                        type="dm"
                        dmId={group.users[target].id}
                    />
                </div>
            </div>
            <IonAlert
                isOpen={alertController.isOpen}
                onDidDismiss={() => setAlertController({
                    isOpen: false,
                    header: '',
                    message: '',
                })}
                header={alertController.header}
                message={alertController.message}
                buttons={['OK']}
            />
        </Fragment>
    )
}

export default DirectMessageNonIonic;