import { Fragment } from "react"
import { IonList, IonItem, IonSkeletonText, IonLabel } from "@ionic/react";


interface Props{
    children?: any;
    count?: number;
}

const RoomListSkeleton: React.FC<Props> = (props: Props) => {
    const skeletons: Array<number> = [];

    const count = props.count ? props.count : 10;
    for (var i = 0; i < count; i++){
        skeletons.push(i);
    }
    
    return (
        <Fragment>
            <IonList lines='full'>
                {
                skeletons.map((item, index) => (
                    <IonItem key={`skeleton-${index}`}>
                        <IonLabel>
                            <h2><IonSkeletonText animated style={{ width: '80%' }} /></h2>
                            <h3><IonSkeletonText animated style={{ width: '60%' }} /></h3>
                            <br/>
                        </IonLabel>
                    </IonItem>
                ))
                }
            </IonList>
        </Fragment>
    )
}

export default RoomListSkeleton;