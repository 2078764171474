import {
	IonContent,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonList,
	IonListHeader,
	IonLoading,
	IonPage,
} from "@ionic/react";
import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { firebaseLogin, userLogout } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import {
	addMagicTokens,
	selectMagicEvents,
	selectMagicTokens,
    setMagicTokens,
    setMagicEvents
} from "../../app/slices/magicEventsSlice";
import { CheckEnv } from "../../components/Classes/CheckEnv";
import TabHeader from "../../components/TabHeader";
import {
	isAuthenticated,
	selectEvent_key,
	setCredentials,
	setEvent_key,
} from "../Auth/authSlice";
import { getBrandings } from "../../components/Classes/Brandings";
import { MagicEvent } from "../../app/types/events";
import { translate } from "../../app/translate";

const Gateway: React.FC = () => {
	const appType = CheckEnv();
	const initMagicEvents = useAppSelector(selectMagicEvents);
	const [magicEvents, setStateMagicEvents] = useState(initMagicEvents);
	const [chatGoMagicEvents, setChatGoMagicEvents] = useState<
		{ orgName: string; events: MagicEvent[] }[]
	>([]);
	const magicTokens = useAppSelector(selectMagicTokens);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const event_key: string | null | undefined = useAppSelector(selectEvent_key);
	const isAuthed = useAppSelector(isAuthenticated);
	const history = useHistory();

	useEffect(() => {
		// check if initMagicEvents is not empty
		// check if magic events has changes
		// if appType !== chatGo, return
		if (initMagicEvents.length <= 0) {
			return;
		}

		if (appType === "chatgo") {
			// massage the array into org indexed
			let tempEvents: { orgName: string; events: MagicEvent[] }[] = [];
			initMagicEvents.forEach((event) => {
				const targetOrgItemIndex = tempEvents.findIndex(
					(item) => item.orgName === event.organisation_name
				);
				if (targetOrgItemIndex > -1) {
					// item exist
					tempEvents[targetOrgItemIndex].events.push(event);
				} else {
					// no item, create first
					const tempEvent = {
						orgName: String(event.organisation_name),
						events: [event],
					};
					tempEvents.push(tempEvent);
				}
			});
			setChatGoMagicEvents(tempEvents);
        }
        setStateMagicEvents(initMagicEvents);
	}, [initMagicEvents, appType]);

	const handleSelectEvent = async (eventKey: string, token: string) => {
		const tempMagicEvents = [...initMagicEvents];
        const tempMagicTokens = [...magicTokens];

		// if user is logged in, do switching logout
		if (isAuthed) {
			const loggedOut = await userLogout(true);
			if (!loggedOut) {
				return;
			}
        }

        dispatch(setEvent_key(eventKey));      
        dispatch(setMagicEvents(tempMagicEvents));
        dispatch(setMagicTokens(tempMagicTokens));

		// update event branding
		await getBrandings(eventKey);

		const url = `${process.env.REACT_APP_CHATGO_API}/chat-users/magic-link/${token}`;
		const options = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		};
		setIsLoading(true);
		try {
			const res = await fetch(url, {
				...options,
				body: JSON.stringify({ event_key: eventKey }),
			});
			const data = await res.json();
			if (!data || !data.access_token || !data.firebase_token) {
				return;
			}
			dispatch(
				addMagicTokens({
					org:
						appType === "org"
							? process.env.REACT_APP_ORGANISATION_KEY || null
							: null,
					token,
				})
			);
			//return
			dispatch(setCredentials(data));
			await firebaseLogin();
			setIsLoading(false);
			window.location.assign(`/`);
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	return (
		<IonPage>
			<TabHeader title="Please Select Chat" hideUser back />
			<IonContent>
				<IonList lines="full">
					{appType === "org"
						? magicEvents.map((item) => (
								<IonItem
									key={`gateway-event-${item.event_key}`}
									button
									onClick={() =>
										handleSelectEvent(item.event_key, item.magic_token)
									}
								>
									<IonLabel>{item.name}</IonLabel>
								</IonItem>
						  ))
						: chatGoMagicEvents.map((item) => (
								<Fragment key={`event-list-item${item.orgName}`}>
									<IonListHeader>
										<IonLabel><b>{item.orgName}</b></IonLabel>
									</IonListHeader>

									{item.events.map((event) => (
										<IonItem
											key={`gateway-event-org-${item.orgName}-item-${event.event_key}`}
											button
											lines="none"
											onClick={() =>
												handleSelectEvent(event.event_key, event.magic_token)
											}
										>
											<IonLabel>{event.name}</IonLabel>
										</IonItem>
									))}
									<br/>
								</Fragment>
						  ))}
				</IonList>
			</IonContent>
			<IonLoading isOpen={isLoading} message={translate(`Logging you in...`)} />
		</IonPage>
	);
};

export default Gateway;
