import {
    IonContent, IonItem,
    IonLabel, IonList, IonPage, useIonActionSheet,
    useIonAlert
} from "@ionic/react";
import { useEffect, useState } from "react";
import { fetchBlockedUserDetails } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { useUserUnBlockMutation } from "../../app/services/userApi";
import { selectUserSettings } from "../../app/slices/userSlice";
import EmptyContent from "../../components/EmptyContent";
import TabHeader from "../../components/TabHeader";
import UserAvatar from "../../components/UserAvatar";
import { User } from "../Auth/types";
import { translate } from "../../app/translate";

const BlockedUsers: React.FC = props => {
    const [present] = useIonActionSheet();
    const self = useAppSelector(selectUserSettings);
    const [users, setUsers] = useState<Array<User>>([]);
    const [unblock] = useUserUnBlockMutation();
    const [alert] = useIonAlert();

    useEffect(() => {
        if (Object.keys(self.blocked).length > 0) {
            (async () => {
                const updatedUsers = await fetchBlockedUserDetails();
                setUsers(updatedUsers);
            })();
        } else {
            setUsers([]);
        }
    }, [self.blocked]);

    return (
        <IonPage>
            <TabHeader title={translate('Your Blocked Users')} back hideUser />
            <IonContent fullscreen>
                <IonList lines="none">
                    {   users && (
                            users.length <= 0 ? <EmptyContent header={translate(`You've not blocked anyone`)} message={translate(`If you've blocked someone, they'll be displayed here`)} /> :
                                users.map((user) => (
                                    <IonItem
                                        key={user.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            present({
                                                buttons: [
                                                    {
                                                        text: translate(`Unblock :firstName`, {firstName: user.firstName}),
                                                        handler: async () => {
                                                            const response: { data: { success: boolean; }; } = await unblock({
                                                                target: user.id,
                                                            });

                                                            var message = translate(`Failed to unblock :firstName`, {firstName: user.firstName});
                                                            var header = translate("Error");
                                                            if (response.data.success) {
                                                                message = translate(`Successfully unblocked :firstName`, {firstName: user.firstName});
                                                                header = translate("Success");
                                                            }

                                                            alert({
                                                                header,
                                                                message,
                                                                buttons: [
                                                                    'OK',
                                                                ],
                                                            });
                                                        },
                                                    },
                                                    {
                                                        text: translate('Cancel'),
                                                        role: 'cancel'
                                                    }
                                                ],
                                            })
                                        }
                                    >
                                        <UserAvatar
                                            id={user.id}
                                            firstName={user.firstName}
                                            img={user.profilePicture}
                                        />
                                        <IonLabel>
                                            <h2>{`${user.firstName}`}</h2>
                                            <p>{`${user.organisation}`}</p>
                                            <br />
                                        </IonLabel>

                                    </IonItem>
                                ))
                    )}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default BlockedUsers;