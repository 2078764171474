import {
	IonCard,
	IonCardContent,
	IonContent,
	IonFooter,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonLabel,
	IonList,
	IonPage,
	IonSearchbar,
	IonSegment,
	IonSegmentButton,
	IonSpinner,
	IonText,
	IonToolbar,
} from "@ionic/react";
import { DocumentData } from "firebase/firestore";
import { alertCircleOutline, searchOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { getAllOnlineUsers, getAllUsersPaginated } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { useUserSearchQuery } from "../../app/services/userApi";
import { selectUser } from "../../app/slices/userSlice";
import UserListSkeleton from "../../components/Skeleton/UserListSkeleton";
import TabHeader from "../../components/TabHeader";
import UserItem from "../../components/UserItem";
import { User } from "../Auth/types";
import { translate } from "../../app/translate";

const UserList: React.FC = (props) => {
	const [searchText, setSearchText] = useState("");
	const { data = [], isFetching } = useUserSearchQuery(searchText, {
		skip: searchText.length === 0,
	});

	const user: User = useAppSelector(selectUser);
	const [result, setResult] = useState(data);

	const [tab, setTab] = useState("search");
	const [onlineLoading, setOnlineLoading] = useState("idle");
	const [onlineResults, setOnlineResults] = useState<User[]>([]);
	const [allResults, setAllResults] = useState<User[]>([]);
	const [allLoading, setAllLoading] = useState("idle");
	const [lastDoc, setLastDoc] = useState<DocumentData | null>(null);

	useEffect(() => {
		if (isFetching) {
			data.length > 0 && setResult([]);
		} else {
			if (searchText !== "") {
				// convert data into users
				let users: User[] = [];
				data.forEach((item: any) => {
					const tempUser: User = {
						id: item.id,
						firstName: item.first_name,
						lastName: item.last_name,
						organisation: item.organisation,
						profilePicture: item.profile_picture,
						email: item.email,
						jobTitle: item.job_title,
						online: false,
						callStatus: {
							status: 0,
						},
						is_sponsor_user: item.is_sponsor || false,
					};
					users.push(tempUser);
				});
				setResult(users);
			}
		}
	}, [isFetching, data, searchText]);

	useEffect(() => {
		const loadOnlineUsers = async () => {
			setOnlineLoading("loading");
			const res = await getAllOnlineUsers();
			setOnlineResults(res);
			setOnlineLoading("idle");
		};

		const loadAllUsers = async () => {
			setAllLoading("loading");
            const res = await getAllUsersPaginated();
			setAllResults(res.users);
			setLastDoc(res.lastDoc);
			setAllLoading("idle");
		};

		if (tab === "online") {
			loadOnlineUsers();
			return;
		}
		if (tab === "all") {
			loadAllUsers();
			return;
		}
	}, [tab]);

    const loadMore = useCallback(async (e: CustomEvent<void>) => {
        if (!lastDoc) { return };
        const getMore = async () => {
            const res = await getAllUsersPaginated(lastDoc);
            const newLastDoc = res.lastDoc;
            const newUsers = res.users;
            setAllResults(r => r.concat(newUsers));
            setLastDoc(newLastDoc);
            (e.target as HTMLIonInfiniteScrollElement).complete();
        }
        getMore();
	}, [lastDoc]);

	return (
		<IonPage>
			<TabHeader title={translate("Connect With Attendees")}>
				{tab === "search" ? (
					<IonToolbar>
						<IonSearchbar
							animated
							value={searchText}
							// onKeyUp={e => changeSearchText(e)}
							onIonChange={(e) => setSearchText(e.detail.value!)}
							debounce={600}
							className={"ion-margin-top"}
							placeholder={translate(`eg. Sales Manager`)}
						></IonSearchbar>
					</IonToolbar>
				) : null}
			</TabHeader>
			{tab === "search" ? (
				<IonContent>
					{searchText === "" ? (
						<div
							className="ion-padding ion-margin-top ion-text-center ion-align-items-center"
							style={{
								display: "flex",
								flexDirection: "column",
								maxWidth: "420px",
								margin: "auto",
							}}
						>
							<IonIcon icon={searchOutline} size="large" />
							<IonText>
								<p>{translate('Try searching by job title or company name')}</p>
							</IonText>
						</div>
					) : isFetching ? (
						<UserListSkeleton count={6} />
					) : result.length > 0 ? (
						<IonList lines="none">
							{result.map(
								(item: any, index: any) =>
									item.id !== user.id && (
										<UserItem
											key={index}
											id={item.id}
											user={item}
											index={index}
											firstName={item.firstName}
											lastName={item.lastName}
											jobTitle={item.jobTitle}
											organisation={item.organisation}
											status={"disabled"}
											profileImage={item.profilePicture}
										/>
									)
							)}
						</IonList>
					) : (
						<div
							className="ion-padding ion-margin-top ion-text-center ion-align-items-center"
							style={{
								display: "flex",
								flexDirection: "column",
								maxWidth: "420px",
								margin: "auto",
							}}
						>
							<IonIcon icon={alertCircleOutline} size="large" color="danger" />
							<IonText color="danger">
								<p>
									{translate('No attendees found. Try searching for other job titles and companies, or broadening your search.')}
								</p>
							</IonText>
						</div>
					)}
				</IonContent>
			) : tab === "online" ? (
				<IonContent>
					{onlineLoading === "loading" ? (
						<IonCard color="light">
							<IonCardContent className="ion-text-center">
								<IonSpinner name="dots" />
							</IonCardContent>
						</IonCard>
					) : onlineResults.filter((x) => x.id !== user.id).length ? (
						<IonList lines="none" className="ion-margin-top">
							{onlineResults
								.filter((x) => x.id !== user.id)
								.map((item, index) => (
									<UserItem
										key={`online-user-item-${item.id}`}
										id={item.id}
										user={item}
										index={index}
										firstName={item.firstName}
										lastName={item.lastName}
										jobTitle={item.jobTitle}
										organisation={item.organisation}
										status={translate("online")}
										profileImage={item.profilePicture}
									/>
								))}
						</IonList>
					) : (
						<IonCard color="light">
							<IonCardContent>
								<IonLabel>
									<h2>{translate('No User online right now. Please check back later.')}</h2>
								</IonLabel>
							</IonCardContent>
						</IonCard>
					)}
				</IonContent>
			) : (
				<IonContent>
					{allLoading === "loading" ? (
						<IonCard color="light">
							<IonCardContent className="ion-text-center">
								<IonSpinner name="dots" />
							</IonCardContent>
						</IonCard>
					) : allResults.filter((x) => x.id !== user.id).length ? (
						<>
							<IonList lines="none" className="ion-margin-top">
								{allResults
									.filter((x) => x.id !== user.id)
									.map((item, index) => (
										<UserItem
											key={`online-user-item-${item.id}`}
											id={item.id}
											user={item}
											index={index}
											firstName={item.firstName}
											lastName={item.lastName}
											jobTitle={item.jobTitle}
											organisation={item.organisation}
											status={translate(item.online ? 'online' : 'disabled')}
											profileImage={item.profilePicture}
										/>
									))}
							</IonList>
							<IonInfiniteScroll
								onIonInfinite={(e: CustomEvent<void>) => loadMore(e)}
								position="bottom"
								disabled={lastDoc === null}
							>
								<IonInfiniteScrollContent
									loadingText={translate("Loading")}
								></IonInfiniteScrollContent>
							</IonInfiniteScroll>
						</>
					) : (
						<IonCard color="light">
							<IonCardContent>
								<IonLabel>
									<h2>{translate('No User found. Please check back later.')}</h2>
								</IonLabel>
							</IonCardContent>
						</IonCard>
					)}
				</IonContent>
			)}

			<IonFooter>
				<IonToolbar color="medium">
					<IonSegment
						value={tab}
						color={"primary"}
						onIonChange={(e) => {
							setTab(e.detail.value!);
						}}
					>
						<IonSegmentButton value="search">{translate('By Keyword')}</IonSegmentButton>
						<IonSegmentButton value="online">{translate('Online Only')}</IonSegmentButton>
						<IonSegmentButton value="all">{translate('All Users')}</IonSegmentButton>
					</IonSegment>
				</IonToolbar>
			</IonFooter>
		</IonPage>
	);
};

export default UserList;
