import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";
import { Match, Matches } from "../types/match";

interface MatchState{
    matches: Match[]
}

const initialState: MatchState = {
    matches: [],
}

export const matchSlice = createSlice({
    name: 'matches',
    initialState,
    reducers: {
        setMatches: (state: MatchState, action: PayloadAction<Array<Match>>) => {
            state.matches = action.payload;
        }
    }
});

export const selectMatches = (state: RootState): Array<Match> => state.matches.matches;
export const selectMatchesCount = (state: RootState): number => state.matches.matches.length;

export const { setMatches } = matchSlice.actions;

export default matchSlice.reducer;