import { IonAvatar, IonSkeletonText } from '@ionic/react';
import { useEffect, useState } from 'react';
import { colorMaps } from './Classes/ColorMap';
import './UserAvatar.css';

interface Props{
    id: number;
    firstName: string;
    img?: string | null;
    status?: string;
    size?: number;
    className?: string;
    style?: CSSStyleDeclaration;
    slot?: string;
}

// const bgColor = randomColor({ luminosity: 'dark' })

const UserAvatar: React.FC<Props> = (props: Props) => {

    const [bgColor, setBgColor] = useState('#888');
    const size = props.size ? props.size : 48;
    const [imgLoaded, setImgLoaded] = useState(false);

    useEffect(() => {
        if (props.firstName && props.firstName[0] && colorMaps) {
            const finalColor:string | any = colorMaps.find(color => color.initials.includes(props.firstName[0].toLowerCase()))?.color;
            finalColor && setBgColor(finalColor)
        }
    }, [props.firstName])

    const style = {
        background: props.img ? 'none' : bgColor,
        display: 'flex',
        justifyContent: `center`,
        alignItems: `center`,
        color: '#fff',
        position: 'relative',
        minWidth: `${size}px`,
        minHeight: `${size}px`,
        maxWidth: `${size}px`,
        maxHeight: `${size}px`,
        fontSize: `${size/2}px`,
    }
    const statusStyle = {
        bottom: `${size * 0.02}px`,
        right: `${size * 0.02}px`,
        width: `${size * 0.2}px`,
        height: `${size * 0.2}px`,
    }

    return (
        <IonAvatar slot='start' style={style} className={props.className ? props.className : ''}>
            {
                props.img && !imgLoaded &&
                <IonSkeletonText animated />
            }
            {
                props.img ?
                    <img
                        src={props.img ? props.img : ''}
                        alt={props.firstName}
                        onLoad={() => setImgLoaded(true)}
                        style={{
                            position: "absolute",
                            transition: "300ms",
                            top: '0px',
                            left: '0px',
                            opacity: imgLoaded ? '1':'0'
                        }}
                    /> :
                props.firstName.substring(0, 1).toUpperCase()
            }
            {
                props.status && props.status !== 'disabled' && <span className={`status-dot ${props.status}`} style={statusStyle}></span>
            }
        </IonAvatar>
    )
}

export default UserAvatar;