import { Fragment } from "react"

const UserInfoChecked: React.FC<{ jobTitle?: string | null | undefined; organisation?: string | null | undefined;}> = ({ jobTitle, organisation }) => {
    return (
        <Fragment>
            {(jobTitle && jobTitle !== '' && jobTitle !== 'Unknown') && jobTitle}
            {(jobTitle && jobTitle !== '' && jobTitle !== 'Unknown' && organisation && organisation !== '' && organisation !== 'Unknown') && ` | `}
            {(organisation && organisation !== '' && organisation !== 'Unknown') && organisation}
        </Fragment>
    )
}

export default UserInfoChecked;