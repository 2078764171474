import { IonApp, IonRouterOutlet, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { boostrapFirebase, firebaseLogin } from "./app/firebase";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
	selectEventName,
	selectIsAutoAuth,
	selectMode,
	setDmBack,
	setDmId,
	setEventName,
	setIsAutoAuth,
	setMode,
	setRoomId,
	setTranslations,
	setLang
} from "./app/slices/initDataSlice";
import { store } from "./app/store";
import { InitData } from "./app/types/InitData";
import AppUrlListener from "./components/AppUrlListener";
import AuthGuard from "./components/AuthGuard";
import { AppType, CheckEnv } from "./components/Classes/CheckEnv";
import CheckWidget from "./components/Classes/CheckWidget";
import OverrideRouting from "./components/OverrideRouting";
import UserMenu from "./components/UserMenu";
import {
	selectEvent_key,
	setCredentials,
	setEvent_key
} from "./pages/Auth/authSlice";
import Login from "./pages/Auth/Login";
import OrgLogin from "./pages/Auth/OrgLogin";
import Register from "./pages/Auth/Register";
import ChatRoomIonic from "./pages/ChatRooms/ChatRoomIonic";
import Gateway from "./pages/Home/Gateway";
import Home from "./pages/Home/Home";
import InvalidMagicLink from "./pages/Home/InvalidMagicLink";
import DirectMessageIonic from "./pages/Messages/DirectMessageIonic";
import BlockedUsers from "./pages/Settings/BlockedUsers";
import MatchMakingSettings from "./pages/Settings/MatchMakingSettings";
import NotificationsSettings from "./pages/Settings/NotificationsSettings";
import ProfileSettings from "./pages/Settings/ProfileSettings";
import ResetPassword from "./pages/Settings/ResetPassword";
import SponsorsList from "./pages/Sponsors/Sponsors";
import UserProfile from "./pages/Users/UserProfile";
import VideoCall from "./pages/VideoCall/VideoCall";
import "./theme/font.css";
import "./theme/variables.css";

import "./theme/global.css";

import 'moment/min/locales';

setupConfig({
	mode: "ios",
});

interface Props {
	initData: InitData;
}

const App: React.FC<Props> = (props: Props) => {
	const appType: AppType = CheckEnv();
	const isWidget = CheckWidget();
	const event_key: string | null | undefined = useAppSelector(selectEvent_key); // from_auth_slice

	const isAutoAuth: boolean = useAppSelector(selectIsAutoAuth); // from_initData_slice
	const event_name: string | null | undefined = useAppSelector(selectEventName); // from_initData_slice
	const mode: string | null | undefined = useAppSelector(selectMode); // from_initData_slice

	const [viewportInfo, setViewportInfo] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	const [didDmRoute, setDidDmRoute] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setViewportInfo({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		boostrapFirebase();
	}, []);

	// use Effect for updating init data if it is a widget, and index received updates of data after loaded or from parent page messages
	const dispatch = useAppDispatch();
	useEffect(() => {
		props.initData.dmId && store.dispatch(setDmId(props.initData.dmId));
		props.initData.dmBack &&
			store.dispatch(setDmBack(props.initData.dmBack || false));

		props.initData.roomId && store.dispatch(setRoomId(props.initData.roomId));

		props.initData.hasOwnProperty("isAutoAuth") &&
			props.initData.isAutoAuth !== isAutoAuth &&
			store.dispatch(setIsAutoAuth(props.initData.isAutoAuth));

		// update authSlice event key from props
		if (
			props.initData.eventKey &&
			props.initData.eventKey !== "" &&
			event_key === ""
		) {
			dispatch(setEvent_key(props.initData.eventKey));
		}

		// update authSlice access token and firebase token from props
		if (props.initData.userToken && props.initData.firebaseToken) {
			dispatch(
				setCredentials({
					access_token: props.initData.userToken,
					firebase_token: props.initData.firebaseToken,
				})
			);
			const doLogin = async () => {
				await firebaseLogin();
			};
			doLogin();
		}

		if (props.initData.event_name && props.initData.event_name !== event_name) {
			dispatch(setEventName(props.initData.event_name));
		}

		if (props.initData.mode && props.initData.mode !== mode) {
			dispatch(setMode(props.initData.mode));
		}

		props.initData.translations && store.dispatch(setTranslations(props.initData.translations));

		props.initData.lang && store.dispatch(setLang(props.initData.lang));

	}, [props.initData, dispatch, event_key, isAutoAuth, event_name, mode]);

	return (
		<IonApp>
			<IonReactRouter>
				<UserMenu />
				<AppUrlListener />
				<IonRouterOutlet id="main" animated={true}>
					<AuthGuard>
						{!didDmRoute && (
							<OverrideRouting
								setDidDmRoute={(bool: boolean) => setDidDmRoute(bool)}
							/>
						)}
						<Switch>
							<Route exact path={`/gateway`} render={() => <Gateway />} />
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/login`}
								render={() =>
									appType === "event" || isAutoAuth || isWidget ? (
										<Login />
									) : (
										<OrgLogin />
									)
								}
							/>
							<Route
								exact
								path={`/invalidMagicLink`}
								render={() => <InvalidMagicLink />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/register`}
								render={() => <Register />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/videoCall/:id`}
								render={() => <VideoCall isCaller={true} />}
							/>
							<Route
								exact
								path={`${process.env.PUBLIC_URL}/videoCallReceived/:id`}
								render={() => <VideoCall />}
							/>
							<Route path={`/home`}>
								<Home viewportInfo={viewportInfo} initData={props.initData} />
							</Route>
							<Route
								path={`${process.env.PUBLIC_URL}/matchmakingsettings`}
								render={() => <MatchMakingSettings />}
								exact={true}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/notificationssettings`}
								render={() => <NotificationsSettings />}
								exact={true}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/profilesettings`}
								render={() => <ProfileSettings />}
								exact={true}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/resetpassword`}
								render={() => <ResetPassword />}
								exact={true}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/blockedusers`}
								render={() => <BlockedUsers />}
								exact={true}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/chatroom/:id`}
								render={() => <ChatRoomIonic />}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/group/:id`}
								render={() => <DirectMessageIonic />}
							/>
							<Route
								path={`${process.env.PUBLIC_URL}/userprofile/:id`}
								render={() => <UserProfile />}
							/>
							<Route path={`/sponsors`} render={() => <SponsorsList />} />
							<Route render={() => <Redirect to={`/home`} />}></Route>
						</Switch>
					</AuthGuard>
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	);
};

export default App;
