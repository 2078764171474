import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { UserLoginPayload, UserRegisterPayload } from '../../pages/Auth/types';
import { store } from "../store";

export const authApi: any = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: String(process.env.REACT_APP_CHATGO_API),
        prepareHeaders: (headers, { getState }) => {
            let token = store.getState().auth.event_key || String(process.env.REACT_APP_EVENT_TOKEN);
            headers.set('authorization', `Bearer ${token}`);
            headers.set('accept', 'application/json')

            return headers;
        }
    }),
    endpoints: (builder) => ({
        login: builder.mutation<any, UserLoginPayload>({
            query: (payload: UserLoginPayload) => ({
                url: "login",
                method: "POST",
                body: payload,
            }),
        }),
        register: builder.mutation<any, UserRegisterPayload>({
            query: (payload: UserRegisterPayload) => ({
                url: "register",
                method: "POST",
                body: payload,
            })
        }),
        branding: builder.query<any, any>({
            query: (query) => `branding`,
        }),
    }),
});

export const { useLoginMutation, useRegisterMutation, useBrandingQuery } = authApi;